<app-admin-navigation-panel></app-admin-navigation-panel>

<div class="main"><br><br>

    <ngl-tabset [(selected)]='selectedTab' variant='scoped'>
        <ng-template ngl-tab label="Org Registration Report" (activate)="tabChange(tabs.orgReport)">
            <div class="slds-page-header slds-page-header_record-home">
                <div class="slds-page-header__row">
                    <div class="slds-page-header__col-title">
                        <div class="slds-media">
                            <div class="slds-media__body">
                                <div class="slds-page-header__name">
                                    <div class="slds-page-header__name-title">
                                        <h1>
                                            <span class="slds-page-header__title slds-truncate"
                                                title="Scheduled Sandbox Org Information"></span>
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="slds-page-header__col-actions">
                        <div class="slds-page-header__controls">
                            <div class="slds-page-header__control">
                                <div *ngTemplateOutlet="chartFilter"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template ngl-tab label="Upgrade Job Report" (activate)="tabChange(tabs.jobReport)">
            <div class="slds-page-header slds-page-header_record-home">
                <div class="slds-page-header__row">
                    <div class="slds-page-header__col-title">
                        <div class="slds-media">
                            <div class="slds-media__body">
                                <div class="slds-page-header__name">
                                    <div class="slds-page-header__name-title">
                                        <h1>
                                            <span class="slds-page-header__title slds-truncate"
                                                title="Scheduled Sandbox Org Information"></span>
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="slds-page-header__col-actions">
                        <div class="slds-page-header__controls">
                            <div class="slds-page-header__control">
                                <div *ngTemplateOutlet="chartFilter"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>

    </ngl-tabset>

    <ng-template #chartFilter>
        <div class="slds-grid slds-gutters_direct">
            <div class="slds-col">
                <ngl-combobox label="Type" [(open)]="isFilterTypeOpen" [options]="filterTypeList"
                    [(selection)]="filterType">
                    <input nglCombobox placeholder="Type">
                </ngl-combobox>
            </div>
            <div class="slds-col">
                <ngl-datepicker-input label="From" [formControl]="filterFromDate" format="little-endian"
                    patternPlaceholder>
                    <input nglDatepickerInput type="text">
                </ngl-datepicker-input>
                <span class="slds-text-color_error" *ngIf="filterToDate.errors">
                    <ng-container *ngIf="filterFromDate.errors?.nglDatepickerInput?.invalid">
                        This is not a valid date.
                    </ng-container>
                </span>
            </div>
            <div class="slds-col">
                <ngl-datepicker-input label="To" [formControl]="filterToDate" format="little-endian" patternPlaceholder
                    [class.slds-has-error]="!filterToDate.valid" [min]="filterFromDate.value">
                    <input nglDatepickerInput type="text">
                </ngl-datepicker-input>
                <p class="slds-m-top_xx-small" *ngIf="filterToDate.errors">
                    <span class="slds-text-color_error">
                        <ng-container *ngIf="filterToDate.errors?.inValid">Invalid Date
                        </ng-container>
                        <ng-container *ngIf="filterToDate.errors?.nglDatepickerInput?.invalid">
                            This is not a valid date.
                        </ng-container>
                    </span>
                </p>
            </div>
            <button class="slds-button slds-icon_container slds-icon-utility-info btn-download-dashboard"
                (click)="openFilter()" [nglTooltip]="'Export the Reports to Excel'" nglTooltipInteractive="true"
                nglTooltipDelay="100" nglTooltipOpenAuto>
                <svg class="slds-button__icon" aria-hidden="true">
                    <use xlink:href="/assets/icons/action-sprite/svg/symbols.svg#download">
                    </use>
                </svg>
            </button>
        </div>
    </ng-template>

    <br />
    <div class="slds-page-header">
        <div class="data-body">
            <div class="slds-page-header">
                <app-org-registration-report *ngIf="activeTab===tabs.orgReport" [orgSummary]="orgSummary">
                </app-org-registration-report>
                <app-upgraded-job-report *ngIf=" activeTab===tabs.jobReport" [jobSummary]="jobSummary">
                </app-upgraded-job-report>
            </div>
        </div>
    </div>

    <!-- Loading Icon while making backend call -->
    <div class="loading_spinner" *ngIf="isLoading">
        <ngl-spinner size="large" variant="brand" alternativeText="Loading"></ngl-spinner>
    </div>
</div>

<ngl-modal header="Filter" [(open)]="orgReportFilter.open" dismissOnClickOutside="false">
    <div class="slds-p-around_medium filter-form">
        <div class="slds-col">
            <ngl-combobox label="Report For" [(open)]="orgReportFilter.formData.reportFor.open"
                [options]="filterTypeList" [(selection)]="orgReportFilter.formData.reportFor.value">
                <input nglCombobox placeholder="">
            </ngl-combobox>
        </div>
        <br />
        <ngl-checkbox-toggle label="Enable Date Range" enabledText="Yes" disabledText="No">
            <input ngl type="checkbox" [(checked)]="orgReportFilter.formData.hasDateRange"
                (change)="orgReportFilter.formData.hasDateRange = $event.target.checked">
        </ngl-checkbox-toggle>

        <div class="slds-grid slds-gutters_direct" *ngIf="orgReportFilter.formData.hasDateRange">
            <div class="slds-col">
                <ngl-datepicker-input label="From" [(value)]="orgReportFilter.formData.dateRange.fromDate"
                    format="little-endian" patternPlaceholder>
                    <input nglDatepickerInput type="text">
                </ngl-datepicker-input>
            </div>

            <div class="slds-col">
                <ngl-datepicker-input label="To" format="little-endian" patternPlaceholder
                    [(value)]="orgReportFilter.formData.dateRange.toDate"
                    [class.slds-has-error]="!orgReportFilter.formData.dateRange.toDate"
                    [min]="orgReportFilter.formData.dateRange.fromDate">
                    <input nglDatepickerInput type="text">
                </ngl-datepicker-input>
            </div>
        </div>
    </div>
    <ng-template nglModalFooter>
        <button class="slds-button slds-button_neutral" (click)="orgReportFilter.open = false">Cancel</button>
        <button class="slds-button slds-button_brand" (click)="onOrgReportFilterApplied()">Apply</button>
    </ng-template>
</ngl-modal>


<ngl-modal header="Filter" [(open)]="jobReportFilter.open" dismissOnClickOutside="false">
    <div class="slds-p-around_medium filter-form">
        <div class="slds-grid slds-gutters_direct">
            <div class="slds-col">
                <ngl-combobox label="Report For" [(open)]="jobReportFilter.formData.reportFor.open"
                    [options]="filterTypeList" [(selection)]="jobReportFilter.formData.reportFor.value"
                    (selectionChange)="jobReportFilter.formData.jobId.value = ''; filterOrgList($event)">
                    <input nglCombobox placeholder="Type">
                </ngl-combobox>
            </div>
            <div class="slds-col">
                <ngl-combobox label="Org ID" variant="lookup" [(open)]="jobReportFilter.formData.orgId.open"
                    [options]="filteredOrgList$ | async" [(selection)]="jobReportFilter.formData.orgId.value"
                    (selectionChange)="jobReportFilter.formData.jobId.value = ''">
                    <input nglCombobox placeholder="Select Orgs" [formControl]="orgListControl">
                </ngl-combobox>
            </div>
            <div class="slds-col">
                <ngl-combobox label="Release" variant="lookup" [(open)]="jobReportFilter.formData.releaseId.open"
                    [options]="filteredReleaseList$ | async" [(selection)]="jobReportFilter.formData.releaseId.value"
                    (selectionChange)="jobReportFilter.formData.jobId.value = ''">
                    <input nglCombobox placeholder="Select Release" [formControl]="releaseListControl">
                </ngl-combobox>
            </div>
        </div>
        <br />
        <div style="text-align: center;">
            <h3>OR</h3>
        </div>

        <div class="slds-col">
            <input ngl type="text" placeholder="Job ID" [(ngModel)]="jobReportFilter.formData.jobId.value"
                (change)="clearFirstPartOfJobFilter()">
        </div>

        <br />

        <ngl-checkbox-toggle label="Enable Date Range" enabledText="Yes" disabledText="No">
            <input ngl type="checkbox" [(checked)]="jobReportFilter.formData.hasDateRange"
                (change)="jobReportFilter.formData.hasDateRange = $event.target.checked">
        </ngl-checkbox-toggle>

        <div class="slds-grid slds-gutters_direct" *ngIf="jobReportFilter.formData.hasDateRange">
            <div class="slds-col">
                <ngl-datepicker-input width="100%" label="From" [(value)]="jobReportFilter.formData.dateRange.fromDate"
                    format="little-endian" patternPlaceholder>
                    <input nglDatepickerInput type="text">
                </ngl-datepicker-input>
            </div>
            <div class="slds-col">
                <ngl-datepicker-input width="100%" label="To" [(value)]="jobReportFilter.formData.dateRange.toDate"
                    format="little-endian" patternPlaceholder
                    [class.slds-has-error]="!jobReportFilter.formData.dateRange.toDate"
                    [min]="jobReportFilter.formData.dateRange.fromDate">
                    <input nglDatepickerInput type="text">
                </ngl-datepicker-input>
            </div>
        </div>
    </div>
    <ng-template nglModalFooter>
        <button class="slds-button slds-button_neutral" (click)="jobReportFilter.open = false">Cancel</button>
        <button class="slds-button slds-button_brand" (click)="onJobReportFilterApplied()">Apply</button>
    </ng-template>
</ngl-modal>