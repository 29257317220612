import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { tap, map, catchError } from 'rxjs/operators';
import { AppConfigService } from '../app-config.service';
import { ClientApplication } from '../interface/client-application';

interface HttpConfig { showToast?: boolean, successToast?: string }

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  apiError$ = new BehaviorSubject({ hasError: false, err: null })
  apiSuccess$ = new BehaviorSubject({ hasSuccess: false, msg: null })
  apiBaseUrl: string;
  headers = new HttpHeaders().set('Content-Type', 'application/json');
  currentUser: ClientApplication = { ClientId: null, UserType: null, AppName: null };
  defaultHttpConfig = { showToast: true, successToast: "" };

  constructor(
    private http: HttpClient,
    private appConfigService: AppConfigService
  ) {
    this.apiBaseUrl = this.appConfigService.apiBaseUrl;
    this.currentUser.UserType = this.appConfigService.userType;
  }


  get<T>(url, options = {}, httpConfig: HttpConfig = {}) {
    httpConfig = { ...this.defaultHttpConfig, ...httpConfig };
    return this.http.get<T>(`${this.apiBaseUrl}${url}`, { headers: this.headers, ...options }).pipe(
      tap((resp) => {
        if (httpConfig.successToast) {
          this.apiSuccess$.next({ hasSuccess: true, msg: httpConfig.successToast });
        }
        return resp;
      }),
      map((resp) => {
        return resp;
      }),
      catchError((err) => {
        return this.handleError(err, httpConfig);
      })
    );
  }

  post<T>(url, body, options = {}, httpConfig: HttpConfig = {}) {
    httpConfig = { ...this.defaultHttpConfig, ...httpConfig };

    return this.http.post<T>(`${this.apiBaseUrl}${url}`, body, { headers: this.headers, ...options }).pipe(
      tap((resp) => {
        if (httpConfig.successToast) {
          this.apiSuccess$.next({ hasSuccess: true, msg: httpConfig.successToast });
        }
        return resp;
      }),
      map((resp) => {
        return resp;
      }),
      catchError((err) => {
        return this.handleError(err, httpConfig);
      })
    );
  }

  put<T>(url, body, options = {}, httpConfig: HttpConfig = {}) {
    httpConfig = { ...this.defaultHttpConfig, ...httpConfig };

    return this.http.put<T>(`${this.apiBaseUrl}${url}`, body, { headers: this.headers, ...options }).pipe(
      tap((resp) => {
        if (httpConfig.successToast) {
          this.apiSuccess$.next({ hasSuccess: true, msg: httpConfig.successToast });
        }
        return resp;
      }),
      map((resp) => {
        return resp;
      }),
      catchError((err) => {
        return this.handleError(err, httpConfig);
      })
    );
  }

  delete<T>(url, options = {}, httpConfig: HttpConfig = {}) {
    httpConfig = { ...this.defaultHttpConfig, ...httpConfig };

    return this.http.delete<T>(`${this.apiBaseUrl}${url}`, { headers: this.headers, ...options, observe: 'response' }).pipe(
      tap((resp) => {
        if (httpConfig.successToast) {
          this.apiSuccess$.next({ hasSuccess: true, msg: httpConfig.successToast });
        }
        return resp;
      }),
      map((resp) => {
        return resp;
      }),
      catchError((err) => {
        return this.handleError(err, httpConfig);
      })
    );
  }

  // handleError(err) {
  //   this.apiError$.next({hasError: true, err});
  // }

  // Error Handler
  handleError(error: HttpErrorResponse, httpConfig: HttpConfig = {} = null) {
    console.error(error);
    let msg = 'Something went wrong';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      msg = error.message;
    } else {
      // server-side error
      if (error.error && typeof error.error != 'object') {
        msg = `Error Code: ${error.status}\nMessage: ${error.error}`;
      }
      switch (error.status) {

        case HttpError.BadRequest:
          console.error(msg);
          break;

        case HttpError.Unauthorized:
          console.error(msg);
          localStorage.removeItem('access_token');
          localStorage.removeItem('refresh_token');
          localStorage.removeItem('org_id');
          this.redirectToAdminLogin();
          break;

        case HttpError.NotFound:
          console.error(msg);
          break;

        case HttpError.TimeOut:
          console.error(msg);
          break;

        case HttpError.Forbidden:
          window.location.href = 'ui/forbidden';
          break;

        case HttpError.InternalServerError:
          console.error(msg);
          break;
      }
    }
    if (httpConfig?.showToast) {
      this.apiError$.next({ hasError: true, err: msg });
    }
    return throwError(msg);
  }

  redirectToAdminLogin() {
    localStorage.setItem('redirectURLAfterReLogin', window.location.href);
    localStorage.removeItem('user_type');
    window.location.href = 'ui/admin/login';
  }
}


enum HttpError {
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  TimeOut = 408,
  Conflict = 409,
  InternalServerError = 500
}