import { Directive, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from '../auth.service';

@Directive({
    selector: '[readOnlyBtn]'
})
export class ReadOnlyBtnDirective {

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private authService: AuthService
    ) {
        this.readOnlyBtn();
    }

    readOnlyBtn() {
        if (this.authService.currentUser.UserType == 'ReadOnlyUser') {
            this.viewContainer.clear();
        } else {
            this.viewContainer.createEmbeddedView(this.templateRef);
        }
    }
}