import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { HttpClient } from '@angular/common/http';;
import { AuthService } from '../shared/auth.service';
import { Organization } from '../interface/organization'
import { NglComboboxOptionItem } from 'ng-lightning';
import { forkJoin } from 'rxjs';
import { CommonService } from '../shared/common.service';
import { MatTableDataSource } from '@angular/material/table';
import { AccessControlEnum, PackageAccessControl } from '../interface/package-access-control';

@Component({
  selector: 'app-push-upgrade-registration',
  templateUrl: './push-upgrade-registration.component.html',
  styleUrls: ['./push-upgrade-registration.component.css']
})

export class PushUpgradeRegistrationComponent implements OnInit {
  org_id: string;
  displayPage: boolean;
  loading: boolean;
  org_details: Organization;
  showTopToast = false;
  toastMsg: string;
  submitBtnLabel: string = "Submit";
  disableSubmitBtn: boolean = false;
  isEarlyFeedbackEnabled: boolean = true;
  earlyFeedbackPopUpOpen: boolean = false;
  accessCtrlPopUpOpen: boolean = false;
  packageDisplayedColumns: string[] = ['packageName', 'installForAdminOnly', 'installForAllUsers'];
  packageAccessCtrlDataSource = new MatTableDataSource<PackageAccessControl>();
  packageAccessControl: PackageAccessControl[];
  snapShotOfPackageAccessControl: PackageAccessControl[];
  isHeadInstallForAdmin: boolean = false;
  isHeadInstallForAllUsers: boolean = false;
  isHeadInstallForCustomProfiles: boolean = false;
  displayDependentPkgFootnotes: boolean = false;

  [key: string]: any;
  open: boolean = false;
  user_Profile: NglComboboxOptionItem[]
  sysAdminId: string;
  inputError: boolean = false;
  installForCustomProfileFeatureEnabled: boolean = true;
  userProfileImg: string;

  /* On Close of Toast message */
  onClose(reason: string) {
    console.log(`Closed by ${reason}`);
  }

  constructor(private route: ActivatedRoute, private http: HttpClient, private authservice: AuthService, private commonService: CommonService) {
    this.userProfileImg = window.location.origin + "/assets/images/avatar2.jpg";
  }

  ngOnInit(): void {
    this.loading = true;
    // Check if Org ID is in local storage and if present redirect to registration ui page with
    if (this.authservice.isEmpty(this.authservice.getOrgId())) {
      this.authservice.doLogout();
      this.authservice.redirectToLogin();
    }
    else {
      this.authservice.redirectToRegistrationPageWithOrgId(this.authservice.getOrgId());
      let customerPreference = this.authservice.getSalesForceOrgCustomerPreference(this.authservice.getOrgId());
      forkJoin([customerPreference]).subscribe(response => {
        this.org_details = response[0];
        this.isEarlyFeedbackEnabled = this.org_details.isEarlyFeedbackEnabled;
        response[0].isSandbox ? (this.org_details.isSandbox = "Sandbox", localStorage.setItem('isSandbox', "Yes")) : this.org_details.isSandbox = "Production";
        localStorage.setItem('instanceURL', this.org_details.instanceURL);
        this.displayPage = true;
        if (!this.authservice.isEmpty(this.org_details.id)) {
          this.submitBtnLabel = "Update";

          // Alert Toast if Adobe is Installed but the Add-on checkbox is Unchecked.
          if (this.org_details.isAdobePkgInstalled && !this.org_details.enableExternalDependentPackageInstallation) {
            this.sleep(3000).then(() => {
              this.alertToastToCheckAdobeInstall();
            });
          }

          // Disable Submit btn if Adobe Package (External Package) not present.
          if (!this.org_details.isAdobePkgInstalled)
            this.disableSubmitBtn = true;
        }
        else {
          this.org_details.enableExternalDependentPackageInstallation = true;
        }
        this.loading = false;
      });
    }
  }


  /* Save the User Preference and Register the user in push upgrade system */
  saveUserPreference(): void {
    this.loading = true;
    this.authservice.saveSalesForceOrgCustomerPreference(this.org_details).subscribe((response: any) => {
      this.loading = false;
      this.openToast("User preference saved!", true)

      if (this.submitBtnLabel !== "Update") {
        this.submitBtnLabel = "Update";
        this.earlyFeedbackPopUpOpen = true;
      }

      // Disable Submit btn if Adobe Package (External Package) not present.
      if (!this.org_details.isAdobePkgInstalled)
      this.disableSubmitBtn = true;

    });
  }

  /* Validate and Save the User selected Access control for all the Conga Packages */
  savePackageAccessControlInfo(): void {
    this.inputError = false;
    this.packageAccessControl.forEach(element => {
      element.customProfileIds = this['selectedProfile' + element.packageId];
      if (element.accessibility.toUpperCase() === AccessControlEnum.InstallForCustomProfiles.toString().toUpperCase()) {
        var result = this.checkIfErrorinUserSelection(element);
        if (result)
          this.inputError = true;
      }
    });

    // If there is validation error dont proceed.
    if (this.inputError)
      return;

    this.loading = true;
    this.authservice.savePackageAccessControlInfo(this.packageAccessControl, this.org_details.orgId).subscribe((response: any) => {
      this.loading = false;
      this.openToast("Access Control Settings Saved!", true)
      this.onAccessCtrlModalClose();
    });
  }


  /* Validate and enable error flag for user profile selection.
  Note : Atleast 2 users need to be selected as Sys Admin user is default.
  */
  private checkIfErrorinUserSelection(element: PackageAccessControl) {

    if ((element.accessibility.toUpperCase() === AccessControlEnum.InstallForCustomProfiles.toString().toUpperCase())) {
      if (this['selectedProfile' + element.packageId].length < 2) {
        element.inputHasError = true;
      }
      else
        element.inputHasError = false;
    }
    return element.inputHasError;
  }

  navigateToEarlyFeedbackDashboard(orgId: string) {
    this.loading = true;
    this.authservice.getEarlyFeedbackURL(orgId).subscribe((earlyFeedbackURL: any) => {
      this.loading = false;
      this.redirectToEarlyFeedback(earlyFeedbackURL);
    });
  }

  //Logout and remove active salesforce sessions for the provided Instance URL
  redirectToEarlyFeedback(redirectURL: string): void {
    this.authservice.doLogout();
    var domain = this.getDomainName(localStorage.getItem('instanceURL'));
    var thisTimeout = setTimeout(function () {
      window.location.href = "https://" + domain + ".lightning.force.com/secur/logout.jsp";
    }, 100);
    var newWindow = window.open(redirectURL);
    if (!newWindow) {
      clearTimeout(thisTimeout);
      alert('Please allow pop-ups on this site!');
    }
  }

  // Gets the Domain Name from Salesforce Instace Url
  getDomainName(instanceURL: string) {
    var match = instanceURL.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);
    if (match != null && match.length > 2 && typeof match[2] === 'string' && match[2].length > 0) {
      return match[2].split(".")[0];
    }
    else {
      return null;
    }
  }

  onModalClose() {
    this.earlyFeedbackPopUpOpen = false;
    this.ngOnInit();
  }

  onAccessCtrlModalClose() {
    this.accessCtrlPopUpOpen = false;
    this.packageAccessControl = null;
    this.packageAccessCtrlDataSource = null;
    //reset the header checkbox to false;
    this.resetHeaderCheckBox();
  }

  resetHeaderCheckBox() {
    this.isHeadInstallForAdmin = false;
    this.isHeadInstallForAllUsers = false;
    this.isHeadInstallForCustomProfiles = false;
  }

  /* Open the Package Access Modal and get all the profile for an Org and saved Package Access Info  */
  openAccessCtrlModal() {
    this.loading = true;
    //reset the header checkbox to false;
    this.resetHeaderCheckBox();

    this.authservice.getAccessControlInfo(this.org_details.orgId).subscribe((data: any) => {
      this.packageAccessControl = data;
      let packageAccessControlhasdata = this.packageAccessControl.length > 0;
      this.installForCustomProfileFeatureEnabled = packageAccessControlhasdata ? this.packageAccessControl[0].isCustomProfileEnabled : this.installForCustomProfileFeatureEnabled;

      if (this.installForCustomProfileFeatureEnabled) {
        if (!this.packageDisplayedColumns.includes('installForSpecificProfiles'))
          this.packageDisplayedColumns.push('installForSpecificProfiles');
      }
      else {
        // If the InstallForCustomProfiles feature is disabled then set those value to default i.e InstallForAdminOnly.
        this.packageAccessControl.forEach(x => {
          if (x.accessibility.toUpperCase() === AccessControlEnum.InstallForCustomProfiles.toString().toUpperCase()) {
            x.accessibility = AccessControlEnum.InstallForAdminOnly.toString();
          }
        })
      }
      this.snapShotOfPackageAccessControl = JSON.parse(JSON.stringify(data));
      this.packageAccessCtrlDataSource = data;
      this.user_Profile = packageAccessControlhasdata ? this.packageAccessControl[0].userProfileList.map(x => ({ value: x.id, label: x.name, disabled: x.name === "System Administrator" ? true : false })) : null;
      this.sysAdminId = packageAccessControlhasdata ? this.user_Profile.find(x => x.label === "System Administrator")?.value.toString() : null;
      this.setupAccessibilityHeaderCheckboxes(this.loading);
      var display = false;

      this.packageAccessControl.forEach(x => {
        if (!x.isInstalledPackage) {
          display = true;
          return;
        }
      });
      this.displayDependentPkgFootnotes = display;
      this.loading = false;
    },
      error => {
        console.log(error)
      });
    this.accessCtrlPopUpOpen = true;
  }

  onSelectionChange(event: any, pkg: PackageAccessControl) {
    this.setSystemAdminAsDefaultProfileForPackageAccess(pkg);
    this.checkIfErrorinUserSelection(pkg);
  }

  remove(profileId: string, packageId: string) {
    if (this.sysAdminId !== profileId)
      this['selectedProfile' + packageId] = this['selectedProfile' + packageId].filter((s: string) => s !== profileId);
    else
      this.openToast("System Adminstrator profile cannot be removed", false);
  }

  setupAccessibilityHeaderCheckboxes(onload: boolean = false) {
    var adminCheckAll = true;
    var allUserCheckAll = true;
    var customProfileCheckAll = true;
    this.packageAccessControl.forEach(pkg => {


      switch (pkg.accessibility.toUpperCase()) {
        case AccessControlEnum.InstallForAdminOnly.toString().toUpperCase():
          allUserCheckAll = false;
          customProfileCheckAll = false;
          this.clearSelectedProfiles(pkg);
          break;
        case AccessControlEnum.InstallForAllUsers.toString().toUpperCase():
          adminCheckAll = false;
          customProfileCheckAll = false;
          this.clearSelectedProfiles(pkg);
          break;
        case AccessControlEnum.InstallForCustomProfiles.toString().toUpperCase():
          adminCheckAll = false;
          allUserCheckAll = false;
          this['disableProfileSel_' + pkg.packageId] = false;
          if (onload)
            this['selectedProfile' + pkg.packageId] = pkg.customProfileIds;
          this['open_' + pkg.packageId] = false;
          break;
        default:
          adminCheckAll = false;
          allUserCheckAll = false;
          customProfileCheckAll = false;
          break;
      }
    });

    this.isHeadInstallForAdmin = adminCheckAll;
    this.isHeadInstallForAllUsers = allUserCheckAll;
    this.isHeadInstallForCustomProfiles = customProfileCheckAll;
  }

  private clearSelectedProfiles(pkg: PackageAccessControl) {
    this['disableProfileSel_' + pkg.packageId] = true;
    this['selectedProfile' + pkg.packageId] = [];
    pkg.inputHasError = false;
  }

  private setSystemAdminAsDefaultProfileForPackageAccess(pkg: PackageAccessControl) {
    if (!this.commonService.isEmpty(this.sysAdminId) && !this['selectedProfile' + pkg.packageId].includes(this.sysAdminId))
      this['selectedProfile' + pkg.packageId].push(this.sysAdminId);
  }

  /* Method triggered when package access checkbox is changed */
  onItemChange(event: any, item: PackageAccessControl) {
    item.accessibility = event.target.value;
    this.setSystemAdminAsDefaultProfileForPackageAccess(item);
    this.setupAccessibilityHeaderCheckboxes();
  }

  getProfileNameById(profileId: string) {
    return this.user_Profile[this.user_Profile.map(x => x.value).indexOf(profileId)].label;
  }

  /* Method to trigger when Select all header checkbox is triggered from Access Control Modal */
  onSelectAll(event: any, accessControl: any) {

    if (event.target.checked && accessControl.toUpperCase() === AccessControlEnum.InstallForAdminOnly.toString().toUpperCase()) {
      this.packageAccessControl.forEach(x => {
        x.accessibility = AccessControlEnum.InstallForAdminOnly.toString()
        this.clearSelectedProfiles(x);
      });
      this.isHeadInstallForAdmin = true;
      this.isHeadInstallForAllUsers = false;
      this.isHeadInstallForCustomProfiles = false;
    }
    else if (event.target.checked && accessControl.toUpperCase() === AccessControlEnum.InstallForAllUsers.toString().toUpperCase()) {
      this.packageAccessControl.forEach(x => {
        x.accessibility = AccessControlEnum.InstallForAllUsers.toString()
        this.clearSelectedProfiles(x);
      });
      this.isHeadInstallForAdmin = false;
      this.isHeadInstallForCustomProfiles = false;
      this.isHeadInstallForAllUsers = true;
    }
    else if (event.target.checked && accessControl.toUpperCase() === AccessControlEnum.InstallForCustomProfiles.toString().toUpperCase()) {
      this.packageAccessControl.forEach(x => { x.accessibility = AccessControlEnum.InstallForCustomProfiles.toString(); this['disableProfileSel_' + x.packageId] = false; this.setSystemAdminAsDefaultProfileForPackageAccess(x) })
      this.isHeadInstallForAdmin = false;
      this.isHeadInstallForAllUsers = false;
      this.isHeadInstallForCustomProfiles = true;
    }
    else {
      this.packageAccessControl.forEach(x => {
        x.accessibility = "";
        // this.snapShotOfPackageAccessControl.forEach(element => {
        //   if (x.id === element.id)
        //     x.accessibility = element.accessibility
        // });
      });
      this.setupAccessibilityHeaderCheckboxes();
    }
  }

  /* Method to open a Toastand and flag for sussecc or failure msg */
  openToast(msg: string, success: Boolean = false) {
    this.toastMsg = msg;
    if (success)
      this.toastVariant = 'success';
    else
      this.toastVariant = 'error';
    this.showTopToast = true;
  }

  alertToastToCheckAdobeInstall() {
    this.toastMsg = "Please check the Add-ons Section";
    this.toastVariant = 'warning'
    this.showTopToast = true;
  }

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}
