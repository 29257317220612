<div class="slds-context-bar">
    <div class="slds-context-bar__primary">
        <div class="slds-context-bar__item slds-context-bar__dropdown-trigger slds-dropdown-trigger slds-dropdown-trigger_click slds-no-hover">
            <div class="slds-context-bar__icon-action">
                <img class="img" width="30" alt="Conga Logo" src="../../assets/images/logo-conga.svg" />
            </div>
            <span class="slds-context-bar__label-action slds-context-bar__app-name">
        <span class="slds-truncate" title="Push Upgrade">Push Upgrade</span>
            </span>
        </div>
    </div>
    <nav class="slds-context-bar__secondary" role="navigation">
        <ul class="slds-grid">
            <li class="slds-context-bar__item" routerLinkActive="slds-is-active">
                <a [routerLink]="['/ui/consent-form']" class="slds-context-bar__label-action" title="Home">
                    <span class="slds-truncate" title="Org Registration Form">Org Registration Form</span>
                </a>
            </li>
            <li class="slds-context-bar__item" routerLinkActive="slds-is-active" [routerLinkActiveOptions]="{exact: true}">
                <a [routerLink]="['/ui/release-detail']" class="slds-context-bar__label-action" title="Menu Item">
                    <span class="slds-truncate" title="Release Details">Installed Release Details</span>
                </a>
            </li>
        </ul>
    </nav>
    <a class="logout-icon" (click)="logoutUserFromSalesForce();">
        <span title="Log Out">
      <ngl-icon iconName='utility:logout' size="small"></ngl-icon>
    </span>
    </a>
</div>
