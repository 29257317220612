<div class="main common-settings">

    <div class="flex rows">

        <div class="flex">
            <div class="flex columns">
                <strong class="title">Maintenance</strong>
                <span class="sub-title">Do you wish to enable maintenance page?</span>
            </div>
            <ngl-checkbox-toggle>
                <input ngl type="checkbox" [(ngModel)]="isSiteUnderMaintenance">
            </ngl-checkbox-toggle>
        </div>

        <div class="flex">
            <div class="flex columns">
                <strong class="title">Notification Configuration</strong>
                <span class="sub-title">Do you want to send an email for org lifecycle?</span>
            </div>
            <ngl-checkbox-toggle>
                <input ngl type="checkbox" [(ngModel)]="isSendEmailForOrgLifecycle">
            </ngl-checkbox-toggle>
        </div>

    </div>

    <br />
    <strong class="title">Minimum Release Version</strong>
    <div class="flex rows">
        <div class="flex rows">
            <span class="sub-title">Minimum supported release version for patch</span>
            <ngl-combobox [(open)]="releaseVersionOpen" [options]="allReleaseVersionOptions"
                [(selection)]="minSupportedReleaseVersionForPatch">
                <input nglCombobox>
            </ngl-combobox>
        </div>
        <div class="flex rows"></div>
    </div>


    <br>
    <h3 class="title">Smart Schedule Settings</h3>
    <div class="flex columns">
        <div class="flex rows">
            <div class="flex rows">
                <span>Days before finalize schedule</span>
                <input ngl type="number" min="0" oninput="validity.valid||(value='0');"
                    [(ngModel)]="smartScheduleSettingInfo.timeInDaysBeforeFinalzeSchedule" required>
            </div>
            <div class="flex rows">
                <span>Days to schedule</span>
                <input ngl type="number" min="0" oninput="validity.valid||(value='0');"
                    [(ngModel)]="smartScheduleSettingInfo.timeInDaysToSchedule" required>
            </div>
        </div>
        <div class="flex rows">
            <div class="flex rows">
                <span>Days to send reminder</span>
                <input ngl type="number" min="0" oninput="validity.valid||(value='0');"
                    [(ngModel)]="smartScheduleSettingInfo.timeInDaysToSendRemainderNotification" required>
            </div>
            <div class="flex rows">
                <span>Maximum org allowed to schedule</span>
                <input ngl type="number" min="0" oninput="validity.valid||(value='0');"
                    [(ngModel)]="smartScheduleSettingInfo.maxOrgAllowedToSchedule" required>
            </div>
        </div>
    </div>

    <br>
    <h3 class="title">GA AutoUpgrade Schedule Settings</h3>
    <div class="flex columns">
        <div class="flex rows">
            <div class="flex rows">
                <div class="flex columns">
                    <strong class="title">Auto Upgrade Sandbox</strong>
                    <span class="sub-title">Do you wish to enable AutoUpgrade for Sandbox?</span>
                </div>
                <ngl-checkbox-toggle enabledText="Enabled" disabledText="Disabled">
                    <input ngl type="checkbox" [(ngModel)]="smartScheduleSettingInfo.isGAAutoUpgradeEnabledForSandbox">
                </ngl-checkbox-toggle>
            </div>
            <div class="flex rows">
                <div class="flex columns">
                    <strong class="title">Auto Upgrade Production</strong>
                    <span class="sub-title">Do you wish to enable AutoUpgrade for Production?</span>
                </div>
                <ngl-checkbox-toggle enabledText="Enabled" disabledText="Disabled">
                    <input ngl type="checkbox"
                        [(ngModel)]="smartScheduleSettingInfo.isGAAutoUpgradeEnabledForProduction">
                </ngl-checkbox-toggle>
            </div>
        </div>
        <div class="flex rows">
            <div class="flex rows">
                <div class="flex columns">
                    <strong class="title">Sandbox Upgrade Schedule Date & Time</strong>
                    <span class="sub-title">
                        <ngl-pill class="schedule-date-text">
                            {{this.authService.dateFormatter(this.smartScheduleSettingInfo.autoUpgradeDateForSandbox.toString())}}
                        </ngl-pill>
                    </span>
                </div>
                <div class="flex columns">
                    <ngl-datepicker-input [(ngModel)]="scheduleDateSB" #dt="ngModel" [class.slds-has-error]="!dt.valid"
                        [showToday]="true" [min]="minScheduleDateSB" [cleave]="cleaveOptions"
                        (valueChange)="scheduleDateChange($event)">
                        <input nglDatepickerInput type="text">
                    </ngl-datepicker-input>
                    <ngl-combobox [(open)]="scheduleTimeOpenSB" [options]="scheduleTimeOptionsSB"
                        [(selection)]="scheduleTimeSelectionSB" (selectionChange)="scheduleTimeChange($event)">
                        <input nglCombobox>
                    </ngl-combobox>
                </div>
            </div>
            <div class="flex rows">
                <div class="flex columns">
                    <strong class="title">Production Upgrade Schedule Date & Time</strong>
                    <span class="sub-title">
                        <ngl-pill class="schedule-date-text">
                            {{this.authService.dateFormatter(this.smartScheduleSettingInfo.autoUpgradeDateForProduction.toString())}}
                        </ngl-pill>
                    </span>
                </div>
                <div class="flex columns">
                    <ngl-datepicker-input [(ngModel)]="scheduleDateProd" #dt="ngModel"
                        [class.slds-has-error]="!dt.valid" [showToday]="true" [min]="minScheduleDateProd"
                        [cleave]="cleaveOptions" (valueChange)="scheduleDateChange($event, false)">
                        <input nglDatepickerInput type="text">
                    </ngl-datepicker-input>
                    <ngl-combobox [(open)]="scheduleTimeOpenProd" [options]="scheduleTimeOptionsProd"
                        [(selection)]="scheduleTimeSelectionProd" (selectionChange)="scheduleTimeChange($event, false)">
                        <input nglCombobox>
                    </ngl-combobox>
                </div>
            </div>
        </div>
        <div class="flex rows">
            <div class="flex rows">
                <span>Days before scheduling Sandbox Upgrade</span>
                <input ngl type="number" min="0" oninput="validity.valid||(value='0');"
                    [(ngModel)]="smartScheduleSettingInfo.timeInDaysToScheduleAutoUpgradeForSandbox" required>
            </div>
            <div class="flex rows">
                <span>Days before scheduling Production Upgrade</span>
                <input ngl type="number" min="0" oninput="validity.valid||(value='0');"
                    [(ngModel)]="smartScheduleSettingInfo.timeInDaysToScheduleAutoUpgradeForProduction" required>
            </div>
        </div>
        <div class="flex rows">
            <div class="flex rows">
                <div class="flex columns">
                    <strong class="title">Enable Schedule Finalization</strong>
                    <span class="sub-title">Do you wish to freeze scheduled autoUpgrade for orgs?</span>
                </div>
                <ngl-checkbox-toggle enabledText="Enabled" disabledText="Disabled">
                    <input ngl type="checkbox"
                        [(ngModel)]="smartScheduleSettingInfo.finaliseAutoUpgradeScheduleCreation">
                </ngl-checkbox-toggle>
            </div>
            <div class="flex rows">
                <span>Auto Batch Creation Time Differernce in Hours</span>
                <input ngl type="number" min="0" oninput="validity.valid||(value='0');"
                    [(ngModel)]="smartScheduleSettingInfo.timeDiffInHoursBetweenAutoBatchCreation" required>
            </div>
        </div>
    </div>

    <br>
    <h3 class="title">Miscellaneous Settings</h3>
    <div class="flex columns">
        <div class="flex rows">
            <div class="flex rows">
                <span class="flex-1">Interval for patch release checking in minutes</span>
                <input class="flex-1" ngl type="number" min="0" oninput="validity.valid||(value='0');"
                    [(ngModel)]="backgroundServiceSettings.chekPatchReleaseTaskIntervalInMinutes" required>
            </div>
            <div class="flex rows">
                <span>Interval to refresh metadata for latest patches in minutes</span>
                <input ngl type="number" min="0" oninput="validity.valid||(value='0');"
                    [(ngModel)]="backgroundServiceSettings.refreshMetadataForLatestPatchesTaskIntervalInMinutes"
                    required>
            </div>
        </div>
        <div class="flex rows">
            <div class="flex rows">
                <span>Interval for smart email schedule in minutes</span>
                <input ngl type="number" min="0" oninput="validity.valid||(value='0');"
                    [(ngModel)]="backgroundServiceSettings.smartScheduleEmailReminderTaskIntervalInMinutes" required>
            </div>
            <div class="flex rows">
                <span>Interval for Org Lifecycle management in minutes</span>
                <input ngl type="number" min="0" oninput="validity.valid||(value='0');"
                    [(ngModel)]="backgroundServiceSettings.orgLifeCycleManagementTaskIntervalInMinutes" required>
            </div>
        </div>
        <div class="flex rows">
            <div class="flex rows">
                <span>Interval to refresh metadata of Package Version in minutes</span>
                <input ngl type="number" min="0" oninput="validity.valid||(value='0');"
                    [(ngModel)]="backgroundServiceSettings.packageVersionMetadataRefreshTaskIntervalInMinutes" required>
            </div>
            <div class="flex rows">
            </div>
        </div>
    </div>

    <br>
    <h3 class="title">Session Settings</h3>
    <div class="flex columns">
        <div class="flex rows">
            <div class="flex rows">
                <span>Access token expiry in minutes</span>
                <input ngl type="number" min="1" oninput="validity.valid||(value='1');"
                    [(ngModel)]="sessionSettings.timeToExpireAccessTokenInMinutes" required>
            </div>
            <div class="flex rows">
                <span>Refresh token expiry in hours</span>
                <input ngl type="number" min="1" oninput="validity.valid||(value='1');"
                    [(ngModel)]="sessionSettings.timeToExpireRefreshTokenInHours" required>
            </div>
        </div>
    </div>


    <div class="flex btn-submit">
        <button nglButton (click)="updateSettings()">Update</button>
    </div>
</div>

<div class="loading_spinner" *ngIf="isLoading">
    <ngl-spinner size="large" variant="brand" alternativeText="Loading"></ngl-spinner>
</div>

<ngl-modal header="Warning" [(open)]="confirmMaintenance" [prompt]="'warning'" dismissOnClickOutside="false">
    <div class="slds-p-around_medium">
        <p>
            Are you sure want to enable maintenance page? This will disable push-upgrade customers' login from our
            portal.
        </p>
    </div>
    <ng-template nglModalFooter>
        <button class="slds-button slds-button_neutral" (click)="onConfirmMaintenance()">Okay</button>
        <button class="slds-button slds-button_neutral" (click)="confirmMaintenance = false">Cancel</button>
    </ng-template>
</ngl-modal>