<div class="data-container mat-elevation-z8">
    <table #TableOneSort="matSort" mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

        <ng-container matColumnDef="appName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> User Name </th>
            <td mat-cell *matCellDef="let element"> {{element.appName}} </td>
        </ng-container>

        <ng-container matColumnDef="emailId">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Email Id </th>
            <td mat-cell *matCellDef="let element"> {{element.emailId}} </td>
        </ng-container>

        <ng-container matColumnDef="userType">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> User Type </th>
            <td mat-cell *matCellDef="let element"> {{element.userType}} </td>
        </ng-container>

        <ng-container matColumnDef="isActive">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Is Active </th>
            <td mat-cell *matCellDef="let element">{{element.isActive ? 'Yes' : 'No'}} </td>
        </ng-container>

        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef>
                <span *readOnlyBtn>
                    Action
                </span>
            </th>
            <td mat-cell *matCellDef="let element">
                <button *readOnlyBtn class="slds-button slds-icon_container slds-icon-utility-info"
                    [nglTooltip]="'Update'" nglTooltipInteractive="true" nglTooltipDelay="100" nglTooltipOpenAuto
                    (click)="editUserDetails(element)">
                    <svg class="slds-button__icon" aria-hidden="true">
                        <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#edit">
                        </use>
                    </svg>
                </button>
                <button *readOnlyBtn class="slds-button slds-icon_container slds-icon-utility-info"
                    [nglTooltip]="'Delete'" nglTooltipInteractive="true" nglTooltipDelay="100" nglTooltipOpenAuto
                    (click)="confirmDeleteUser(element)">
                    <svg class="slds-button__icon" aria-hidden="true">
                        <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#delete">
                        </use>
                    </svg>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="6" style="text-align: center;">
                <span>No results found</span>
            </td>
        </tr>
    </table>

    <mat-paginator [length]="length" [pageIndex]="pageIndex" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
        [showFirstLastButtons]="true" (page)="handlePageEvent($event)">
    </mat-paginator>

</div>

<div class="loading_spinner" *ngIf="isLoading">
    <ngl-spinner size="large" variant="brand" alternativeText="Loading"></ngl-spinner>
</div>

<ngl-modal [header]="editUser ? 'Update User' : 'Add New User'" [(open)]="showUserModal" size="large">
    <div class="slds-p-around_medium">
        <div class="user-form">
            <table class="table borderless">
                <tbody>
                    <tr>
                        <td>User Name</td>
                        <td>
                            <input ngl type="text" [(ngModel)]="editorData.appName" [disabled]="editUser">
                            <span *ngIf="isSubmitted && !editorData.appName" class="slds-text-color_error">
                                User Name is required
                            </span>
                        </td>
                        <td>Email Id</td>
                        <td>
                            <input ngl type="text" [(ngModel)]="editorData.emailId">
                            <span *ngIf="isSubmitted && !editorData.emailId" class="slds-text-color_error">
                                Email Id is required
                            </span>
                            <span *ngIf="isSubmitted && editorData.emailId && !emailPattern.test(editorData.emailId)"
                                class="slds-text-color_error">
                                Invalid Email Id
                            </span>

                        </td>
                    </tr>
                    <tr>
                        <td>User Type</td>
                        <td>
                            <ngl-combobox [(open)]="userTypeOpen" [options]="userTypeList"
                                [(selection)]="editorData.userType">
                                <input nglCombobox>
                            </ngl-combobox>
                            <span *ngIf="isSubmitted && !editorData.userType" class="slds-text-color_error">
                                User Type is required
                            </span>
                        </td>
                        <td *ngIf="editUser">Update Password</td>
                        <td *ngIf="editUser">
                            <div class="slds-form-element">
                                <label class="slds-checkbox_toggle slds-grid">
                                    <input ngl type="checkbox" [(ngModel)]="editorData.isChangePassword">
                                    <span id="checkbox-toggle-20" class="slds-checkbox_faux_container"
                                        aria-live="assertive">
                                        <span class="slds-checkbox_faux"></span>
                                        <span class="slds-checkbox_on">Yes</span>
                                        <span class="slds-checkbox_off">No</span>
                                    </span>
                                </label>
                            </div>
                        </td>
                        <td *ngIf="!editUser">Password</td>
                        <td *ngIf="!editUser">
                            <div class="slds-form-element__control slds-input-has-icon slds-input-has-icon_right">
                                <mat-icon *ngIf="!editorData.showPassword"
                                    class="clickable-input-icon slds-icon slds-input__icon slds-input__icon_right"
                                    (click)="editorData.showPassword = true">visibility</mat-icon>
                                <mat-icon *ngIf="editorData.showPassword"
                                    class="clickable-input-icon slds-icon slds-input__icon slds-input__icon_right"
                                    (click)="editorData.showPassword = false">visibility_off</mat-icon>
                                <input ngl name="custom-key" [type]="editorData.showPassword ? 'text' : 'password'"
                                    autocomplete="new-password" class="slds-input" id="text-input-id-1"
                                    [(ngModel)]="editorData.clientId">
                            </div>
                            <span *ngIf="isSubmitted && !editorData.clientId" class="slds-text-color_error">
                                Password is required
                            </span>
                        </td>
                    </tr>
                    <tr>

                        <td *ngIf="(editUser && editorData.isChangePassword)">Password</td>
                        <td *ngIf="(editUser && editorData.isChangePassword)">
                            <div class="slds-form-element__control slds-input-has-icon slds-input-has-icon_right">
                                <mat-icon *ngIf="!editorData.showPassword"
                                    class="clickable-input-icon slds-icon slds-input__icon slds-input__icon_right"
                                    (click)="editorData.showPassword = true">visibility</mat-icon>
                                <mat-icon *ngIf="editorData.showPassword"
                                    class="clickable-input-icon slds-icon slds-input__icon slds-input__icon_right"
                                    (click)="editorData.showPassword = false">visibility_off</mat-icon>
                                <input ngl name="custom-key" [type]="editorData.showPassword ? 'text' : 'password'"
                                    autocomplete="off" class="slds-input" id="text-input-id-1"
                                    [(ngModel)]="editorData.clientId">
                            </div>
                            <!-- <input ngl type="text" [(ngModel)]="editorData.clientId"> -->
                            <span *ngIf="isSubmitted && !editorData.clientId" class="slds-text-color_error">
                                Password is required
                            </span>
                        </td>
                    </tr>

                </tbody>
            </table>
            <br>
        </div>
    </div>
    <ng-template nglModalFooter>
        <button class="slds-button slds-button_neutral"
            (click)="showUserModal = false; editSettings = false;;isSubmitted = false">Cancel</button>
        <button class="slds-button slds-button_brand" (click)="onSubmitUser()">{{editUser ?
            'Update' :
            'Submit'}}</button>
    </ng-template>
</ngl-modal>

<ngl-modal [header]="deactivateUser ? 'Deactivate User' : 'Activate User'" [(open)]="openDeleteConfirmationModel">
    <div class="slds-p-around_medium">
        <p>Are you sure to {{deactivateUser ? 'deactivate' : 'activate'}} the user <b>{{getUserName()}}</b> ?</p>
    </div>
    <ng-template nglModalFooter>
        <button class="slds-button slds-button_neutral" (click)="openDeleteConfirmationModel = false">Cancel</button>
        <button class="slds-button slds-button_brand" (click)="userStatusChange()">Ok</button>
    </ng-template>
</ngl-modal>