import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../shared/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AdminSettingsGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) { }
  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.authService.currentUser.UserType !== 'SystemAdmin') {
      this.router.navigate(['ui/pagenotfound']);
      return false;
    }
    return true;
  }

}
