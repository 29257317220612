import { Component, OnInit } from '@angular/core';
import { Chart, ChartConfiguration } from 'chart.js';

@Component({
  selector: 'app-jobs-status-report',
  templateUrl: './jobs-status-report.component.html',
  styleUrls: ['./jobs-status-report.component.css']
})
export class JobsStatusReportComponent implements OnInit {

  jobStatusChart: ChartConfiguration = {
    type: 'doughnut',
    data: {
      labels: ['Sandbox', 'Production'],
      datasets: [{
        data: [300, 200],
        backgroundColor: [
          'rgb(112, 22, 93)',
          'rgb(42, 89, 128)',
        ],
      }]
    },
    options: {
      responsive: true,
      plugins: {
        legend: {
          position: 'left',
        },
        title: {
          display: true,
          text: 'No. of Orgs Registered'
        }
      }
    },
  };
  constructor() { }

  ngOnInit(): void {
    new Chart(<HTMLCanvasElement>document.getElementById('job-status'), this.jobStatusChart);
  }

}
