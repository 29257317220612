<div class="data-container mat-elevation-z8">
    <table #TableOneSort="matSort" mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

        <ng-container matColumnDef="subscriberPackageVersionKey">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Version Key </th>
            <td mat-cell *matCellDef="let element"> {{element.subscriberPackageVersionKey}} </td>
        </ng-container>

        <ng-container matColumnDef="requiredPassword">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Require Password </th>
            <td mat-cell *matCellDef="let element"> {{element.requiredPassword? 'Yes'
                : 'No'}} </td>
        </ng-container>

        <ng-container matColumnDef="requiredCustomInstallKey">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Require Custom Install Key </th>
            <td mat-cell *matCellDef="let element"> {{element.requiredCustomInstallKey ? 'Yes'
                : 'No'}} </td>
        </ng-container>

        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef>
                <span *readOnlyBtn>
                    Action
                </span>
            </th>
            <td mat-cell *matCellDef="let element">
                <button *readOnlyBtn class="slds-button slds-icon_container slds-icon-utility-info"
                    [nglTooltip]="'Update'" nglTooltipInteractive="true" nglTooltipDelay="100" nglTooltipOpenAuto
                    (click)="editPackageUpgradeSettings(element)">
                    <svg class="slds-button__icon" aria-hidden="true">
                        <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#edit">
                        </use>
                    </svg>
                </button>
                <button *readOnlyBtn class="slds-button slds-icon_container slds-icon-utility-info"
                    [nglTooltip]="'Delete'" nglTooltipInteractive="true" nglTooltipDelay="100" nglTooltipOpenAuto
                    (click)="confirmDeletePackageUpgradeSettings(element)">
                    <svg class="slds-button__icon" aria-hidden="true">
                        <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#delete">
                        </use>
                    </svg>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="6" style="text-align: center;">
                <span>No results found</span>
            </td>
        </tr>
    </table>

    <mat-paginator [length]="length" [pageIndex]="pageIndex" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
        [showFirstLastButtons]="true" (page)="handlePageEvent($event)">
    </mat-paginator>

</div>

<div class="loading_spinner" *ngIf="isLoading">
    <ngl-spinner size="large" variant="brand" alternativeText="Loading"></ngl-spinner>
</div>

<ngl-modal [header]="editSettings ? 'Update Package Upgrade Settings' : 'Create New Package Upgrade Settings'"
    [(open)]="showSettingsModal" size="large">
    <div class="slds-p-around_medium">
        <div class="package-upgrade-settings-form">
            <table class="table borderless">
                <tbody>
                    <tr>
                        <td>Subscriber Package Version Key</td>
                        <td>
                            <input ngl type="text" [disabled]="editSettings"
                                [(ngModel)]="editorData.subscriberPackageVersionKey">
                            <span *ngIf="isSubmitted && !editorData.subscriberPackageVersionKey"
                                class="slds-text-color_error">Subscriber Package Version Key is
                                required</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <ngl-checkbox-toggle label="Require Password?">
                                <input ngl type="checkbox" [(ngModel)]="editorData.requiredPassword"
                                    (ngModelChange)="!$event ? (editorData.requiredCustomInstallKey = false) : ''">
                            </ngl-checkbox-toggle>
                        </td>
                        <td>
                            <ngl-checkbox-toggle label="Require Custom Install Key?">
                                <input ngl type="checkbox" [disabled]="!editorData.requiredPassword"
                                    [(ngModel)]="editorData.requiredCustomInstallKey"
                                    (ngModelChange)="editorData.customInstallKey = ''">
                            </ngl-checkbox-toggle>
                        </td>
                    </tr>
                    <tr>
                        <td *ngIf="editorData.requiredPassword && editorData.requiredCustomInstallKey">Custom Package
                            Version Key</td>
                        <td *ngIf="editorData.requiredPassword && editorData.requiredCustomInstallKey">
                            <div class="slds-form-element">
                                <label class="slds-form-element__label" for="text-input-id-1">Input Label</label>
                                <div class="slds-form-element__control slds-input-has-icon slds-input-has-icon_right">
                                    <mat-icon *ngIf="!editorData.showCustomKey"
                                        class="clickable-input-icon slds-icon slds-input__icon slds-input__icon_right"
                                        (click)="editorData.showCustomKey = true">visibility</mat-icon>
                                    <mat-icon *ngIf="editorData.showCustomKey"
                                        class="clickable-input-icon slds-icon slds-input__icon slds-input__icon_right"
                                        (click)="editorData.showCustomKey = false">visibility_off</mat-icon>
                                    <input ngl name="custom-key" [type]="editorData.showCustomKey ? 'text' : 'password'"
                                        autocomplete="new-password" class="slds-input" id="text-input-id-1"
                                        [(ngModel)]="editorData.customInstallKey">
                                </div>
                            </div>
                            <span
                                *ngIf="isSubmitted && editorData.requiredCustomInstallKey && !editorData.customInstallKey"
                                class="slds-text-color_error">Custom Install Key is
                                required</span>
                        </td>
                    </tr>
                    <tr>
                    </tr>
                </tbody>
            </table>
            <br>
        </div>
    </div>
    <ng-template nglModalFooter>
        <button class="slds-button slds-button_neutral"
            (click)="showSettingsModal = false; editSettings = false;">Cancel</button>
        <button class="slds-button slds-button_brand" (click)="onSubmitPackageUpgradeSettings()">{{editSettings ?
            'Update' :
            'Submit'}}</button>
    </ng-template>
</ngl-modal>

<ngl-modal header="Delete Package Upgrade Settings" [(open)]="openDeleteConfirmationModel">
    <div class="slds-p-around_medium">
        <p>Are you sure to Delete the watcher</p>
    </div>
    <ng-template nglModalFooter>
        <button class="slds-button slds-button_neutral" (click)="openDeleteConfirmationModel = false">Cancel</button>
        <button class="slds-button slds-button_brand" (click)="deletePackageUpgradeSettings()">Ok</button>
    </ng-template>
</ngl-modal>