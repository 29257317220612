<div class="slds-context-bar">
    <div class="slds-context-bar__primary">
        <div class="slds-context-bar__item slds-context-bar__dropdown-trigger slds-dropdown-trigger slds-dropdown-trigger_click slds-no-hover">
            <div class="slds-context-bar__icon-action">
                <img class="img" width="30" alt="Conga Logo" src="../../assets/images/logo-conga.svg" />
            </div>
            <span class="slds-context-bar__label-action slds-context-bar__app-name">
      <span class="slds" title="Push Upgrade">Welcome to Conga Push Upgrade</span>
            </span>
        </div>
    </div>
</div>
<br>
<br>

<div class="slds-scoped-notification slds-media slds-media_center slds-scoped-notification_light" role="status">
    <div class="slds-media__figure">
        <span class="slds-icon_container slds-icon-utility-info" title="information">
  <svg class="slds-icon slds-icon_small slds-icon-text-default" aria-hidden="true">
  <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#info"></use>
  </svg>
  <span class="slds-assistive-text">information</span>
        </span>
    </div>
    <div class="slds-media__body">
        <p>Welcome to Conga's Push Upgrade program. Here we shall be upgrading Conga managed packages installed in the registered orgs to latest major release in an automated way and make all the new features and fix availble for you in your org itself.
        </p>
    </div>
</div>
<br><br>

<div class="text-center">
    <h2>Kindly select one of the options depending on the type of your salesforce Org.
    </h2>
</div>
<br>

<div class="text-center">
    <button type="button" class="slds-button slds-button_brand" (click)="redirectToProductionOrg()">Production</button>
    <button type="button" class="slds-button slds-button_brand" (click)="redirectToSandboxOrg()">Sandbox</button>
</div>