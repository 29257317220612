import { Component, OnInit, Input } from '@angular/core';
import { JobSummary } from 'src/app/interface/upgrade-summary';

@Component({
  selector: 'app-upgraded-job-report',
  templateUrl: './upgraded-job-report.component.html',
  styleUrls: ['./upgraded-job-report.component.css']
})
export class UpgradedJobReportComponent implements OnInit {


  _jobSummary: JobSummary;
  get jobSummary(): JobSummary {
    return this._jobSummary;
  }
  @Input() set jobSummary(value: JobSummary) {
    this._jobSummary = value;
    if (this._jobSummary) {
      this.updateSummary();
    }
  }

  productionSandboxData!: Array<any>;
  productionStatusData!: Array<any>;
  sandboxStatusData!: Array<any>;

  constructor() { }

  ngOnInit(): void { }

  updateSummary() {
    this.productionSandboxData = [];
    this.productionSandboxData.push({ label: "Total", count: this.jobSummary.totalUpgradeJobCount });
    this.productionSandboxData.push({ label: "Sandbox", count: this.jobSummary.sandboxUpgradeJobCount });
    this.productionSandboxData.push({ label: "Production", count: this.jobSummary.productionUpgradeJobCount });

    this.sandboxStatusData = [];
    this.sandboxStatusData.push({ label: "Success", count: this.jobSummary.sandboxUpgradeJobStautsCount.success });
    this.sandboxStatusData.push({ label: "Failure", count: this.jobSummary.sandboxUpgradeJobStautsCount.failure });
    this.sandboxStatusData.push({ label: "In Progress", count: this.jobSummary.sandboxUpgradeJobStautsCount.inProgress });

    this.productionStatusData = [];
    this.productionStatusData.push({ label: "Success", count: this.jobSummary.productionUpgradeJobStautsCount.success });
    this.productionStatusData.push({ label: "Failure", count: this.jobSummary.productionUpgradeJobStautsCount.failure });
    this.productionStatusData.push({ label: "In Progress", count: this.jobSummary.productionUpgradeJobStautsCount.inProgress });
  }
}
