import { Version } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { ReleaseConfig } from '../interface/release-config';
import { BehaviorSubject } from 'rxjs';
import { HttpResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  maintenanceWarning$ = new BehaviorSubject(false)
  constructor() { }

  //**To remove the last 3 digits (i.e) checksum from the Salesforce Org ID */
  removeOrgIdCheksum(orgID: string) {
    return orgID.slice(0, -3);
  }

  //Empty check condition.
  isEmpty(val: any) {
    return (val === undefined || val == null || val.length <= 0) ? true : false;
  }

  //**Format date to display on UI */
  dateFormatter(inputDate: string) {
    var date = new Date(inputDate);
    if (date.getFullYear() < 2019)
      return "N/A";

    var day = date.getDate();
    var month = date.toDateString().substr(4, 3);
    var year = date.getFullYear();
    var timeZome = /\((.*)\)/.exec(date.toString())[1];
    var time = ("00" + date.getHours()).slice(-2) + ":"
      + ("00" + date.getMinutes()).slice(-2)
      + ":" + ("00" + date.getSeconds()).slice(-2);
    return day + '-' + month + '-' + year + ' ' + time + ' (' + timeZome + ')';
  }

  //**Convert minutes into ** hr ** mins format */
  convertMinToHour(timeInMinutes) {

    // PU-1423 Duration of package is showing negative number. Mark it as 0 if its negative.(Client Date/Time not accurate).
    if (timeInMinutes < 0) {
      return '0 mins';
    }

    if (timeInMinutes < 60) {
      return timeInMinutes + ' mins';
    }
    else {
      var hours = Math.floor(timeInMinutes / 60);
      var min = timeInMinutes % 60;
      return hours + ' hr' + ' ' + min + ' mins';
    }
  }
  getTimeDuration(startDate: Date, endDate: Date) {
    if (new Date(endDate).getFullYear() < 2019 && new Date(startDate).getFullYear() > 2019) {
      return this.convertMinToHour(Math.round(((new Date().getTime() - new Date(startDate).getTime()) / 60000)));
    }
    else {
      return this.convertMinToHour(Math.round(((new Date(endDate).getTime() - startDate.getTime()) / 60000)));
    }
  }
  getVersionString(input: string): string {
    return (Number(input) < 10 ? "0" : "") + input;
  }
  versionCompare(firstInput: Version, secondInput: Version): number {
    var firstInputMajor = Number(firstInput.major);
    var firstInputMinor = Number(firstInput.minor);
    var firstInputPatch = Number(firstInput.patch);

    var secondInputMajor = Number(secondInput.major);
    var secondInputMinor = Number(secondInput.minor);
    var secondInputPatch = Number(secondInput.patch);

    if (firstInputMajor > secondInputMajor) return 1;
    if (firstInputMajor < secondInputMajor) return -1;
    if (firstInputMinor > secondInputMinor) return 1;
    if (firstInputMinor < secondInputMinor) return -1;
    if (firstInput.patch > secondInput.patch) return 1;
    if (firstInput.patch < secondInput.patch) return -1;

    if (!isNaN(firstInputMajor) && isNaN(secondInputMajor)) return 1;
    if (isNaN(firstInputMajor) && !isNaN(secondInputMajor)) return -1;
    if (!isNaN(firstInputMinor) && isNaN(secondInputMinor)) return 1;
    if (isNaN(firstInputMinor) && !isNaN(secondInputMinor)) return -1;
    if (!isNaN(firstInputPatch) && isNaN(secondInputPatch)) return 1;
    if (isNaN(firstInputPatch) && !isNaN(secondInputPatch)) return -1;

    return 0;
  }

  getCurrentReleaseLabel(currentActiveReleaseInfo: ReleaseConfig) {
    return currentActiveReleaseInfo.isCummulative ? currentActiveReleaseInfo.name : currentActiveReleaseInfo.displayLabel;
  }

  getFileNameFromHeader(contentDisposition) {
    if (!contentDisposition) {
      return "exported-file";
    }
    return contentDisposition
      .split(';')[1]
      .split('filename')[1]
      .split('=')[1]
      .trim()
      .match(/"([^"]+)"/)[1];
  }

  downloadFileFromAPIRes(res: HttpResponse<Blob>) {
    const contentDisposition = res.headers.get('content-disposition');

    // Extract the file name
    const filename = this.getFileNameFromHeader(contentDisposition);
    const blob = new Blob([res.body], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    const a = document.createElement('a');
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = filename;
    a.click();
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }, 0)
  }
  getURLFromTag(inputStr: string) {
    if (inputStr.match(/href="([^"]*)/)) {
      return inputStr.match(/href="([^"]*)/)[1];
    } else {
      return inputStr;
    }
  }
}
