<app-admin-navigation-panel></app-admin-navigation-panel>

<!-- Release Details -->

<div class="main">
    <br>
    <br>

    <div class="slds-page-header slds-page-header_record-home">
        <div class="slds-page-header__row">
            <div class="slds-page-header__col-title">
                <div class="slds-media">
                    <div class="slds-media__figure">
                        <span class="slds-icon_container slds-icon-standard-opportunity">
                            <svg class="slds-icon slds-page-header__icon" aria-hidden="true">
                                <use xlink:href="/assets/icons/standard-sprite/svg/symbols.svg#opportunity"></use>
                            </svg>
                        </span>
                    </div>
                    <div class="slds-media__body">
                        <div class="slds-page-header__name">
                            <div class="slds-page-header__name-title">
                                <h1>
                                    <span class="slds-page-header__title slds-truncate" title="Release Details">Release
                                        Details</span>
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="slds-page-header__col-actions">
                <div class="slds-page-header__controls">
                    <div class="slds-page-header__control">
                        <ngl-checkbox-toggle label="Display Only Active Releases">
                            <input ngl type="checkbox" [checked]="showActiveReleases"
                                (click)="toggleShowActiveReleasesFilter($event)">
                        </ngl-checkbox-toggle>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <br>

    <div class="slds-page-header">
        <div class="data-body">
            <div class="slds-page-header">
                <div class="slds-p-around_medium">
                    <table class="table borderless mat-table mat-elevation-z8">
                        <tbody>
                            <tr>
                                <td class="tdReleaseDropDown">
                                    <ngl-combobox label="Release" [options]="filteredReleaseDropdown$ | async"
                                        [(open)]="releaseOpen" [(selection)]="releaseSelection" variant="lookup"
                                        (selectionChange)="onChangeRelease($event)">
                                        <input nglCombobox placeholder="Select Release"
                                            [formControl]="releaseDropdownInputCtrl">
                                    </ngl-combobox>

                                </td>
                                <!-- </tr>
                            <tr> -->
                                <td class="actionButtons">
                                    <div *ngIf="selectedRelease" style="float: right;">
                                        <ng-container
                                            *ngIf="selectedRelease.patchNo != null && !selectedRelease.isCummulative">
                                            <button *readOnlyBtn class="slds-button slds-button_brand"
                                                (click)="refreshReleaseMetadata()">Refresh Release
                                                Metadata</button>
                                        </ng-container>
                                        <ng-container
                                            *ngIf="selectedRelease.patchNo == null || selectedRelease.isCummulative">
                                            <button *readOnlyBtn class="slds-button slds-button_brand" disabled>Refresh Release
                                                Metadata</button>
                                        </ng-container>
                                        <ng-container *ngIf="this.selectedRelease.isActive; then activeReleaseBlock; else deactiveReleaseBlock;">
                                        </ng-container>
                                        <ng-template #activeReleaseBlock>
                                            <button *readOnlyBtn class="slds-button slds-button_brand" [disabled]="!selectedRelease.canBeDeactivated"
                                                (click)="showDeactivateReleaseConfirmationModal()"> DeActivate Release </button>
                                        </ng-template>
                                        <ng-template #deactiveReleaseBlock>
                                            <button *readOnlyBtn class="slds-button slds-button_brand"
                                                (click)="changeReleaseStatus()"> Activate Release </button>
                                        </ng-template>
                                    </div>
                                    <div *ngIf="selectedRelease == null" style="float: right;">
                                        <button *readOnlyBtn class="slds-button slds-button_brand" disabled>Refresh Release
                                            Metadata</button>
                                        <button *readOnlyBtn class="slds-button slds-button_brand" disabled>Activate Release</button>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2">
                                    <div *ngIf="releaseProducts != null && releaseProducts.length > 0">
                                        <button class="slds-button slds-button_brand" [disabled]="this.expandedProducts != null && this.releaseProducts.length == this.expandedProducts.length" (click)="expandAllProducts()">
                                            Expand All </button>
                                        <button class="slds-button slds-button_brand" [disabled]="this.expandedProducts == null" (click)="collaspeAllProducts()">
                                            Collapse All </button>
                                    </div>
                                    <div *ngFor="let product of releaseProducts; let i = index">
                                        <ul ngl-accordion [(activeName)]="expandedProducts" [multiple]="true">
                                            <ng-template nglAccordionSection name="{{product.name}}"
                                                label="{{product.name}}">

                                                <div class="detail-data-container mat-elevation-z8">
                                                    <table mat-table [dataSource]="product.packages"
                                                        class="mat-elevation-z8">

                                                        <!--- Note that these columns can be defined in any order.
                                                              The actual rendered columns are set as a property on the row definition" -->

                                                        <!-- Name Column -->
                                                        <ng-container matColumnDef="packageName">
                                                            <th mat-header-cell *matHeaderCellDef
                                                                class="tdReleaseDropDown"> Package Name </th>
                                                            <td mat-cell *matCellDef="let element">
                                                                {{element.packageName}} 
                                                                <label *ngIf="element.isParticipating" style="font-weight: bold;">(New)</label> </td>
                                                        </ng-container>

                                                        <!-- Version Column -->
                                                        <ng-container matColumnDef="version">
                                                            <th mat-header-cell *matHeaderCellDef> Version </th>
                                                            <td mat-cell *matCellDef="let element"> {{element.version}}
                                                            </td>
                                                        </ng-container>

                                                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                                        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                                        </tr>
                                                    </table>
                                                </div>
                                            </ng-template>
                                        </ul>
                                    </div>
                                </td>
                            </tr>

                        </tbody>

                    </table>
                </div>
            </div>
        </div>
    </div>

    <!-- Loading Icon while making backend call -->
    <div class="loading_spinner" *ngIf="isLoading">
        <ngl-spinner size="large" variant="brand" alternativeText="Loading"></ngl-spinner>
    </div>
</div>

<div class="slds-notify_container">
    <ngl-toast iconName="utility:info" class="toast-info" *ngIf="showTopToast" [(variant)]="toastVariant"
        (close)="onClose($event); showTopToast = false;" duration="3000">
        <h3 class="slds-text-heading_small">{{toastMsg}}</h3>
    </ngl-toast>
</div>

<!-- Modal Popup for confirmation to Deactivate the Release -->
<ngl-modal header="Deactivate Release Confirmation" [(open)]="openDeactivateReleaseConfirmationModal">
    <div class="slds-p-around_medium">
        <p>Are you sure you want to Deactivate the release?</p>
    </div>
    <ng-template nglModalFooter>
        <button class="slds-button slds-button_neutral"
            (click)="cancelDeactivateReleaseConfirmationModal()">Cancel</button>
        <button class="slds-button slds-button_brand" (click)="changeReleaseStatus()">Ok</button>
    </ng-template>
</ngl-modal>