<!-- <div class="jumbotron text-center" style="margin-bottom:0">
  <h1> <img class="img" width="60" alt="Conga Logo" src="https://conga.com/themes/conga/images/logo.svg" />Push Upgrade
    - Org Registration Form</h1>
</div> -->



<router-outlet></router-outlet>

<div class="slds-notify_container">
  <ngl-toast iconName="utility:info" class="toast-info" *ngIf="(this.http.apiError$ | async).hasError" variant="error"
    (close)="this.http.apiError$.next({hasError: false, err: null})" duration="3000">
    <h3 class="slds-text-heading_small">{{(this.http.apiError$ | async).err}}</h3>
  </ngl-toast>
  <ngl-toast iconName="utility:info" class="toast-info" *ngIf="(this.http.apiSuccess$ | async).hasSuccess" variant="success"
    (close)="this.http.apiSuccess$.next({hasSuccess: false, msg: null})" duration="3000">
    <h3 class="slds-text-heading_small">{{(this.http.apiSuccess$ | async).msg}}</h3>
  </ngl-toast>
</div>