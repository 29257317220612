import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from '../shared/common.service';
import { CustomerPreference } from '../interface/customer-preference';
import { JobDetail } from '../interface/job-detail';
import { PackageInstallRecord } from '../interface/package-install-record';
import { AuthService } from '../shared/auth.service';
import { interval, Observable } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { AppConfigService } from '../app-config.service';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-admin-release-job-list',
  templateUrl: './admin-release-job-list.component.html',
  styleUrls: ['./admin-release-job-list.component.css']
})
export class AdminReleaseJobListComponent implements OnInit {
  isLoggedIn: boolean;
  displayedColumns: string[] = ['jobId', 'name', 'orgId', 'jobStartDate', 'jobDuration', 'releaseDisplayName', 'status', 'action'];
  packageDisplayedColumns: string[] = ['index', 'packageName', 'productName', 'previousPackageVersion', 'packageVersion', 'taskDuration', 'sfdcRequestStartTime', 'sfdcRequestEndTime', 'packageStatus', 'comments'];
  sanityDisplayedColumns: string[] = ['subTaskType', 'packageStatus', 'comments', 'taskDuration', 'resultUrl'];
  dataSource = new MatTableDataSource<CustomerPreference>();
  length = 100;
  pageIndex = 0;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 25, 50, 75, 100];
  searchText: string = '';
  isSandboxTab: boolean = true;
  isLoading: boolean = false;
  showTopToast = false;
  toastMsg: string;
  enableModalAutoRefresh: boolean = false;

  open: boolean = false;
  jobDetails: JobDetail;
  packageDataSource = new MatTableDataSource<PackageInstallRecord>();
  sanityDataSource = new MatTableDataSource<PackageInstallRecord>();
  orgRowData: any;
  toastVariant: string;
  disableBulkUpgradeBtn: boolean = true;
  timeZone: string;
  tzTimeZone: string;
  filterValue: string;
  modalAutoRefresh: Observable<number>;

  selection = new SelectionModel<CustomerPreference>(true, []);
  selectedTab: any = 'sandbox';

  @ViewChild('TableOneSort', { static: false }) tableOneSort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(private authservice: AuthService, private route: ActivatedRoute, private commonService: CommonService, private appConfigService: AppConfigService) {
    // Get filter value presnt in the query parameter.
    if (!this.authservice.isEmpty(this.route.snapshot.queryParamMap.get('filter'))) {
      this.filterValue = this.route.snapshot.queryParamMap.get('filter');
      this.searchText = this.filterValue;
    }
    this.modalAutoRefresh = interval(this.appConfigService.autoRefreshTimeInSec * 1000);
  }

  ngOnInit(): void {
    var date = new Date();
    this.timeZone = /\((.*)\)/.exec(date.toString())[1];
    this.tzTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.getPushUpgradeJoblist();
  }

  ngAfterViewInit() {
    this.paginator._intl.firstPageLabel = "First Page";
    this.paginator._intl.lastPageLabel = "Last Page";
    this.paginator._intl.previousPageLabel = "Prev Page";
    this.paginator._intl.nextPageLabel = "Next Page";
    this.paginator._intl.itemsPerPageLabel = "Display Records per page";

    this.dataSource.paginator = this.paginator;
  }

  onModalClose() {
    this.enableModalAutoRefresh = false;
    this.fetchPushUpgradeJoblist();
  }

  openToast(msg: string, success: Boolean = false) {
    this.toastMsg = msg;
    if (success)
      this.toastVariant = 'success';
    else
      this.toastVariant = 'error';

    this.showTopToast = true;
  }

  onClose(reason: string) {
    console.log(`Closed by ${reason}`);
  }

  openDialog(rowdata: any) {
    this.orgRowData = rowdata;
    this.enableModalAutoRefresh = true;
    this.getMoreDetailsOnJobId();
    this.modalAutoRefresh.pipe(takeWhile(x => (this.enableModalAutoRefresh))).subscribe(value => {
      this.getMoreDetailsOnJobId();
      console.log("AutoRefresh Count: " + value)
    });

  }


  getMoreDetailsOnJobId(): void {
    this.isLoading = true;
    // Check if access token is present else take the user to admin login page.
    if (this.authservice.isLoggedIn) {
      this.authservice.getJobDetails(this.orgRowData.orgId, this.orgRowData.jobId).subscribe((data: any) => {
        this.jobDetails = data;
        var packagesArray = data.packageInstallRecords.filter((ele) => ele.taskType == null || ele.taskType == "PackageUpgrade");
        for (let index = 0; index < packagesArray.length; index++) {
          var pkg = packagesArray[index];
          pkg.index = index + 1;
        }
        this.packageDataSource = packagesArray;
        this.sanityDataSource = data.packageInstallRecords.filter((ele) => ele.taskType == "Sanity" || ele.taskType == "UpgradeValidation");
        this.isLoading = false;

        for (let i = 0; i < this.jobDetails.packageInstallRecords.length; i++) {
          this.jobDetails.packageInstallRecords[i].taskDurationInString = this.commonService.getTimeDuration(new Date(this.jobDetails.packageInstallRecords[i].taskStartTime), this.jobDetails.packageInstallRecords[i].taskEndTime);
        }
        // Check if enableModalAutoRefresh is true then open the Modal pop-up.
        if (this.enableModalAutoRefresh)
          this.open = true;
        // If the job is not in progress then disable auto refresh.
        if (this.jobDetails.jobStatus !== 'In Progress')
          this.enableModalAutoRefresh = false;
      },
        error => {
          this.isLoading = false;
          console.log(error)
        });
    }
  }

  refresh(): void {
    this.isLoading = true;
    this.getMoreDetailsOnJobId();
  }

  /** Method triggered to fetch the Push-Upgrade Jobs records for the configured Product and Release Version */
  getPushUpgradeJoblist(): void {
    // Check if access token is present else take the user to admin login page.
    if (this.authservice.isLoggedIn) {
      this.isLoading = true;
      this.isLoggedIn = true;
      this.fetchPushUpgradeJoblist();
    }
    else {
      this.authservice.doLogout();
      this.authservice.redirectToAdminLogin();
    }
  }

  /** Method triggered to fetch/refresh the Push-Upgrade Jobs records */
  fetchPushUpgradeJoblist(): void {
    this.isLoading = true;
    this.authservice.getSalesForceOrgPushUpgradeJobs(this.searchText, this.pageIndex + 1, this.pageSize, this.tzTimeZone, this.isSandboxTab).subscribe(response => {
      var data = response.data as CustomerPreference[];
      this.length = response.totalCount;
      this.pageIndex = response.pageNo - 1;
      this.pageSize = response.pageSize;
      if (!this.authservice.isEmpty(this.route.snapshot.queryParamMap.get('filter')) && (this.route.snapshot.queryParamMap.get('filter') === this.filterValue) && this.isSandboxTab && data.length === 0) {
        this.tabChange('production');
        this.selectedTab = 'production';
      }

      for (let i = 0; i < data.length; i++) {
        data[i].jobDurationInString = this.commonService.getTimeDuration(new Date(data[i].jobStartDate), data[i].jobEndDate);
      }
      this.dataSource = new MatTableDataSource<CustomerPreference>(data);
      this.dataSource.sort = this.tableOneSort;
      // PU-748 Handle sorting with case insensitive behaviour.
      this.dataSource.sortingDataAccessor = (data, header) => (typeof (data[header]) !== "string" || this.authservice.isEmpty(data[header])) ? data[header] : data[header].toString().toLowerCase();
      // if (!this.authservice.isEmpty(this.filterValue))
      //   this.dataSource.filter = this.filterValue;
      this.isLoading = false;
    },
      error => {
        console.log(error)
      });
  }

  handlePageEvent(event: PageEvent) {
    this.length = event.length;
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;

    this.fetchPushUpgradeJoblist();
  }

  //**Format date to display on UI */
  dateFormatter(inputDate: string) {
    var date = this.authservice.dateFormatter(inputDate);
    if (this.authservice.isEmpty(this.timeZone))
      this.timeZone = /\((.*)\)/.exec(date.toString())[1];
    date = date.split('(')[0];
    return date;
  }

  //**Apply filter to the Org list displayed on the UI */
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    // this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.searchText != filterValue.trim().toLowerCase()) {
      this.searchText = filterValue.trim().toLowerCase();
      // this.dataSource.filter = filterValue.trim().toLowerCase();
      if (this.searchText.length == 0 || this.searchText.length >= 3) {
        // PU-1168 Need to reset the page index as 0 at the time of search.
        this.pageIndex = 0;
        this.fetchPushUpgradeJoblist();
      }
    }
  }

  retryPostUpgradeValidation(): void {
    if (this.authservice.isLoggedIn) {
      this.isLoading = true;

      this.authservice.ReTriggerPostUpgradeValidation(this.jobDetails.jobId).subscribe((response: object) => {
        this.refresh();
      }, (err) => {
        var msg = err.split("\n");
        this.openToast(msg[1], false);
        this.isLoading = false;
      });
    }
  }

  //**Apply summary tool tip for the completed jobs (i.e with success/failed) and if summary is not null or empty*/
  isJobStatusComplete(status: string, summary: string): boolean {
    var isjobComplete: boolean = false;
    if ((status.toLocaleLowerCase() === "failed" || status.toLocaleLowerCase() === "success") && !this.authservice.isEmpty(summary))
      isjobComplete = true;
    return isjobComplete;
  }

  showRetryOption(element: PackageInstallRecord): boolean {
    return ((element.subTaskType == 'Post-UpgradeValidation' || element.subTaskType == 'Post-Upgrade Validation') && element.packageStatus == 'Failed');
  }

  tabChange(tabName: string) {
    this.pageIndex = 0;
    var isSandboxTabActive = true;
    switch (tabName) {
      case "production": {
        isSandboxTabActive = false;
        break;
      }
      case "sandbox": {
        isSandboxTabActive = true;
        break;
      }
      default: {
        isSandboxTabActive = true;
        break;
      }
    }
    if (isSandboxTabActive !== this.isSandboxTab) {
      this.isSandboxTab = isSandboxTabActive;
      this.getPushUpgradeJoblist();
    }
  }

  disableResendJobStatusButton(): boolean {
    if (this.jobDetails != null
      && (this.jobDetails.jobStatus == "Success" || this.jobDetails.jobStatus == "Failed")) {
      return false;
    }

    return true;
  }

  triggerJobRubStatusReport() {
    if (this.authservice.isLoggedIn) {
      this.isLoading = true;

      this.authservice.TriggerJobRubStatusReport(this.jobDetails.jobId).subscribe((response: object) => {
        this.openToast("Pushjob run status report re-send sucessfully.", true);
        this.isLoading = false;
      }, (err) => {
        var msg = err.split("\n");
        this.openToast(msg[1], false);
        this.isLoading = false;
      });
    }
    else {
      this.authservice.doLogout();
      this.authservice.redirectToAdminLogin();
    }
  }

  exportJobListReport() {
    this.isLoading = true;
    let zone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.authservice.getAllUpgradeJobListReport("", "", zone).subscribe((res: HttpResponse<Blob>) => {
      this.commonService.downloadFileFromAPIRes(res);
      this.isLoading = false;
    }, err => {
      console.error(err);
      this.isLoading = false;
    });
  }
}
