<div>
    <h2>Org Registration Report</h2>
    <!-- <div class="production-chart-wrapper">
        <div class="total-jobs-chart-wrapper">
            <div class="total-jobs-chart"><canvas id="total-production-jobs"></canvas></div>
        </div>
        <div class="production-status-chart-wrapper">
            <div class="successful-jobs-chart-wrapper">
                <div class="successful-jobs-chart"><canvas id="successful-production-jobs"></canvas></div>
            </div>
            <div class="failed-jobs-chart-wrapper">
                <div class="failed-jobs-chart"><canvas id="failed-production-jobs"></canvas></div>
            </div>
        </div>
    </div> -->
    <div class="flex rows">
        <h3>Active Orgs</h3>
        <h3>Production</h3>
        <h3>Sandbox</h3>
    </div>
    <div class="flex rows align-flex-start">
        <table mat-table [dataSource]="productionSandboxData" class="mat-elevation-z8">
            <ng-container matColumnDef="label">
                <th mat-header-cell *matHeaderCellDef> Type </th>
                <td mat-cell *matCellDef="let element"> {{element.label}} </td>
            </ng-container>

            <ng-container matColumnDef="count">
                <th mat-header-cell *matHeaderCellDef> Count </th>
                <td mat-cell *matCellDef="let element"> {{element.count}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="['label', 'count']"></tr>
            <tr mat-row *matRowDef="let row; columns: ['label', 'count'];"></tr>
        </table>
        <table mat-table [dataSource]="productionStatusData" class="mat-elevation-z8">
            <ng-container matColumnDef="label">
                <th mat-header-cell *matHeaderCellDef> Status </th>
                <td mat-cell *matCellDef="let element"> {{element.label}} </td>
            </ng-container>

            <ng-container matColumnDef="count">
                <th mat-header-cell *matHeaderCellDef> Count </th>
                <td mat-cell *matCellDef="let element"> {{element.count}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="['label', 'count']"></tr>
            <tr mat-row *matRowDef="let row; columns: ['label', 'count'];"></tr>
        </table>
        <table mat-table [dataSource]="sandboxStatusData" class="mat-elevation-z8">
            <ng-container matColumnDef="label">
                <th mat-header-cell *matHeaderCellDef> Status </th>
                <td mat-cell *matCellDef="let element"> {{element.label}} </td>
            </ng-container>

            <ng-container matColumnDef="count">
                <th mat-header-cell *matHeaderCellDef> Count </th>
                <td mat-cell *matCellDef="let element"> {{element.count}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="['label', 'count']"></tr>
            <tr mat-row *matRowDef="let row; columns: ['label', 'count'];"></tr>
        </table>
    </div>

</div>