import { Directive, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from '../auth.service';

@Directive({
    selector: '[systemAdminAccess]'
})
export class SystemAdminAccessDirective {

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private authService: AuthService
    ) {
        this.systemAdminAccess();
    }

    systemAdminAccess() {
        if (this.authService.currentUser.UserType != 'SystemAdmin') {
            this.viewContainer.clear();
        } else {
            this.viewContainer.createEmbeddedView(this.templateRef);
        }
    }
}