import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PushUpgradeRegistrationComponent } from './push-upgrade-registration/push-upgrade-registration.component';
import { LoginComponent } from './login/login.component';
import { PushUpgradeAdminComponent } from './push-upgrade-admin/push-upgrade-admin.component'
import { ForbiddenComponent } from './forbidden/forbidden.component';
import { PushUpgradeReleaseDetailComponent } from './push-upgrade-release-detail/push-upgrade-release-detail.component';
import { AdminReleaseJobListComponent } from './admin-release-job-list/admin-release-job-list.component'
import { AdminRegisteredOrgListComponent } from './admin-registered-org-list/admin-registered-org-list.component'
import { PushUpgradeWelcomeComponent } from './push-upgrade-welcome/push-upgrade-welcome.component';
import { AdminJobSchedulerListComponent } from './admin-job-scheduler-list/admin-job-scheduler-list.component';
import { AdminReleaseDetailsComponent } from './admin-release-details/admin-release-details.component';
import { AdminSmartScheduleComponent } from './admin-smart-schedule/admin-smart-schedule.component';
import { AdminSmartTagComponent } from './admin-smart-tag/admin-smart-tag.component'
import { AdminSettingsComponent } from './admin-settings/admin-settings.component'
import { UpgradeSummaryComponent } from './upgrade-summary/upgrade-summary.component';
import { AdminSettingsGuard } from './guards/admin-settings.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: "ui/welcome",
    pathMatch: 'full'
  },
  { path: 'ui/welcome', component: PushUpgradeWelcomeComponent },
  { path: 'ui/pagenotfound', component: PageNotFoundComponent },
  { path: 'ui/forbidden', component: ForbiddenComponent },
  { path: 'ui/login/:type', component: LoginComponent },
  { path: 'ui/admin/login', component: PushUpgradeAdminComponent },
  // { path: 'ui/admin/upgrade-summary', component: UpgradeSummaryComponent },
  { path: 'ui/admin/job-list', component: AdminReleaseJobListComponent },
  { path: 'ui/admin/subscriber-list', component: AdminRegisteredOrgListComponent },
  { path: 'ui/admin/schedule-list', component: AdminJobSchedulerListComponent },
  { path: 'ui/admin/release-detail', component: AdminReleaseDetailsComponent },
  { path: 'ui/admin/smart-schedule', component: AdminSmartScheduleComponent },
  { path: 'ui/admin/smart-tag', component: AdminSmartTagComponent },
  { path: 'ui/admin/settings', component: AdminSettingsComponent, canActivate: [AdminSettingsGuard] },
  { path: 'ui/release-detail', component: PushUpgradeReleaseDetailComponent },
  { path: 'ui/consent-form/:id', component: PushUpgradeRegistrationComponent },
  { path: 'ui/consent-form', component: PushUpgradeRegistrationComponent },
  { path: 'ui', component: PushUpgradeRegistrationComponent },
  { path: 'callback', component: LoginComponent },
  { path: 'callback/:type', component: LoginComponent },
  { path: '**', component: PageNotFoundComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
