import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse, HttpEvent } from "@angular/common/http";
import { AuthService } from "./auth.service";
import { throwError, BehaviorSubject } from 'rxjs';
import { catchError, switchMap, filter, take } from 'rxjs/operators';

@Injectable()

export class AuthInterceptor implements HttpInterceptor {

  private isRefreshingToken = false;
  private refreshToken$ = new BehaviorSubject<any>(null);
  constructor(private authService: AuthService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const authToken = this.authService.getToken();
    req = req.clone({
      setHeaders: {
        Authorization: "Bearer " + authToken
      }
    });
    return next.handle(req).pipe(catchError((err) => {
      if (err.status === 401) {
        if (!this.isRefreshingToken) {
          this.refreshToken$.next(null);
          this.isRefreshingToken = true;

          return this.authService.refreshToken({
            accessToken: localStorage.getItem('access_token'),
            refreshToken: localStorage.getItem('refresh_token')
          }).pipe(
            switchMap(res => {
              this.isRefreshingToken = false;
              localStorage.setItem('refresh_token', res.refreshToken);
              localStorage.setItem('access_token', res.accessToken);
              this.refreshToken$.next(res.accessToken);

              return next.handle(req.clone({
                setHeaders: {
                  Authorization: "Bearer " + res.accessToken
                }
              }))
            }),
            catchError((err) => {
              this.isRefreshingToken = false;
              localStorage.clear();
              localStorage.setItem('redirectURLAfterReLogin', window.location.href);
              window.location.href = 'ui/admin/login';
              return throwError(err);
            })
          );
        } else {
          return this.refreshToken$.pipe(
            filter(token => token != null),
            take(1),
            switchMap(token => {
              return next.handle(req.clone({
                setHeaders: {
                  Authorization: "Bearer " + token
                }
              }))
            })
          )
        }
      }
      return throwError(err);
    }));
  }
}