import { Directive, ElementRef } from '@angular/core';
import { AuthService } from '../auth.service';

@Directive({
    selector: '[readOnlyField]'
})
export class ReadOnlyFieldDirective {

    constructor(
        private elementRef: ElementRef,
        private authService: AuthService,
    ) {
        this.readOnlyField();
    }

    readOnlyField() {
        if (this.authService.currentUser.UserType == 'ReadOnlyUser') {
            this.elementRef.nativeElement.disabled = true;
        }
    }
}