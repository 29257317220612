<app-opt-in-navigation-panel></app-opt-in-navigation-panel>

<div class="row justify-content-around slds-text-body_regular" *ngIf="displayPage">
    <div class="slds-brand-band slds-brand-band_medium"></div>

    <div class="col-md-5 rounded org-detail border-10">
        <p>This consent will be used for the sole purpose of authorising <span class="label-red">Conga</span> to upgrade given org with the latest conga packages and install dependant packages if they are not available in the Org.</p>

        <hr class="solid">
        <h2 style="text-align: left;">Organization Details</h2>

        <table class="table borderless">
            <tbody>
                <tr>
                    <td>Organization Name</td>
                    <td><input ngl type="text" disabled [(ngModel)]="org_details.organizationName"></td>
                </tr>
                <tr>
                    <td class="td-label">Organization ID</td>
                    <td><input ngl type="text" disabled [ngModel]="this.commonService.removeOrgIdCheksum(org_details.orgId)"></td>
                </tr>
                <tr>
                    <td>Organization Edition</td>
                    <td><input ngl type="text" disabled [(ngModel)]="org_details.organizationType"></td>
                </tr>
                <tr>
                    <td>Environment</td>
                    <td><input ngl type="text" disabled [(ngModel)]="org_details.isSandbox"></td>
                </tr>
                <tr>
                    <td>User Name</td>
                    <td><input ngl type="text" disabled [(ngModel)]="org_details.userName"></td>
                </tr>
                <tr>
                    <td>Email ID</td>
                    <td><input ngl type="text" disabled [(ngModel)]="org_details.emailId"></td>
                </tr>
            </tbody>
        </table>

        <hr class="solid">
        <div>
            <h2>Package Access</h2>
            <div class="slds-grid slds-grid_pull-padded slds-grid_vertical-align-center">
                <div class="slds-col_padded">

                    <table class="AccessCtrlTbl">
                        <tbody>
                            <tr>
                                <td>To set the accessibility for Conga packages which shall be upgraded using Push Upgrade system</td>
                                <td>
                                    <button class="accessCtrlBtn" type="button" nglButton variant="brand" (click)="openAccessCtrlModal()">Set Package Access</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <br>
        <hr class="solid">
        <div class="disclaimer">
            <h2>Notes</h2>
            <p>To know more about each release or product, kindly refer to <a href="https://documentation.conga.com">https://documentation.conga.com</a>
                <br>This package may send or release data from third-party websites. Make sure you trust these websites -
                <br> CSP Trusted Site
            </p>
            <ul>
                <li>https://content.analytics.apttus.com</li>
                <li>https://data.analytics.apttus.com</li>
                <li>Website - data.analytics.apttus.com(SSL Encrypted)</li>
            </ul>
        </div>

        <br>
        <hr class="solid">
        <div class="disclaimer" *ngIf="isEarlyFeedbackEnabled">
            <h2>Early Feedback Program</h2>
            <p>Now users have an option to get an early feedback by running the test in existing version of our service in the registered org.
                <br><br>Early feedback also allows Conga to enhance their "Golden Test Suite" (These are some of the Out-Of-Box API based tests) which will run once before and after the org. upgrade just to catch any unexpected functionality changes between
                the releases.
                <br><br>
            </p>
            <ng-container *ngIf="org_details.id; else registeredOrg">
                To start "Early Feedback" program and see how the Golden Test Suite runs in existing version <a href="JavaScript:void(0)" (click)="navigateToEarlyFeedbackDashboard(org_details.orgId)">click here</a>
            </ng-container>
            <ng-template #registeredOrg>
                This feature works only after org. registration. Please click on "Submit" button to register your org.
            </ng-template>

        </div>

        <br>
        <hr class="solid">
        <div class="disclaimer" *ngIf="org_details.isAdobePkgInstalled">
            <h2>Add-Ons</h2>
            <mat-checkbox class="chkbox-margin" color="primary" [(ngModel)]="org_details.enableExternalDependentPackageInstallation"> <span class="wrap-mat-radio-label">Allow Conga to upgrade the Adobe Sign package to recommended version as per <a href="https://documentation.conga.com">release notes</a></span></mat-checkbox>
        </div>

        <br>
        <div class="label-red" *ngIf="org_details.id">
            <p>** For the given org {{this.commonService.removeOrgIdCheksum(org_details.orgId)}} the Push Upgrade is set up by {{org_details.createdBy}} on {{this.authservice.dateFormatter(org_details.createdDate)}} </p>
        </div>
        <div>
            <div class="btn-group action-btn">
                <button [(disabled)]="this.disableSubmitBtn" type="button" nglButton variant="brand" (click)="saveUserPreference()">{{submitBtnLabel}}</button>
            </div>
        </div>

        <div *ngIf="loading">
            <div class="login_spinner">
                <ngl-spinner size="large" variant="brand" alternativeText="Loading"></ngl-spinner>
            </div>
        </div>
    </div>
</div>

<div *ngIf="!displayPage">
    <div class="login_spinner">
        <ngl-spinner size="large" variant="brand" alternativeText="Loading"></ngl-spinner>
    </div>
</div>

<div class="slds-notify_container">
    <ngl-toast iconName="utility:info" class="toast-info" *ngIf="showTopToast" [(variant)]="toastVariant" (close)="onClose($event); showTopToast = false;" duration="3000">
        <h3 class="slds-text-heading_small">{{toastMsg}}</h3>
    </ngl-toast>
</div>

<ngl-modal header="Early Feedback" [(open)]="earlyFeedbackPopUpOpen" (openChange)="onModalClose()" size="small" *ngIf="earlyFeedbackPopUpOpen && isEarlyFeedbackEnabled">
    <div class="slds-p-around_medium">
        Your Org is registered succesfully!
        <br> Do you wish to run the Early Feedback program with the existing version of Conga services in the registered Org ?
    </div>
    <div class="login_spinner" *ngIf="loading">
        <ngl-spinner size="large" variant="brand" alternativeText="Loading"></ngl-spinner>
    </div>
    <ng-template nglModalFooter>
        <button class="slds-button slds-button_brand" (click)="navigateToEarlyFeedbackDashboard(org_details.orgId)">Try Now</button>
        <button class="slds-button slds-button_brand" (click)="onModalClose()">Close</button>
    </ng-template>
</ngl-modal>

<ngl-modal header="Conga Package Access" [(open)]="accessCtrlPopUpOpen" (openChange)="onAccessCtrlModalClose()" size="medium" *ngIf="accessCtrlPopUpOpen">
    <div class="detail-data-container mat-elevation-z8">
        <table mat-table [dataSource]="packageAccessCtrlDataSource" class="pkgAccessCtrlModal">

            <!-- Pacakge Name Column -->
            <ng-container matColumnDef="packageName">
                <th mat-header-cell *matHeaderCellDef> Package Name </th>
                <td mat-cell *matCellDef="let element"> {{element.packageName}}
                    <ng-container *ngIf="!element.isInstalledPackage;">*</ng-container>
                    <br>
                    <ng-container *ngIf="element.inputHasError"><span class="profile-error-msg">*Please select user profile</span></ng-container>
                </td>
            </ng-container>

            <!-- Install For Admin Only Column-->
            <ng-container matColumnDef="installForAdminOnly">
                <th mat-header-cell *matHeaderCellDef>
                    <div class="slds-col_padded">
                        <ngl-checkbox-toggle label="Install For Admin Only" (click)="onSelectAll($event,'InstallForAdminOnly')" enabledText="All Selected" disabledText="Select All">
                            <input ngl type="checkbox" [checked]="isHeadInstallForAdmin">
                        </ngl-checkbox-toggle>
                    </div>
                </th>
                <td mat-cell *matCellDef="let element">
                    <input type="radio" name="{{element.packageId}}" value="InstallForAdminOnly" (change)="onItemChange($event,element)" [checked]="'InstallForAdminOnly' == element.accessibility"></td>
            </ng-container>

            <!-- Install For All Users Column -->
            <ng-container matColumnDef="installForAllUsers">
                <th mat-header-cell *matHeaderCellDef>
                    <ngl-checkbox-toggle label="Install For All Users" (click)="onSelectAll($event,'InstallForAllUsers')" enabledText="All Selected" disabledText="Select All">
                        <input ngl type="checkbox" [checked]="isHeadInstallForAllUsers">
                    </ngl-checkbox-toggle>
                </th>
                <td mat-cell *matCellDef="let element">
                    <input type="radio" name="{{element.packageId}}" value="InstallForAllUsers" (change)="onItemChange($event,element)" [checked]="'InstallForAllUsers' == element.accessibility"></td>
            </ng-container>

            <!-- Install For Specific Profiles Column -->
            <ng-container *ngIf="installForCustomProfileFeatureEnabled" matColumnDef="installForSpecificProfiles">
                <th mat-header-cell *matHeaderCellDef>
                    <div [nglTooltip]="interactive" nglTooltipPlacement='left' nglTooltipInteractive="true" nglTooltipDelay="100" nglTooltipOpenAuto>
                        <ngl-checkbox-toggle label="Install For Specific Profiles" (click)="onSelectAll($event,'InstallForCustomProfiles')" enabledText="All Selected" disabledText="Select All">
                            <input ngl type="checkbox" [checked]="isHeadInstallForCustomProfiles">
                        </ngl-checkbox-toggle>
                        <ng-template #interactive>Standard profiles (including the Read-Only profile) don't receive access to any installed custom objects. Because permissions are not editable for standard profiles, you must clone your profile to grant access. These security settings
                            determine access to the custom objects and components installed in the package. It doesn't affect permissions for existing objects.</ng-template>
                    </div>
                </th>

                <td mat-cell *matCellDef="let element">
                    <div>
                        <input type="radio" name="{{element.packageId}}" value="InstallForCustomProfiles" (change)="onItemChange($event,element)" [checked]="'InstallForCustomProfiles' == element.accessibility">
                    </div>

                    <div>
                        <ngl-combobox name="{{element.packageId}}" label="Which profiles do you prefer to give full access?" [options]="this.user_Profile" [(open)]="this['open_' + element.packageId]" multiple [(selection)]="this['selectedProfile' + element.packageId]" (selectionChange)="onSelectionChange($event,element)">
                            <input nglCombobox placeholder="Select User Profiles" [(disabled)]="this['disableProfileSel_' + element.packageId]">
                        </ngl-combobox>

                        <div class="slds-m-top_xx-small profile-pill-div " *ngIf="this['selectedProfile' + element.packageId]">
                            <ng-container *ngFor="let profileId of this['selectedProfile' + element.packageId]">
                                <div *ngIf="this.sysAdminId === profileId ; else nonSysAdmin">
                                    <div [nglTooltip]="interactive" nglTooltipPlacement='left' nglTooltipInteractive="true" nglTooltipDelay="100" nglTooltipOpenAuto>
                                        <ngl-pill class="sysAdmin-profile-pill" [avatar]="userProfileImg">{{this.getProfileNameById(profileId)}}</ngl-pill>
                                        <ng-template #interactive>This profile will always have full access.</ng-template>
                                    </div>
                                </div>

                                <ng-template #nonSysAdmin>
                                    <ngl-pill class="profile-pill" [avatar]="userProfileImg" (remove)="remove(profileId, element.packageId)">{{this.getProfileNameById(profileId)}}</ngl-pill>
                                </ng-template>
                            </ng-container>
                        </div>
                    </div>
                </td>

            </ng-container>


            <tr mat-header-row *matHeaderRowDef="packageDisplayedColumns; sticky: true"></tr>
            <tr class="package-element-row" mat-row *matRowDef="let row; columns: packageDisplayedColumns;"></tr>

            <!-- Row shown when there is no matching data. -->
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="3" style="text-align: center;">No Packages found</td>
            </tr>
        </table>
    </div>
    <div class="login_spinner" *ngIf="loading">
        <ngl-spinner size="large" variant="brand" alternativeText="Loading"></ngl-spinner>
    </div>
    <ng-template nglModalFooter>
        <div *ngIf="displayDependentPkgFootnotes" class="footerLeft">Packages with (*) are dependent packages which needs to be installed to ensure the core features to work as expected</div>

        <button class="slds-button slds-button_brand" (click)="savePackageAccessControlInfo(org_details.orgId)">Save</button>
        <button class="slds-button slds-button_brand" (click)="onAccessCtrlModalClose()">Close</button>
    </ng-template>
</ngl-modal>
