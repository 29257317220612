import { Component, OnInit } from '@angular/core';
import { NglComboboxOptionItem } from 'ng-lightning/lib/comboboxes/combobox';
import { forkJoin } from 'rxjs';
import { BackgroundServiceSetting, SessionSetting, SmartScheduleSetting } from 'src/app/interface/pu-admin';
import { ReleaseConfig } from 'src/app/interface/release-config';
import { AuthService } from 'src/app/shared/auth.service';
import { CommonService } from 'src/app/shared/common.service';
import { HttpService } from 'src/app/shared/http.service';

@Component({
  selector: 'app-common-settings',
  templateUrl: './common-settings.component.html',
  styleUrls: ['./common-settings.component.css']
})
export class CommonSettingsComponent {
  isLoading: boolean = false;
  isSendEmailForOrgLifecycle: boolean = false;
  isSiteUnderMaintenance: boolean = false;
  smartScheduleSettingInfo: SmartScheduleSetting = {
    maxOrgAllowedToSchedule: 0,
    timeInDaysBeforeFinalzeSchedule: 0,
    timeInDaysToSchedule: 0,
    timeInDaysToSendRemainderNotification: 0,
    isGAAutoUpgradeEnabledForSandbox: false,
    isGAAutoUpgradeEnabledForProduction: false,
    finaliseAutoUpgradeScheduleCreation: false,
    timeDiffInHoursBetweenAutoBatchCreation: 0,
    timeInDaysToScheduleAutoUpgradeForSandbox: 0,
    timeInDaysToScheduleAutoUpgradeForProduction: 0,
    autoUpgradeDateForSandbox: new Date(),
    autoUpgradeDateForProduction: new Date()
  };
  backgroundServiceSettings: BackgroundServiceSetting = {
    chekPatchReleaseTaskIntervalInMinutes: 0,
    refreshMetadataForLatestPatchesTaskIntervalInMinutes: 0,
    smartScheduleEmailReminderTaskIntervalInMinutes: 0,
    orgLifeCycleManagementTaskIntervalInMinutes: 0,
    packageVersionMetadataRefreshTaskIntervalInMinutes: 0
  }

  sessionSettings: SessionSetting = {
    timeToExpireAccessTokenInMinutes: 1,
    timeToExpireRefreshTokenInHours: 1
  }
  confirmMaintenance: boolean = false;
  dataChanges = [];
  cleaveOptions = {
    date: true,
    delimiter: '/',
    datePattern: ['d', 'MMM', 'Y']
  };

  scheduleDateSB: Date;
  minScheduleDateSB: Date;
  scheduleTimeSelectionSB: string = null;
  scheduleDateHourSelectionSB: string = null;
  scheduleDateMinutesSelectionSB: string = null;
  scheduleTimeOptionsSB: NglComboboxOptionItem[];
  scheduleTimeOpenSB: true;

  scheduleDateProd: Date;
  minScheduleDateProd: Date;
  scheduleTimeSelectionProd: string = null;
  scheduleDateHourSelectionProd: string = null;
  scheduleDateMinutesSelectionProd: string = null;
  scheduleTimeOptionsProd: NglComboboxOptionItem[];
  scheduleTimeOpenProd: true;

  releaseVersionOpen: boolean = false;
  minSupportedReleaseVersionForPatch: string = "";
  originalData!: any;
  allReleaseVersionOptions!: Array<{ label: string, value: string }>;

  constructor(public authService: AuthService, private httpService: HttpService, private commonService: CommonService) { }

  ngOnInit(): void {

    this.scheduleDateSB = new Date();
    this.minScheduleDateSB = new Date();
    this.scheduleDateProd = new Date();
    this.minScheduleDateProd = new Date();

    // Check if access token is present else take the user to admin login page.
    if (this.authService.isLoggedIn) {
      this.getCommonSettingsStatus();
    }
    else {
      this.authService.redirectToAdminLogin();
    }
  }

  getCommonSettingsStatus() {
    this.isLoading = true;
    forkJoin({
      isSiteUnderMaintenance: this.authService.GetSiteMaintenanceStatus(),
      isSendEmailForOrgLifecycle: this.authService.GetSendEmailForOrgLifeCycle(),
      smartScheduleSettingInfo: this.authService.GetSmartScheduleSettingInfo(),
      minSupportedReleaseVersionForPatch: this.authService.GetMinSupportedReleaseVersionForPatch(),
      backgroundServiceSettings: this.authService.getGetBackgroundServiceSettings(),
      allReleaseInfo: this.authService.getAllReleaseInfo(false),
      sessionSettings: this.authService.getSessionSettings()
    }).subscribe((res) => {
      this.isLoading = false;
      const { isSiteUnderMaintenance, isSendEmailForOrgLifecycle, smartScheduleSettingInfo, minSupportedReleaseVersionForPatch, backgroundServiceSettings, allReleaseInfo, sessionSettings } = res;

      this.originalData = { ...res };

      this.isSiteUnderMaintenance = isSiteUnderMaintenance ?? false;
      this.allReleaseVersionOptions = allReleaseInfo && Array.isArray(allReleaseInfo) && allReleaseInfo.filter(item => !item.patchNo).map(item => {
        return { label: item?.displayLabel, value: item?.releaseVersionString }
      }) || [];
      if (backgroundServiceSettings) {
        this.backgroundServiceSettings = { ...this.backgroundServiceSettings, ...backgroundServiceSettings };
      }
      this.isSendEmailForOrgLifecycle = isSendEmailForOrgLifecycle?.allowSendEmail ?? false;
      if (smartScheduleSettingInfo) {
        this.smartScheduleSettingInfo = { ...this.smartScheduleSettingInfo, ...smartScheduleSettingInfo };

        var todayDate = new Date();
        this.minScheduleDateSB = this.getDate(todayDate, this.smartScheduleSettingInfo.timeInDaysToScheduleAutoUpgradeForSandbox);
        this.minScheduleDateProd = this.getDate(todayDate, this.smartScheduleSettingInfo.timeInDaysToScheduleAutoUpgradeForProduction);

        this.prepareScheduleTimeOptions(new Date(this.smartScheduleSettingInfo.autoUpgradeDateForSandbox));
        this.prepareScheduleTimeOptions(new Date(this.smartScheduleSettingInfo.autoUpgradeDateForProduction), false);

        this.scheduleDateSB = this.getDate(new Date(this.smartScheduleSettingInfo.autoUpgradeDateForSandbox));
        var timeSelection = this.getDate(new Date(this.smartScheduleSettingInfo.autoUpgradeDateForSandbox)).toTimeString().split(" ")[0].slice(0, -3);
        timeSelection = timeSelection.split(":")[0].length === 1 ? 0 + timeSelection : timeSelection;
        this.scheduleTimeSelectionSB = timeSelection;

        this.scheduleDateProd = this.getDate(new Date(this.smartScheduleSettingInfo.autoUpgradeDateForProduction));
        timeSelection = this.getDate(new Date(this.smartScheduleSettingInfo.autoUpgradeDateForProduction)).toTimeString().split(" ")[0].slice(0, -3);
        timeSelection = timeSelection.split(":")[0].length === 1 ? 0 + timeSelection : timeSelection;
        this.scheduleTimeSelectionProd = timeSelection;


      }
      if (minSupportedReleaseVersionForPatch && this.allReleaseVersionOptions.length) {
        this.minSupportedReleaseVersionForPatch = this.allReleaseVersionOptions.find(item => item?.value == minSupportedReleaseVersionForPatch)?.value ?? "";
      }
      if (sessionSettings) {
        this.sessionSettings = { ...this.sessionSettings, ...sessionSettings };
      }
    })
  }

  scheduleDateChange(selectedDate: Date, isSandbox: boolean = true) {
    this.setScheduleDateTimeFor(selectedDate, isSandbox);
    this.prepareScheduleTimeOptions(selectedDate, isSandbox);
  }

  prepareScheduleTimeOptions(inputDate: Date, isSandbox: boolean = true) {

    if (isSandbox)
      this.scheduleTimeOptionsSB = [];
    else
      this.scheduleTimeOptionsProd = [];

    let hrs = 0;
    var todayDate = new Date();
    if (todayDate.getDate() == inputDate.getDate() &&
      todayDate.getMonth() == inputDate.getMonth()) {
      hrs = todayDate.getHours() + 2;
    }

    for (; hrs < 24; hrs++) {
      var paddedHrs = (hrs < 10 ? "0" : "") + hrs.toString();

      for (let mins = 0; mins < 46; mins = mins + 15) {
        var paddedMins = (mins < 10 ? "0" : "") + mins.toString();

        let item: NglComboboxOptionItem = {
          value: paddedHrs + ":" + paddedMins,
          label: paddedHrs + ":" + paddedMins
        };

        if (isSandbox)
          this.scheduleTimeOptionsSB.push(item);
        else
          this.scheduleTimeOptionsProd.push(item);

      }
    }
  }

  scheduleTimeChange(selectedItem: string, isSandbox: boolean = true) {
    var hrs = selectedItem.substring(0, selectedItem.indexOf(":"));
    var mins = selectedItem.replace(hrs + ":", "");
    if (isSandbox) {
      this.scheduleDateHourSelectionSB = hrs;
      this.scheduleDateMinutesSelectionSB = mins;
      this.setScheduleDateTimeFor(this.scheduleDateSB, isSandbox);
    }
    else {
      this.scheduleDateHourSelectionProd = hrs;
      this.scheduleDateMinutesSelectionProd = mins;
      this.setScheduleDateTimeFor(this.scheduleDateProd, isSandbox);
    }

    
  }

  setScheduleDateTimeFor(inputDate: Date, isSandbox: boolean = true) {
    if (isSandbox) {
      var scheduleHours: number = +this.scheduleDateHourSelectionSB;
      var scheduleMins: number = +this.scheduleDateMinutesSelectionSB;
      this.scheduleDateSB = new Date(inputDate.getFullYear(), inputDate.getMonth(), inputDate.getDate(), scheduleHours, scheduleMins, 0);
      this.smartScheduleSettingInfo.autoUpgradeDateForSandbox = this.scheduleDateSB;
    }
    else {
      var scheduleHours: number = +this.scheduleDateHourSelectionProd;
      var scheduleMins: number = +this.scheduleDateMinutesSelectionProd;
      this.scheduleDateProd = new Date(inputDate.getFullYear(), inputDate.getMonth(), inputDate.getDate(), scheduleHours, scheduleMins, 0);
      this.smartScheduleSettingInfo.autoUpgradeDateForProduction = this.scheduleDateProd;
    }
  }


  getDate(fromDate: Date, addDays: number = 0): Date {
    var date = new Date(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate(), fromDate.getHours(), fromDate.getMinutes());
    if (addDays > 0) {
      date.setDate(date.getDate() + addDays);
    }
    return date;
  }


  updateSettings() {

    if (this.isSiteUnderMaintenance != this.originalData?.isSiteUnderMaintenance) {
      this.dataChanges.push(this.authService.updateSiteMaintenanceStatus({ IsSiteUnderMaintenanceFlag: this.isSiteUnderMaintenance }))
    }
    if (this.isSendEmailForOrgLifecycle != this.originalData?.isSendEmailForOrgLifecycle.allowSendEmail) {
      this.dataChanges.push(this.authService.SetSendEmailForOrgLifeCycle(this.isSendEmailForOrgLifecycle))
    }
    if (this.minSupportedReleaseVersionForPatch != this.originalData?.minSupportedReleaseVersionForPatch) {
      this.dataChanges.push(this.authService.SetMinSupportedReleaseVersionForPatch(this.minSupportedReleaseVersionForPatch))
    }
    if (
      JSON.stringify(this.smartScheduleSettingInfo) != JSON.stringify(this.originalData?.smartScheduleSettingInfo)
    ) {
      this.dataChanges.push(this.authService.UpdateSmartScheduleSettingInfo(this.smartScheduleSettingInfo))
    }
    if (JSON.stringify(this.backgroundServiceSettings) != JSON.stringify(this.originalData?.backgroundServiceSettings)) {
      this.dataChanges.push(this.authService.updateBackgroudServiceSettings(this.backgroundServiceSettings))
    }
    if (JSON.stringify(this.sessionSettings) != JSON.stringify(this.originalData?.sessionSettings)) {
      this.dataChanges.push(this.authService.updateSessionSettings(this.sessionSettings))
    }
    if (!this.authService.isEmpty(this.dataChanges)) {
      if (this.isSiteUnderMaintenance) {
        this.confirmMaintenance = true;
        return;
      }
      this.isLoading = true;
    }
    this.onUpdate();
  }

  onUpdate() {
    forkJoin(this.dataChanges).subscribe(() => {
      this.isLoading = false;
      this.originalData = JSON.parse(JSON.stringify({
        isSendEmailForOrgLifecycle: { "allowSendEmail": this.isSendEmailForOrgLifecycle },
        minSupportedReleaseVersionForPatch: this.minSupportedReleaseVersionForPatch,
        smartScheduleSettingInfo: this.smartScheduleSettingInfo,
        backgroundServiceSettings: this.backgroundServiceSettings,
        isSiteUnderMaintenance: this.isSiteUnderMaintenance,
      }))
      this.commonService.maintenanceWarning$.next(this.isSiteUnderMaintenance);
      this.dataChanges = [];
      this.httpService.apiSuccess$.next({ hasSuccess: true, msg: 'System Setting is updated successfully' })
    }, () => {
      this.isLoading = false;
    });
  }

  onConfirmMaintenance() {
    this.confirmMaintenance = false;
    this.onUpdate();
  }

}
