export class TextMessage {
  static selectReleaseName: string = "Please select the Release";
  static upgradeInitated: string = "Initiated Push Upgrade for ${0} Org(s)";
  static enterUserName: string = "Please Enter Username";
  static enterPassword: string = "Please Enter Password";
  static loggedIn: string = "Logged In!";
  static invalidCredentials: string = "Invalid UserName/Password!!!";
  static errorMsg: string = "Error!!!";
  static forbiddenErrorCode: string = "403";
  static forbiddenErrorMsg:string = "Insufficient Privileges  - You do not have the required access to perform the Operation. Please contact Conga Push Upgrade team or your Sys. Administrator";
}
