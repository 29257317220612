<app-admin-navigation-panel></app-admin-navigation-panel>

<!-- Admin Dashboard to view Customer preference settings and trigger Push Upgrade -->
<div class="main"><br><br>

    <button class="slds-button slds-icon_container slds-icon-utility-info btn-download-job-list"
        [nglTooltip]="'Export the Job Details to Excel'" nglTooltipInteractive="true" nglTooltipDelay="100"
        nglTooltipOpenAuto (click)="exportJobListReport()">
        <svg class="slds-button__icon" aria-hidden="true">
            <use xlink:href="/assets/icons/action-sprite/svg/symbols.svg#download">
            </use>
        </svg>
    </button>
    <ngl-tabset [(selected)]='selectedTab' variant='scoped'>
        <ng-template ngl-tab label="Sandbox" (activate)="tabChange('sandbox')">
            <div class="slds-page-header slds-page-header_record-home">
                <div class="slds-page-header__row">
                    <div class="slds-page-header__col-title">
                        <div class="slds-media">
                            <div class="slds-media__figure">
                                <span class="slds-icon_container slds-icon-standard-opportunity">
                                    <svg class="slds-icon slds-page-header__icon" aria-hidden="true">
                                        <use xlink:href="/assets/icons/standard-sprite/svg/symbols.svg#opportunity">
                                        </use>
                                    </svg>
                                </span>
                            </div>
                            <div class="slds-media__body">
                                <div class="slds-page-header__name">
                                    <div class="slds-page-header__name-title">
                                        <h1>
                                            <span class="slds-page-header__title slds-truncate"
                                                title="Sandbox - Push Upgrade Job Details">Sandbox - Push Upgrade Job
                                                Details</span>
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="slds-page-header__col-actions">
                        <div class="slds-page-header__controls">
                            <div class="slds-page-header__control">
                                <button type="button" nglButton variant="brand" (click)="fetchPushUpgradeJoblist()"
                                    aria-live="assertive">
                                    <span class="slds-text-not-selected">Refresh</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template #heading>Production</ng-template>
        <ng-template id="production" ngl-tab #summaryTab="nglTab" [label]="heading"
            (activate)="tabChange('production')">
            <div class="slds-page-header slds-page-header_record-home">
                <div class="slds-page-header__row">
                    <div class="slds-page-header__col-title">
                        <div class="slds-media">
                            <div class="slds-media__figure">
                                <span class="slds-icon_container slds-icon-standard-opportunity">
                                    <svg class="slds-icon slds-page-header__icon" aria-hidden="true">
                                        <use xlink:href="/assets/icons/standard-sprite/svg/symbols.svg#opportunity">
                                        </use>
                                    </svg>
                                </span>
                            </div>
                            <div class="slds-media__body">
                                <div class="slds-page-header__name">
                                    <div class="slds-page-header__name-title">
                                        <h1>
                                            <span class="slds-page-header__title slds-truncate"
                                                title="Production - Push Upgrade Job Details">Production - Push Upgrade
                                                Job Details</span>
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="slds-page-header__col-actions">
                        <div class="slds-page-header__controls">
                            <div class="slds-page-header__control">
                                <button type="button" nglButton variant="brand" (click)="fetchPushUpgradeJoblist()"
                                    aria-live="assertive">
                                    <span class="slds-text-not-selected">Refresh</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </ngl-tabset>

    <br>

    <div class="slds-page-header">
        <div class="data-body">
            <div class="slds-page-header">
                <mat-form-field>
                    <mat-label>Filter</mat-label>
                    <input [(ngModel)]="this.filterValue" matInput (keyup)="applyFilter($event)"
                        placeholder="Search Text" #input>
                </mat-form-field>
                <div class="data-container mat-elevation-z8">
                    <table #TableOneSort="matSort" mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

                        <!-- Job ID Column -->
                        <ng-container matColumnDef="jobId">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Job Id </th>
                            <td mat-cell *matCellDef="let element"> {{element.jobId}} </td>
                        </ng-container>

                        <!-- Org ID Column -->
                        <ng-container matColumnDef="orgId">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Organization Id </th>
                            <td mat-cell *matCellDef="let element">
                                {{this.commonService.removeOrgIdCheksum(element.orgId)}} </td>
                        </ng-container>

                        <!-- Org Name Column -->
                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Organization Name </th>
                            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                        </ng-container>

                        <!-- Job Start Date Column -->
                        <ng-container matColumnDef="jobStartDate">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Job Start Date<br>({{timeZone}})</th>
                            <td mat-cell *matCellDef="let element"> {{element.jobStartDateStr}} </td>
                        </ng-container>

                        <!-- Job End Date Column -->
                        <ng-container matColumnDef="jobDuration">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Job Duration </th>
                            <td mat-cell *matCellDef="let element"> {{element.jobDurationInString}}</td>
                        </ng-container>

                        <!-- Release Name Column -->
                        <ng-container matColumnDef="releaseDisplayName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Release </th>
                            <td mat-cell *matCellDef="let element"> {{element.releaseDisplayName}} </td>
                        </ng-container>

                        <!-- Status Column -->
                        <ng-container matColumnDef="status">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Upgrade Job Status </th>
                            <td mat-cell *matCellDef="let element">
                                <ng-container
                                    *ngIf="this.isJobStatusComplete(element.status,element.summary); else withoutSummary">
                                    <span [nglTooltip]="interactive" nglTooltipInteractive="true" nglTooltipDelay="100"
                                        nglTooltipOpenAuto>
                                        {{element.status}}
                                        <ng-template #interactive>{{element.summary}}</ng-template>
                                    </span>
                                </ng-container>
                                <ng-template #withoutSummary> {{element.status}}</ng-template>

                            </td>
                        </ng-container>

                        <!-- Action Column -->
                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef> Actions </th>
                            <td mat-cell *matCellDef="let element">
                                <button class="slds-button slds-icon_container slds-icon-utility-info"
                                    [nglTooltip]="'Show More Details'" nglTooltipInteractive="true"
                                    nglTooltipDelay="100" nglTooltipOpenAuto (click)="openDialog(element)">
                                    <svg class="slds-button__icon" aria-hidden="true">
                                        <use xlink:href="/assets/icons/action-sprite/svg/symbols.svg#more">
                                        </use>
                                    </svg>
                                </button>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                        <tr class="org-element-row" mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                        <!-- Row shown when there is no matching data. -->
                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" colspan="8" style="text-align: center;">No results found</td>
                        </tr>
                    </table>
                </div>
                <mat-paginator [length]="length" [pageIndex]="pageIndex" [pageSize]="pageSize"
                    [pageSizeOptions]="pageSizeOptions" [showFirstLastButtons]="true" (page)="handlePageEvent($event)">
                </mat-paginator>
            </div>
        </div>

    </div>

    <!-- Modal Popup for the Package Details View -->
    <ngl-modal header="Push Upgrade Details" [(open)]="open" (openChange)="onModalClose()" size="large" *ngIf="open">
        <div class="slds-p-around_medium">
            <div>
                <table class="table borderless">
                    <tbody>
                        <tr>
                            <td>Organization ID</td>
                            <td><input ngl type="text" disabled
                                    [ngModel]="this.commonService.removeOrgIdCheksum(jobDetails.orgId)"></td>
                            <td>Organization Name</td>
                            <td><input ngl type="text" disabled [(ngModel)]="jobDetails.orgName"></td>
                        </tr>
                        <tr>
                            <td>Job ID</td>
                            <td><input ngl type="text" disabled [(ngModel)]="jobDetails.jobId"></td>
                            <td>Push Upgrade Status</td>
                            <td><input ngl type="text" disabled [(ngModel)]="jobDetails.jobStatus"></td>
                        </tr>
                        <tr>
                            <td>Consent Provided By</td>
                            <td><input ngl type="text" disabled [ngModel]="jobDetails.consentProviderUserName"></td>
                            <td>Consent Creation Date</td>
                            <td><input ngl type="text" disabled
                                    [ngModel]="this.authservice.dateFormatter(jobDetails.consentProvidedDate)"></td>
                        </tr>
                        <tr>
                            <td>Push Upgrade Start Date</td>
                            <td><input ngl type="text" disabled
                                    [ngModel]="this.authservice.dateFormatter(jobDetails.startDate)"></td>
                            <td>Push Upgrade End Date</td>
                            <td><input ngl type="text" disabled
                                    [ngModel]="this.authservice.dateFormatter(jobDetails.endDate)"></td>
                        </tr>
                        <tr>
                            <td>Job Created By</td>
                            <td><input ngl type="text" disabled [(ngModel)]="jobDetails.createdBy"></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
                <br>

                <h3 *ngIf="packageDataSource.length > 0">Package Details</h3>
                <div *ngIf="packageDataSource.length > 0" class="detail-data-container mat-elevation-z8">
                    <table mat-table [dataSource]="packageDataSource" class="mat-elevation-z8">

                        <!-- Index Column -->
                        <ng-container matColumnDef="index">
                            <th mat-header-cell *matHeaderCellDef> Order </th>
                            <td mat-cell *matCellDef="let element"> {{element.index}} </td>
                        </ng-container>

                        <!-- Pacakge Name Column -->
                        <ng-container matColumnDef="packageName">
                            <th mat-header-cell *matHeaderCellDef> Package Name </th>
                            <td mat-cell *matCellDef="let element"> {{element.packageName}} </td>
                        </ng-container>

                        <!-- Product Name Column -->
                        <ng-container matColumnDef="productName">
                            <th mat-header-cell *matHeaderCellDef> Product Name </th>
                            <td mat-cell *matCellDef="let element"> {{element.productName}} </td>
                        </ng-container>

                        <!-- Existing Package Version Column -->
                        <ng-container matColumnDef="previousPackageVersion">
                            <th mat-header-cell *matHeaderCellDef> Pre-Upgrade<br>Package Version </th>
                            <td mat-cell *matCellDef="let element"> {{element.previousPackageVersion}} </td>
                        </ng-container>

                        <!-- Package Version Column -->
                        <ng-container matColumnDef="packageVersion">
                            <th mat-header-cell *matHeaderCellDef> Post-Upgrade<br>Package Version </th>
                            <td mat-cell *matCellDef="let element">
                                <ng-container *ngIf="this.authservice.isEmpty(element.documentationUrl); else withLink">
                                    {{element.packageVersion}}
                                </ng-container>
                                <ng-template #withLink>
                                    <a href="{{this.commonService.getURLFromTag(element.documentationUrl)}}" target="_blank"><span
                                            title="{{this.commonService.getURLFromTag(element.documentationUrl)}}">{{element.packageVersion}}</span></a>
                                </ng-template>
                            </td>
                        </ng-container>

                        <!-- Package Install Task Duration Column -->
                        <ng-container matColumnDef="taskDuration">
                            <th mat-header-cell *matHeaderCellDef> Upgrade Duration </th>
                            <td mat-cell *matCellDef="let element"> {{element.taskDurationInString}} </td>
                        </ng-container>


                        <ng-container matColumnDef="sfdcRequestStartTime">
                            <th mat-header-cell *matHeaderCellDef> SFDC Request Sent<br>({{
                                this.authservice.getTimeZone() }})</th>
                            <td mat-cell *matCellDef="let element"> {{
                                this.authservice.dateFormatter(element.sfdcRequestStartTime, false) }} </td>
                        </ng-container>

                        <ng-container matColumnDef="sfdcRequestEndTime">
                            <th mat-header-cell *matHeaderCellDef> SFDC Response Receieved<br>({{
                                this.authservice.getTimeZone() }})</th>
                            <td mat-cell *matCellDef="let element"> {{
                                this.authservice.dateFormatter(element.sfdcRequestEndTime, false) }} </td>
                        </ng-container>

                        <!-- Package Status Column -->
                        <ng-container matColumnDef="packageStatus">
                            <th mat-header-cell *matHeaderCellDef> Status </th>
                            <td mat-cell *matCellDef="let element"> {{element.packageStatusDisplayText}} </td>
                        </ng-container>

                        <!-- Comments Column -->
                        <ng-container matColumnDef="comments">
                            <th mat-header-cell *matHeaderCellDef> Comments </th>
                            <td mat-cell *matCellDef="let element"> {{element.comments}} </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="packageDisplayedColumns; sticky: true"></tr>
                        <tr class="package-element-row" mat-row *matRowDef="let row; columns: packageDisplayedColumns;">
                        </tr>
                    </table>
                </div>
                <br>

                <h3 *ngIf="sanityDataSource.length > 0">Conga Automation Framework Validation Results</h3>
                <div *ngIf="sanityDataSource.length > 0" class="detail-data-container mat-elevation-z8">
                    <table mat-table [dataSource]="sanityDataSource" class="mat-elevation-z8">

                        <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->

                        <!-- SubTaskType Column -->
                        <ng-container matColumnDef="subTaskType">
                            <th mat-header-cell *matHeaderCellDef> Name </th>
                            <td mat-cell *matCellDef="let element"> {{element.subTaskType}} </td>
                        </ng-container>

                        <!-- UpgradeValidation Status Column -->
                        <ng-container matColumnDef="packageStatus">
                            <th mat-header-cell *matHeaderCellDef> Status </th>
                            <td mat-cell *matCellDef="let element"> {{element.packageStatus}} </td>
                        </ng-container>

                        <!-- Comments Column -->
                        <ng-container matColumnDef="comments">
                            <th mat-header-cell *matHeaderCellDef> Notes </th>
                            <td mat-cell *matCellDef="let element">
                                <ng-template [ngIf]="element.comments"> {{element.comments}}
                                    <ng-template [ngIf]="showRetryOption(element)">
                                        <a href="javascript:void(0)" (click)="retryPostUpgradeValidation()">Retry</a>
                                    </ng-template>
                                </ng-template>
                            </td>
                        </ng-container>

                        <!-- UpgradeValidation Task Duration Column -->
                        <ng-container matColumnDef="taskDuration">
                            <th mat-header-cell *matHeaderCellDef> Duration </th>
                            <td mat-cell *matCellDef="let element"> {{element.taskDurationInString}} </td>
                        </ng-container>

                        <!-- ResultUrl Column -->
                        <ng-container matColumnDef="resultUrl">
                            <th mat-header-cell *matHeaderCellDef> Results </th>
                            <td mat-cell *matCellDef="let element">
                                <ng-template [ngIf]="element.resultUrl"><a href="{{element.resultUrl}}"
                                        target="_blank">click here</a> for results</ng-template>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="sanityDisplayedColumns; sticky: true"></tr>
                        <tr class="package-element-row" mat-row *matRowDef="let row; columns: sanityDisplayedColumns;">
                        </tr>
                    </table>
                </div>
            </div>
        </div>
        <ng-template nglModalFooter>
            <button class="slds-button slds-button_brand" (click)="refresh()">Refresh</button>
            <ng-container *ngIf="this.disableResendJobStatusButton(); else activeBtn">
                <div class="btnDiv" [nglTooltip]="interactive" nglTooltipInteractive="true" nglTooltipDelay="100"
                    nglTooltipOpenAuto>
                    <button *readOnlyBtn class="slds-button slds-button_brand" (click)="triggerJobRubStatusReport()"
                        [disabled]="true">Resend Job Run Status Report</button>
                    <ng-template #interactive>Job is {{jobDetails.jobStatus}}</ng-template>
                </div>
            </ng-container>
            <ng-template #activeBtn> <button *readOnlyBtn class="slds-button slds-button_brand"
                    (click)="triggerJobRubStatusReport()">Resend Job Run Status Report</button></ng-template>
        </ng-template>
    </ngl-modal>

    <!-- Loading Icon while making backend call -->
    <div class="loading_spinner" *ngIf="isLoading">
        <ngl-spinner size="large" variant="brand" alternativeText="Loading"></ngl-spinner>
    </div>
</div>

<div class="slds-notify_container">
    <ngl-toast iconName="utility:info" class="toast-info" *ngIf="showTopToast" [(variant)]="toastVariant"
        (close)="onClose($event); showTopToast = false;" duration="2000">
        <h3 class="slds-text-heading_small">{{toastMsg}}</h3>
    </ngl-toast>
</div>