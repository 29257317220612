<h2 mat-dialog-title>Push Upgrade Details</h2>
<mat-dialog-content class="mat-typography">

  <div>
    <table class="table borderless">
      <tbody>
        <tr>
          <td>Organization ID</td>
          <td><input type="text" class="form-control" disabled [(ngModel)]="jobDetails.orgId"></td>
          <td>Organization Name</td>
          <td><input type="text" class="form-control" disabled [(ngModel)]="jobDetails.orgName"></td>
        </tr>
        <tr>
          <td>Request Creation Date</td>
          <td><input type="text" class="form-control" disabled [(ngModel)]="jobDetails.createdDate"></td>
          <td>Instance ID</td>
          <td><input type="text" class="form-control" disabled [(ngModel)]="jobDetails.instanceId"></td>
        </tr>
        <tr>
          <td>Modified By</td>
          <td><input type="text" class="form-control" disabled [(ngModel)]="jobDetails.modifiedBy"></td>
          <td>Requestor's Email ID</td>
          <td><input type="text" class="form-control" disabled [(ngModel)]="jobDetails.createdBy"></td>
        </tr>
        <tr>
          <td>Modified Date</td>
          <td><input type="text" class="form-control" disabled [(ngModel)]="jobDetails.modifiedDate"></td>
          <td>Push Upgrade Status</td>
          <td><input type="text" class="form-control" disabled [(ngModel)]="jobDetails.jobStatus"></td>
        </tr>
        <tr>
          <td>Push Upgrade Start Date</td>
          <td><input type="text" class="form-control" disabled [(ngModel)]="jobDetails.startDate"></td>
          <td>Push Upgrade End Date</td>
          <td><input type="text" class="form-control" disabled [(ngModel)]="jobDetails.endDate"></td>
        </tr>
      </tbody>
    </table>

  </div>


  <div class="detail-data-container mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

      <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

      <!-- Index Column -->
      <ng-container matColumnDef="index">
        <th mat-header-cell *matHeaderCellDef>Index </th>
        <td mat-cell *matCellDef="let element"> {{element.index}} </td>
      </ng-container>

      <!-- Pacakge Name Column -->
      <ng-container matColumnDef="packageName">
        <th mat-header-cell *matHeaderCellDef> Package Name </th>
        <td mat-cell *matCellDef="let element"> {{element.packageName}} </td>
      </ng-container>

      <!-- Package Version Column -->
      <ng-container matColumnDef="packageVersion">
        <th mat-header-cell *matHeaderCellDef> Package Version </th>
        <td mat-cell *matCellDef="let element"> {{element.packageVersion}} </td>
      </ng-container>

      <!-- Package Status Column -->
      <ng-container matColumnDef="packageStatus">
        <th mat-header-cell *matHeaderCellDef> Status </th>
        <td mat-cell *matCellDef="let element"> {{element.packageStatus}} </td>
      </ng-container>

      <!-- Comments Column -->
      <ng-container matColumnDef="comments">
        <th mat-header-cell *matHeaderCellDef> Comments </th>
        <td mat-cell *matCellDef="let element"> {{element.comments}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button (click)="refresh('data')" cdkFocusInitial>Refresh</button>
</mat-dialog-actions>
