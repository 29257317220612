import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { forkJoin, Observable } from 'rxjs';
import { UpgradeScheduleList } from '../interface/upgrade-schedule-list';
import { ReleaseConfig } from '../interface/release-config';
import { AuthService } from '../shared/auth.service';
import { NglComboboxOptionItem } from 'ng-lightning';
import { CommonService } from '../shared/common.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { Tag } from '../interface/tag';
import { CreateUpgradeScheduleRequest } from '../interface/create-upgrade-schedule-request';
import { CustomerPreferenceOrg, ScheduleOrg, UpdateUpgradeScheduleRequest } from '../interface/update-upgrade-schedule-request'
import { DualListComponent } from '../dual-list/dual-list.component';
import { FormControl } from '@angular/forms';
import { isEmpty, map, startWith } from 'rxjs/operators';
import { CustomerPreference } from '../interface/customer-preference';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { stringify } from '@angular/compiler/src/util';
import { SmartScheduleSettings } from '../interface/smart-schedule-settings'
import { SmartTagRequest } from '../interface/push-upgrade-smart-tag';

@Component({
  selector: 'app-admin-smart-schedule',
  templateUrl: './admin-smart-schedule.component.html',
  styleUrls: ['./admin-smart-schedule.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class AdminSmartScheduleComponent implements OnInit {

  expandedElement: UpgradeScheduleList | null;
  scheduleOrgsListToRemove: ScheduleOrg = { pushUpgradeScheduleId: null, customerPreferenceOrgs: [] };
  customerPreferenceOrg: CustomerPreferenceOrg = { orgId: null, customerPrefernceId: null };
  public smartTagList: NglComboboxOptionItem[];
  smartTagSelection = [];
  openSmartTag = false

  keepSorted = true;
  key: string;
  display: string[];
  filter = true;
  open = false;

  userAdd = '';
  disabled = false;

  sourceLeft = true;
  format: any = DualListComponent.DEFAULT_FORMAT;

  sourceScheduledOrgs: Array<any>;
  confirmedScheduledOrgs: Array<any>;

  columnsToDisplay: string[] = ['select', 'id', 'name', 'releaseDisplayName', 'scheduledDate', 'tags', 'status', 'action'];

  dataSource = new MatTableDataSource<UpgradeScheduleList>();
  length = 100;
  pageIndex = 0;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 25, 50, 75, 100];
  searchText: string = '';
  isSandboxTab: boolean = true;

  isLoading: boolean = false;
  showTopToast = false;
  toastMsg: string;
  toastVariant: string;
  disableBulkUpgradeBtn: boolean = true;

  adobeSignErrorMsg: string = "";
  opened = false;
  promptHeader = 'Adobe Sign Validation Error';
  prompt = 'error';

  openConfirmationModal: boolean = false;
  openConfirmationModaltoCancel: boolean = false;
  openConfirmationModaltoFialise: boolean = false;
  allReleaseInfo: ReleaseConfig[];
  openReleaseMenu: boolean = false;
  releaseList: ReleaseConfig[];
  public releaseDropdownList: NglComboboxOptionItem[];
  selection: number;
  openToolTip: boolean = false;
  FinalizelTipMsg: string;
  openUpgradeModal = false;
  isEarlyFeedbackEnabled: boolean = false;
  scheduleOrgsDataSource = new MatTableDataSource<UpgradeScheduleList>();
  checkboxselection = new SelectionModel<UpgradeScheduleList>(true, []);
  timeZone: string;
  tzTimeZone: string;
  selectedTab: any = 'sandbox';
  statusIsScheduled: string = 'Scheduled';

  showScheduleUpgradeModal: boolean = false;
  scheduleUpgradeModalHeader: string;
  scheduleUpgradeModalBtnLabel: string;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  tagsVisible = true;
  tagsSelectable = true;
  tagsRemovable = true;
  tagsAddOnBlur = true;

  cleaveOptions = {
    date: true,
    delimiter: '/',
    datePattern: ['d', 'MMM', 'Y']
  };
  scheduleName: string;
  scheduleDate: Date;
  minScheduleDate: Date;
  scheduleTimeSelection: string = null;
  scheduleDateHourSelection: string = null;
  scheduleDateMinutesSelection: string = null;
  scheduleTimeOptions: NglComboboxOptionItem[];
  scheduleDateHours: string[];
  scheduleDateMinutes: string[];
  scheduleTimeOpen: true;
  scheduleHoursOpen: true;
  scheduleMinsOpen: true;
  createUpgradeScheduleRequest: ScheduleOrg[] = [];
  createRescheduleUpgradeScheduleRequest: CreateUpgradeScheduleRequest = { id: null, releaseConfigId: null, tags: [], orgIds: [], scheduleDate: new Date(), scheduleName: null, description: null, productLine: [], isSandbox: null };
  updateRescheduleRequest: UpdateUpgradeScheduleRequest = { newPushUpgradeScheduleRequest: this.createRescheduleUpgradeScheduleRequest, existingRescheduleOrgs: [] }
  releaseDropdownInputCtrl: FormControl = new FormControl();
  filteredReleaseDropdown$: Observable<any[]>;
  smartScheduleSettings: SmartScheduleSettings = { timeInDaysBeforeFinalzeSchedule: 0, timeInDaysToSchedule: 0, timeInDaysToSendRemainderNotification: 0, maxOrgAllowedToSchedule: 30 }

  @ViewChild('TableOneSort', { static: false }) tableOneSort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(public authservice: AuthService, private commonService: CommonService) { }

  ngOnInit(): void {
    this.doReset();
    var date = new Date();
    this.timeZone = /\((.*)\)/.exec(date.toString())[1];
    this.tzTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    if (this.authservice.isLoggedIn) {
      this.getUpgradeScheduleOrgList();
    }
    else {
      this.authservice.redirectToAdminLogin();
    }
  }

  private dualListSettings() {
    this.key = 'orgId';
    this.display = ['name', 'orgId', 'releaseDisplayName'];
    this.keepSorted = true;
  }

  doReset() {
    this.sourceScheduledOrgs = new Array<any>();
    this.confirmedScheduledOrgs = new Array<any>();
    this.dualListSettings();
  }



  filterBtn() {
    return (this.filter ? 'Hide Filter' : 'Show Filter');
  }

  doDisable() {
    this.disabled = !this.disabled;
  }

  disableBtn() {
    return (this.disabled ? 'Enable' : 'Disabled');
  }

  swapDirection() {
    this.sourceLeft = !this.sourceLeft;
    this.format.direction = this.sourceLeft ? DualListComponent.LTR : DualListComponent.RTL;
  }

  ngAfterViewInit() {
    this.paginator._intl.firstPageLabel = "First Page";
    this.paginator._intl.lastPageLabel = "Last Page";
    this.paginator._intl.previousPageLabel = "Prev Page";
    this.paginator._intl.nextPageLabel = "Next Page";
    this.paginator._intl.itemsPerPageLabel = "Display Records per page";
    this.dataSource.paginator = this.paginator;
  }

  openToast(msg: string, success: Boolean = false) {
    this.toastMsg = msg;
    if (success)
      this.toastVariant = 'success';
    else
      this.toastVariant = 'error';

    this.showTopToast = true;
  }

  onClose(reason: string) {
    console.log(`Closed by ${reason}`);
  }

  /** Method triggered to fetch/refresh the Push-Upgrade Jobs records */
  refresh(): void {
    this.getUpgradeScheduleOrgList();
    this.doReset();
  }

  getUpgradeScheduleOrgList(): void {
    this.checkboxselection.clear()
    this.isLoading = true;
    let smartScheduleSettings = this.authservice.getPushUpgradeScheduleSettings();
    let SmartTagsList = this.authservice.getAllSmartTagsList();
    let upgradeScheduleOrgs = this.authservice.getPushUpgradeScheduleList(this.searchText, this.pageIndex + 1, this.pageSize, this.tzTimeZone, this.isSandboxTab);
    forkJoin([upgradeScheduleOrgs, smartScheduleSettings, SmartTagsList]).subscribe(response => {
      var data = response[0].data;
      this.length = response[0].totalCount;
      this.pageIndex = response[0].pageNo - 1;
      this.pageSize = response[0].pageSize;
      this.smartScheduleSettings = response[1];
      this.smartTagList = response[2].map((item, i) => {
        return {
          value: item.id,
          label: item.name
        };
      });

      this.dataSource = new MatTableDataSource<UpgradeScheduleList>(data);
      this.dataSource.sort = this.tableOneSort;
      // PU-748 Handle sorting with case insensitive behaviour.
      this.dataSource.sortingDataAccessor = (data, header) => this.authservice.isEmpty(data[header]) ? "" : Array.isArray(data[header]) ? data[header].join() : data[header].toString().toLowerCase();
      this.isLoading = false;
    },
      error => {
        console.log(error)
      });
  }

  handlePageEvent(event: PageEvent) {
    this.length = event.length;
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.getUpgradeScheduleOrgList();
  }

  //**Apply filter to the Org list displayed on the UI */
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    if (this.searchText != filterValue.trim().toLowerCase()) {
      this.searchText = filterValue.trim().toLowerCase();
      if (this.searchText.length == 0 || this.searchText.length >= 3) {
        // PU-1168 Need to reset the page index as 0 at the time of search.
        this.pageIndex = 0;
        this.getUpgradeScheduleOrgList();
      }
    }
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.checkboxselection.selected.length;
    var enabledCheckboxCount = 0;
    this.dataSource.filteredData.forEach(row => (this.isDisabled(row.status)) ? null : enabledCheckboxCount++);
    return numSelected === enabledCheckboxCount;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.checkboxselection.clear();
      this.disableBulkUpgradeBtn = true;
      return;
    }
    else {
      this.dataSource.filteredData.forEach(row => (this.isDisabled(row.status)) ? null : this.checkboxselection.select(row));
      this.disableBulkUpgradeBtn = false;
    }
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: UpgradeScheduleList): string {
    if (!row) {
      this.checkboxselection.hasValue() ? this.disableBulkUpgradeBtn = false : this.disableBulkUpgradeBtn = true;
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
  }

  disableMasterCheckbox() {
    let disableMasterCheckbox: Boolean = true;
    this.dataSource.filteredData.forEach(x => (x.status === this.statusIsScheduled || x.status === 'Allocated') ? disableMasterCheckbox = false : null)
    return disableMasterCheckbox
  }

  openRemoveOrgFromSchedulelConfirmationModal(scheduleId: string, org: any) {
    this.createUpgradeScheduleRequest = [];
    this.scheduleOrgsListToRemove.customerPreferenceOrgs = [];
    this.scheduleOrgsListToRemove.pushUpgradeScheduleId = scheduleId;
    this.customerPreferenceOrg.orgId = org.orgId;
    this.customerPreferenceOrg.customerPrefernceId = org.customerPreferenceId;
    this.scheduleOrgsListToRemove.customerPreferenceOrgs.push(this.customerPreferenceOrg);
    this.createUpgradeScheduleRequest.push(this.scheduleOrgsListToRemove);
    this.openConfirmationModal = true;
  }

  closeRemoveOrgFromSchedulelConfirmationModal() {
    this.openConfirmationModal = false;
  }

  openScheduleCancelConfirmationModal() {
    this.openConfirmationModaltoCancel = true;
  }

  closeScheduleCancelConfirmationModal() {
    this.openConfirmationModaltoCancel = false;
  }

  openScheduleFinaliseConfirmationModal() {
    this.openConfirmationModaltoFialise = true;
  }

  closeScheduleFinaliseConfirmationModal() {
    this.openConfirmationModaltoFialise = false;
  }

  cancelSelectedSchedules() {
    this.isLoading = true;
    this.createUpgradeScheduleRequest = [];
    var lstOfSchedule = this.checkboxselection.selected;

    for (let value of lstOfSchedule.values()) {
      var scheduleOrgsListToRemove: ScheduleOrg = { pushUpgradeScheduleId: null, customerPreferenceOrgs: [] };
      for (var i = 0; i < value.organizations.length; i++) {
        scheduleOrgsListToRemove.pushUpgradeScheduleId = value.id;
        var customerPreferenceOrg: CustomerPreferenceOrg = { orgId: null, customerPrefernceId: null };
        customerPreferenceOrg.orgId = value.organizations[i].orgId;
        customerPreferenceOrg.customerPrefernceId = value.organizations[i].customerPreferenceId;
        scheduleOrgsListToRemove.customerPreferenceOrgs.push(customerPreferenceOrg)
      }
      this.createUpgradeScheduleRequest.push(scheduleOrgsListToRemove);
    }
    this.authservice.cancelScheduleUpgrade(this.createUpgradeScheduleRequest).subscribe((data: ReleaseConfig) => {
      this.createUpgradeScheduleRequest = [];
      this.isLoading = false;
      this.openToast(this.checkboxselection.selected.length + ' Schedule(s) has been cancelled', true);
      this.closeScheduleCancelConfirmationModal();
      this.refresh();

    }, error => {
      console.log(error);
      this.openToast("System Error Occured!", false);
      this.closeScheduleCancelConfirmationModal();
      this.isLoading = false;
      this.refresh();
    });
  }

  finalizeSelectedSchedules() {
    this.isLoading = true;
    this.createUpgradeScheduleRequest = [];
    var lstOfSchedule = this.checkboxselection.selected;

    for (let value of lstOfSchedule.values()) {
      var scheduleOrgsListToRemove: ScheduleOrg = { pushUpgradeScheduleId: null, customerPreferenceOrgs: [] };
      for (var i = 0; i < value.organizations.length; i++) {
        scheduleOrgsListToRemove.pushUpgradeScheduleId = value.id;
        var customerPreferenceOrg: CustomerPreferenceOrg = { orgId: null, customerPrefernceId: null };
        customerPreferenceOrg.orgId = value.organizations[i].orgId;
        customerPreferenceOrg.customerPrefernceId = value.organizations[i].customerPreferenceId;
        scheduleOrgsListToRemove.customerPreferenceOrgs.push(customerPreferenceOrg)
      }
      this.createUpgradeScheduleRequest.push(scheduleOrgsListToRemove);
    }
    this.authservice.finalizeScheduleUpgrade(this.createUpgradeScheduleRequest).subscribe((data: ReleaseConfig) => {
      this.createUpgradeScheduleRequest = [];
      this.isLoading = false;
      this.openToast(this.checkboxselection.selected.length + ' Schedule(s) has been finalised', true);
      this.closeScheduleFinaliseConfirmationModal();
      this.refresh();

    }, error => {
      console.log(error);
      this.openToast("System Error Occured!", false);
      this.closeScheduleFinaliseConfirmationModal();
      this.isLoading = false;
      this.refresh();
    });
  }

  removeSingleSchedule(element: UpgradeScheduleList) {
    this.checkboxselection.clear();
    this.checkboxselection.selected.push(element);
    this.openScheduleCancelConfirmationModal();
  }

  finalizeSingleSchedule(element: UpgradeScheduleList) {
    this.checkboxselection.clear();
    this.checkboxselection.selected.push(element);
    this.openScheduleFinaliseConfirmationModal();
  }

  groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }

  //**Format date to display on UI */
  dateFormatter(inputDate: string) {
    var date = this.authservice.dateFormatter(inputDate);
    if (this.authservice.isEmpty(this.timeZone))
      this.timeZone = /\((.*)\)/.exec(date.toString())[1];
    date = date.split('(')[0];
    return date;
  }

  tabChange(tabName: string) {
    this.pageIndex = 0;
    var isSandboxTabActive = true;
    switch (tabName) {
      case "production": {
        isSandboxTabActive = false;
        break;
      }
      case "sandbox": {
        isSandboxTabActive = true;
        break;
      }
      default: {
        isSandboxTabActive = true;
        break;
      }
    }
    if (isSandboxTabActive !== this.isSandboxTab) {
      this.isSandboxTab = isSandboxTabActive;
      this.getUpgradeScheduleOrgList();
    }
  }

  //#region Schedule Upgrade Methods
  openScheduleUpgradeModal(isUpdate: boolean = false, element: UpgradeScheduleList) {
    this.isLoading = true;
    this.smartTagSelection = [];
    // Check if access token is present else take the user to admin login page.
    if (this.authservice.isLoggedIn) {
      this.getAllReleaseInfo().then((res: ReleaseConfig[]) => {
        this.allReleaseInfo = res;
        this.prepareScheduleCreationModal(isUpdate);
        this.isLoading = false;
      })
    }
    else {
      this.authservice.redirectToAdminLogin();
    }
  }

  getAllReleaseInfo() {
    return new Promise((resolve, reject) => {
      if (this.commonService.isEmpty(this.allReleaseInfo)) {
        this.authservice.getAllReleaseInfo().subscribe(response => {
          resolve(response);
        }, () => {
          reject(null)
        });
      }
      else {
        resolve(this.allReleaseInfo);
      }
    })
  }


  prepareScheduleCreationModal(isUpdate: boolean = false) {
    var releaseList = this.allReleaseInfo.map((item, i) => {
      var isDisable = false;
      return {
        value: item.id,
        label: item.isCummulative ? item.name : item.displayLabel,
        disabled: isDisable
      };
    });

    // check if any Org is already scheduled
    this.getScheduleDates(isUpdate);
    this.releaseDropdownList = releaseList;
    this.filteredReleaseDropdown$ = this.releaseDropdownInputCtrl.valueChanges
      .pipe(
        startWith(''),
        map(val => !val ? [...this.releaseDropdownList] : this.filterReleaseDropdownList(val))
      );
    this.showScheduleUpgradeModal = true;

    // prepare Orgs list for selected Orgs
    this.scheduleOrgsDataSource.data = this.checkboxselection.selected;

    if (isUpdate) {
      this.scheduleUpgradeModalHeader = "Update Smart Scheduled Upgrade";
      this.scheduleUpgradeModalBtnLabel = "Update";
      this.createRescheduleUpgradeScheduleRequest.releaseConfigId = this.checkboxselection.selected[0].releaseConfigId;
      this.scheduleTimeSelection = this.checkboxselection.selected[0].scheduledDateStr.split(" ")[1].slice(0, -2);
      this.scheduleTimeChange(this.scheduleTimeSelection);
      this.onReleaseSelection(this.createRescheduleUpgradeScheduleRequest.releaseConfigId, isUpdate);
    }
    else {
      this.scheduleUpgradeModalHeader = "Create Smart Scheduled Upgrade";
      this.scheduleUpgradeModalBtnLabel = "Submit";
      this.doReset();
      this.createRescheduleUpgradeScheduleRequest = { id: null, releaseConfigId: null, tags: [], orgIds: [], scheduleDate: this.scheduleDate, scheduleName: null, description: null, productLine: [], isSandbox: this.isSandboxTab };
    }
  }



  prepareSelectedOrgsForUpdate() {
    this.checkboxselection.selected[0].organizations.forEach(x => {
      let org: CustomerPreference = {
        orgId: null,
        jobId: '',
        customerPreferenceId: '',
        name: '',
        type: '',
        orgEdition: '',
        instanceName: '',
        packageName: '',
        sourceVersionNumber: 0,
        targetVersionNumber: 0,
        status: undefined,
        action: undefined,
        currentReleaseVersion: '',
        timeZone: '',
        releaseTriggeredDate: '',
        releaseDisplayName: '',
        releasePatchNo: '',
        jobStartDate: '',
        jobEndDate: undefined,
        jobDuration: 0,
        jobDurationInString: '',
        jobStartDateStr: '',
        failedJobReleaseTriggeredDate: '',
        failedJobReleaseName: '',
        summary: '',
        isEnrolledForEarlyFeedback: false,
        scheduleId: '',
        scheduleName: '',
        scheduleDate: undefined,
        emailId: '',
        smartTags: [],
        enableAutoUpgradeForGA: false,
        accountId: '',
        optOutForNextGARelease: false
      };
      org.orgId = x.orgId;
      org.name = x.orgName;
      if (!this.commonService.isEmpty(org.orgId)) {
        this.confirmedScheduledOrgs.push(org);
        this.sourceScheduledOrgs.push(org);
      }
    });

  }

  filterReleaseDropdownList(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.releaseDropdownList.filter(release => release.label.toLowerCase().indexOf(filterValue) > -1);
  }

  prepareScheduleTimeOptions(inputDate: Date) {

    this.scheduleTimeOptions = [];
    let hrs = 0;
    var todayDate = new Date();
    if (todayDate.getDate() == inputDate.getDate() &&
      todayDate.getMonth() == inputDate.getMonth()) {
      hrs = todayDate.getHours() + 2;
    }

    for (; hrs < 24; hrs++) {
      var paddedHrs = (hrs < 10 ? "0" : "") + hrs.toString();

      for (let mins = 0; mins < 46; mins = mins + 15) {
        var paddedMins = (mins < 10 ? "0" : "") + mins.toString();

        let item: NglComboboxOptionItem = {
          value: paddedHrs + ":" + paddedMins,
          label: paddedHrs + ":" + paddedMins
        };

        this.scheduleTimeOptions.push(item);
      }
    }

    this.scheduleTimeSelection = this.prepareScheduleTimeOptions.length > 0 ? this.scheduleTimeOptions[0].value.toString() : null;
    this.scheduleTimeChange(this.scheduleTimeOptions[0].value.toString());
  }

  getScheduleDates(isUpdate = false) {
    // Get Active release Date and compare with today and acoording to set min and max date
    var todayDate = new Date();

    if (todayDate.getHours() > 22) {
      todayDate.setDate(todayDate.getDate() + 1);
    }

    if (isUpdate) {
      this.scheduleDate = this.createRescheduleUpgradeScheduleRequest.scheduleDate;
    }
    else {
      this.scheduleDate = this.getDate(todayDate, this.smartScheduleSettings.timeInDaysToSchedule);
    }

    this.minScheduleDate = this.getDate(todayDate, this.smartScheduleSettings.timeInDaysToSchedule);
    this.prepareScheduleTimeOptions(this.scheduleDate);
    this.setupScheduleName(this.scheduleDate);
  }

  getDate(fromDate: Date, addDays: number = 0): Date {
    var date = new Date(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate());
    if (addDays > 0) {
      date.setDate(date.getDate() + addDays);
    }

    return date;
  }

  scheduleDateChange(selectedDate: Date) {
    this.scheduleDate = selectedDate;
    this.prepareScheduleTimeOptions(this.scheduleDate);
  }

  scheduleTimeChange(selectedItem: string) {
    var hrs = selectedItem.substring(0, selectedItem.indexOf(":"));
    var mins = selectedItem.replace(hrs + ":", "");
    this.scheduleDateHourSelection = hrs;
    this.scheduleDateMinutesSelection = mins;

    this.setupScheduleName(this.scheduleDate);
  }

  onReleaseSelection(selectedRelease: string, isUpdate: boolean = false, resetSelection: boolean = true) {
    if (!this.authservice.isEmpty(selectedRelease)) {
      this.isLoading = true;
      var release = this.allReleaseInfo.filter(x => x.id == this.createRescheduleUpgradeScheduleRequest.releaseConfigId);
      if (selectedRelease.length > 0 && release.length) {
        this.authservice.GetAllSchedulableOrgs(release[0].name, this.smartTagSelection, this.isSandboxTab).subscribe((data: any) => {
          //In Case of Smart Tag change. Retain the Intermediate Selections ,do not Reset the Selection.
          if (resetSelection) {
            this.doReset();
          }
          else {
            let confirmedScheduledOrgs = this.confirmedScheduledOrgs;
            this.doReset();
            this.confirmedScheduledOrgs = confirmedScheduledOrgs;
          }
          this.sourceScheduledOrgs = JSON.parse(JSON.stringify(data));
          if (isUpdate) {
            this.prepareSelectedOrgsForUpdate();
          }
          this.isLoading = false;
        },
          (err) => {
            console.log(err);
            this.openToast("System Error Occured!", false);
            this.closeScheduleUpgradeModal();
            this.isLoading = false;
            this.refresh();
          }
        );
      }
    }
    else {
      this.doReset();
    }
  }

  onSmartTagSelection() {
    if (!this.commonService.isEmpty(this.createRescheduleUpgradeScheduleRequest.releaseConfigId)) {
      let isUpdate = false;
      if (this.scheduleUpgradeModalBtnLabel === "Update") {
        isUpdate = true;
      }
      this.onReleaseSelection(this.createRescheduleUpgradeScheduleRequest.releaseConfigId, isUpdate, false)
    }
  }

  setupScheduleName(inputDate: Date) {
    var selectedRelease = this.allReleaseInfo.filter(x => x.id == this.createRescheduleUpgradeScheduleRequest.releaseConfigId);
    if (selectedRelease.length > 0) {
      this.scheduleName = selectedRelease[0].name + ":"
        + inputDate.getDate() + "-" + inputDate.toDateString().substr(4, 3) + "-" + inputDate.getFullYear()
        + " " + this.scheduleDateHourSelection + ":" + this.scheduleDateMinutesSelection;
    }

    var scheduleHours: number = +this.scheduleDateHourSelection;
    var scheduleMins: number = +this.scheduleDateMinutesSelection;
    this.scheduleDate = new Date(inputDate.getFullYear(), inputDate.getMonth(), inputDate.getDate(), scheduleHours, scheduleMins, 0);
    this.createRescheduleUpgradeScheduleRequest.scheduleDate = this.scheduleDate;
    this.scheduleTimeSelection = (scheduleHours < 10 ? "0" + scheduleHours : scheduleHours) + ":" + (scheduleMins < 10 ? "0" + scheduleMins : scheduleMins)
  }

  addTag(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (!this.commonService.isEmpty(value)) {

      if (this.createRescheduleUpgradeScheduleRequest.tags.length + 1 > 10) {
        this.openToast("Cannot add more than 10 tags", false);
        // Clear the input value
        event.input.value = "";
        return;
      }

      this.createRescheduleUpgradeScheduleRequest.tags.push(value);
    }

    // Clear the input value
    event.input.value = "";
  }

  removeTag(tag: string): void {
    const index = this.createRescheduleUpgradeScheduleRequest.tags.indexOf(tag);

    if (index >= 0) {
      this.createRescheduleUpgradeScheduleRequest.tags.splice(index, 1);
    }
  }

  saveUpgradeSchedule() {
    if (this.commonService.isEmpty(this.createRescheduleUpgradeScheduleRequest.releaseConfigId)) {
      this.openToast("Please select a Release version from dropdown.", false);
      return;
    }

    if (this.commonService.isEmpty(this.confirmedScheduledOrgs)) {
      this.openToast("Please select orgs for the creation of schedule.", false);
      return;
    }

    if (this.createRescheduleUpgradeScheduleRequest.tags.length > 10) {
      this.openToast("Cannot add more than 10 tags", false);
      return;
    }

    if (this.createRescheduleUpgradeScheduleRequest.scheduleDate < new Date()) {
      this.openToast("Cannot update the schedule. Please change the Schedule Date/Time to greater than the current Date/Time", false);
      return;
    }

    this.isLoading = true;

    if (this.commonService.isEmpty(this.createRescheduleUpgradeScheduleRequest.scheduleName)) {
      this.getScheduleDates();
      this.createRescheduleUpgradeScheduleRequest.scheduleName = this.scheduleName;
    }
    //this.createRescheduleUpgradeScheduleRequest.releaseConfigId = this.releaseSelection;
    this.createRescheduleUpgradeScheduleRequest.productLine = [];
    this.createRescheduleUpgradeScheduleRequest.isSandbox = this.isSandboxTab;


    this.createRescheduleUpgradeScheduleRequest.orgIds = [];
    this.confirmedScheduledOrgs.forEach(orgData => {
      this.createRescheduleUpgradeScheduleRequest.orgIds.push(orgData.orgId);
    });

    var scheduleHours: number = +this.scheduleDateHourSelection;
    var scheduleMins: number = +this.scheduleDateMinutesSelection;

    this.createRescheduleUpgradeScheduleRequest.scheduleDate = new Date(this.scheduleDate.getFullYear(), this.scheduleDate.getMonth(), this.scheduleDate.getDate(), scheduleHours, scheduleMins, 0);

    this.authservice.CreateDraftUpgradeSchedule(this.createRescheduleUpgradeScheduleRequest).subscribe((data: any) => {
      let success_msg: string = "";
      if (this.scheduleUpgradeModalBtnLabel === "Submit")
        success_msg = 'Push Upgrade Allocated for ' + this.createRescheduleUpgradeScheduleRequest.orgIds.length + ' Org(s)';
      else
        success_msg = 'Push Upgrade schedule is updated for ' + this.createRescheduleUpgradeScheduleRequest.orgIds.length + ' Org(s)';

      this.openToast(success_msg, true);
      this.isLoading = false;
      //this.checkboxselection.selected.forEach(x => x.releaseTriggeredDate = new Date().toISOString());
      this.checkboxselection.clear();
      //this.cancelReleaseSelectionMenu();
      this.closeScheduleUpgradeModal();
      this.refresh();
    },
      (err) => {
        var msg = err.split("\n");
        this.isLoading = false;
        if (msg[0] != "Error Code: 400") {
          console.log(err);
          this.openToast("System Error Occured!", false);
        }
        else {
          this.adobeSignErrorMsg = "";
          for (var ctr = 1; ctr < msg.length; ctr++) {
            if (ctr === 1) {
              var replacedMsg = msg[ctr].replace("Message: ", "");
              this.adobeSignErrorMsg += replacedMsg;
            }
            else {
              this.adobeSignErrorMsg += msg[ctr];
            }
            this.adobeSignErrorMsg += "<br><br>";
          }
          this.openErrorPrompt();
        }
      }
    );
  }

  updateSingleSchedule(element: UpgradeScheduleList) {
    this.checkboxselection.clear();
    this.checkboxselection.selected.push(element);
    this.createRescheduleUpgradeScheduleRequest.id = element.id;
    this.createRescheduleUpgradeScheduleRequest.scheduleName = element.name;
    this.createRescheduleUpgradeScheduleRequest.isSandbox = this.isSandboxTab;
    this.createRescheduleUpgradeScheduleRequest.scheduleDate = this.getDate(new Date(Date.parse(element.scheduledDate.toString().slice(0, -1))));
    this.createRescheduleUpgradeScheduleRequest.description = element.description;
    this.createRescheduleUpgradeScheduleRequest.tags = element.tags;
    this.createRescheduleUpgradeScheduleRequest.orgIds = element.organizations.map(x => x.orgId);
    this.createRescheduleUpgradeScheduleRequest.releaseConfigId = element.releaseConfigId;
    this.openScheduleUpgradeModal(true, element);
  }

  closeScheduleUpgradeModal() {
    this.scheduleName = null;
    this.scheduleTimeSelection = null;
    this.showScheduleUpgradeModal = false;
  }

  isDisabled(status: string) {
    if (status === this.statusIsScheduled || status === "Allocated")
      return false;
    else
      return true;
  }

  getFinalizeBtnToolTip(schedule: UpgradeScheduleList) {
    var allowToFinalise = (this.getFinaliseDate(schedule.scheduledDateStr) > new Date());
    var msg = "";
    if (!allowToFinalise) {
      msg = "Please Update the schedule and finalize " + this.smartScheduleSettings.timeInDaysBeforeFinalzeSchedule + " day(s) before the schedule date";
    }
    else {
      msg = "This will freeze the Schedule and no update can be done further";
    }
    if (schedule.status !== "Allocated") {
      msg = "The Schedule was already finalized";
    }

    if (this.commonService.isEmpty(schedule.organizations) || this.commonService.isEmpty(schedule.organizations[0].orgId)) {
      msg = "Please Allocate Orgs into the Schedule";
    }
    return msg;
  }

  finaliseIsDisabled(schedule: UpgradeScheduleList) {
    var allowToFinalise = (this.getFinaliseDate(schedule.scheduledDateStr) > new Date());
    if (schedule.status === "Allocated" && allowToFinalise && !(this.commonService.isEmpty(schedule.organizations) || this.commonService.isEmpty(schedule.organizations[0].orgId)))
      return false;
    else
      return true;
  }

  getFinaliseDate(dateStr: string) {
    let time = dateStr.split(" ")[1].slice(0, -2);
    var hrs: number = +time.substring(0, time.indexOf(":"));
    var mins: number = +time.replace(hrs + ":", "");
    var date = new Date(dateStr);
    let scheduleDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), hrs, mins, 0);
    date.setDate(scheduleDate.getDate() - this.smartScheduleSettings.timeInDaysBeforeFinalzeSchedule);
    return date;
  }

  updateIsDisabled(status: string) {
    if (status === "Allocated")
      return false;
    else
      return true;
  }

  openJObInfoInNewTab(jobId: string) {
    if (!this.commonService.isEmpty(jobId)) {
      var jobInfoURL = window.location.origin + '/registration/ui/admin/job-list?filter=' + jobId
      window.open(jobInfoURL);
    }
  }

  checkIsActionVisible(scheduleStatus: string) {
    if (scheduleStatus === "Allocated" || scheduleStatus === "Scheduled")
      return true;
    else
      return false;
  }

  removeOrgFromSchedule() {
    this.isLoading = true;

    this.authservice.removeScheduleUpgrade(this.createUpgradeScheduleRequest).subscribe((data: ReleaseConfig) => {
      this.createUpgradeScheduleRequest = [];
      this.isLoading = false;
      this.openToast('Org has been removed from the schedule', true);
      var index = this.dataSource.data.find(x => x.id === this.scheduleOrgsListToRemove.pushUpgradeScheduleId).organizations.findIndex(item => item.orgId === this.scheduleOrgsListToRemove.customerPreferenceOrgs[0].orgId);
      this.dataSource.data.find(x => x.id === this.scheduleOrgsListToRemove.pushUpgradeScheduleId).organizations.splice(index, 1);
      this.closeRemoveOrgFromSchedulelConfirmationModal();

    }, error => {
      console.log(error);
      this.openToast("System Error Occured!", false);
      this.closeRemoveOrgFromSchedulelConfirmationModal();
      this.isLoading = false;
      this.refresh();
    });

  }

  openErrorPrompt() {
    this.opened = !this.opened;
  }

  cancelErrorPrompt() {
    this.adobeSignErrorMsg = "";
    this.opened = false;
  }

  remove(smartTagId: string) {
    this.smartTagSelection = this.smartTagSelection.filter((s) => s !== smartTagId);
    this.onSmartTagSelection();
  }

  getSmartTagNameById(smartTagId: string) {
    return this.smartTagList[this.smartTagList.map(x => x.value).indexOf(smartTagId)].label;
  }

  getScheduleReleaseName(element: UpgradeScheduleList) {
    return element.releaseName.includes("AllPatches") ? element.releaseName : element.releaseDisplayName;
  }

  getReleaseName(releaseConfigId: string) {
    let selectedRelease = this.allReleaseInfo.find(x => x.id === releaseConfigId);
    return selectedRelease ? this.commonService.getCurrentReleaseLabel(selectedRelease) : null;
  }
  //#endregion
}
