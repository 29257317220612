import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../shared/auth.service';
import { CommonService } from '../shared/common.service';

@Component({
  selector: 'app-admin-navigation-panel',
  templateUrl: './admin-navigation-panel.component.html',
  styleUrls: ['./admin-navigation-panel.component.css']
})
export class AdminNavigationPanelComponent implements OnInit {

  constructor(private router: Router, private authservice: AuthService, public commonService: CommonService) { }

  ngOnInit(): void {
  }

  //**Log out Admin User */
  logOutUser(): void {
    this.authservice.doLogout();
    this.router.navigate(['ui/admin/login']);
  }

}
