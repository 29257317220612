import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from '../../shared/auth.service';
import { PackageUpgradeSettings } from '../../interface/pu-admin';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-package-upgrade-settings',
  templateUrl: './package-upgrade-settings.component.html',
  styleUrls: ['./package-upgrade-settings.component.css']
})
export class PackageUpgradeSettingsComponent implements OnInit {


  displayedColumns: string[] = ['subscriberPackageVersionKey', 'requiredPassword', 'requiredCustomInstallKey', 'action'];
  emailPattern = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
  dataSource = new MatTableDataSource<PackageUpgradeSettings>();
  isLoading: boolean = false;
  showSettingsModal: boolean = false;
  editSettings: boolean = false;
  defaultPackageUpgradeSettingsData: PackageUpgradeSettings = {
    id: "",
    subscriberPackageVersionKey: "",
    requiredPassword: false,
    requiredCustomInstallKey: false,
    customInstallKey: "",
    showCustomKey: false
  };
  editorData: PackageUpgradeSettings = this.defaultPackageUpgradeSettingsData;
  openDeleteConfirmationModel = false;
  deleteData: PackageUpgradeSettings = null;


  @ViewChild('TableOneSort', { static: false }) tableOneSort: MatSort;

  length: number = 0;
  pageSize: number = 10;
  pageIndex: number = 0;
  pageSizeOptions: number[] = [10, 25, 50, 75, 100];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  isSubmitted: boolean = false;
  @Input() searchText :FormControl;

  constructor(private authservice: AuthService) { }

  ngOnInit(): void { }

  public getPackageUpgradeSettingsList(searchText: string = "") {
    searchText = this.searchText.value
    // this.checkboxselection.clear()
    this.isLoading = true;

    this.authservice.getAllPackageUpgradeSettings({
      searchText,
      pageNo: this.pageIndex + 1,
      pageSize: this.pageSize
    }).subscribe(response => {
      this.dataSource = new MatTableDataSource<PackageUpgradeSettings>(response.data);
      this.length = this.pageSize * response.totalPages;
      this.dataSource.sort = this.tableOneSort;
      this.dataSource.sortingDataAccessor = (data, header) => this.authservice.isEmpty(data[header]) ? "" : Array.isArray(data[header]) ? data[header].join() : data[header].toString().toLowerCase();
      this.isLoading = false;
    }, () => {
      this.isLoading = false;
    });
  }

  public onCreate() {
    this.editorData = JSON.parse(JSON.stringify(this.defaultPackageUpgradeSettingsData));
    this.editSettings = this.isSubmitted = false;
    this.showSettingsModal = true;
  }

  editPackageUpgradeSettings(item: PackageUpgradeSettings) {
    this.editSettings = this.showSettingsModal = true;
    this.isSubmitted = false;
    this.editorData = JSON.parse(JSON.stringify(item));
  }

  onSubmitPackageUpgradeSettings() {

    this.isSubmitted = true;
    let hasError = !this.editorData.subscriberPackageVersionKey || (this.editorData.requiredCustomInstallKey && !this.editorData.customInstallKey)

    if (!hasError) {
      this.isLoading = true;

      delete this.editorData.showCustomKey;

      this.authservice.submitPackageUpgradeSettings(this.editorData).subscribe(() => {
        this.editSettings = false;
        this.showSettingsModal = false;
        this.getPackageUpgradeSettingsList();
        this.isLoading = false;
      }, () => {
        this.isLoading = false;
      })
    }
  }

  confirmDeletePackageUpgradeSettings(item: PackageUpgradeSettings) {
    this.openDeleteConfirmationModel = true;
    this.deleteData = item;
  }

  deletePackageUpgradeSettings() {
    this.authservice.deletePackageUpgradeSettings(this.deleteData.subscriberPackageVersionKey).toPromise().finally(() => {
      this.openDeleteConfirmationModel = false;
      this.getPackageUpgradeSettingsList();
      this.deleteData = null;
    })
  }

  handlePageEvent(event: PageEvent) {
    this.length = event.length;
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;

    this.getPackageUpgradeSettingsList();
  }

}
