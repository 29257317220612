import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AuthService } from '../shared/auth.service';


@Component({
  selector: 'app-admin-settings',
  templateUrl: './admin-settings.component.html',
  styleUrls: ['./admin-settings.component.css']
})
export class AdminSettingsComponent implements AfterViewInit {

  searchField: FormControl = new FormControl();
  activeTab = 'watcherList';
  selectedTab = 'watcherList';
  @ViewChild('watcherList') watcherList;
  @ViewChild('commonSettings') commonSettings;
  @ViewChild('packageUpgradeSettings') packageUpgradeSettings;
  @ViewChild('packageUpgradeActions') packageUpgradeActions;
  @ViewChild('user') user;


  constructor(private authservice: AuthService) {

    this.searchField.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe(text => {
      if (text != null) {
        this.applyFilter(text)
      }
    })
  }

  ngAfterViewInit(): void {
    // if (!this.authservice.isLoggedIn) {
    //   this.authservice.redirectToAdminLogin();
    // }
  }

  tabChange(tabName) {
    this.searchField.setValue(null);
    this.activeTab = tabName;
    this.applyFilter("");
  }

  applyFilter(searchText) {
    switch (this.activeTab) {
      case 'watcher-list':
        this.watcherList.getUpgradeWatcherList(searchText);
        break;
      case 'package-upgrade-settings':
        this.packageUpgradeSettings.getPackageUpgradeSettingsList(searchText);
        break;
      case 'package-upgrade-actions':
        this.packageUpgradeActions.getPackageUpgradeActionsList(searchText);
        break;
      case 'user':
        this.user.getUserList(searchText);
        break;
      case 'common-settings':
        this.commonSettings.getCommonSettingsStatus();
        break;
    }
  }
}
