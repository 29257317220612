import { Component, OnInit, Inject } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { JobDetail } from '../interface/job-detail';
import { PackageInstallRecord } from '../interface/package-install-record';
import { AuthService } from '../shared/auth.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-show-more-detail',
  templateUrl: './show-more-detail.component.html',
  styleUrls: ['./show-more-detail.component.css']
})
export class ShowMoreDetailComponent implements OnInit {
  jobDetails: JobDetail;
  dataSource = new MatTableDataSource<PackageInstallRecord>();
  constructor(private authservice: AuthService, @Inject(MAT_DIALOG_DATA) public rowdata: any) {
    this.getMoreDetailsOnJobId();
  }
  ngOnInit(): void { }
  displayedColumns: string[] = ['index', 'packageName', 'packageVersion', 'packageStatus', 'comments'];

  getMoreDetailsOnJobId(): void {
    // Check if access token is present else take the user to admin login page.
    if (this.authservice.isLoggedIn) {
      this.authservice.getJobDetails(this.rowdata.orgId, this.rowdata.jobId).subscribe((data: any) => {
        this.jobDetails = data;
        this.dataSource = data.packageInstallRecords;
      },
        error => {
          console.log(error)
        });
    }
  }

  refresh(rowdata: any): void {
    this.getMoreDetailsOnJobId();
  }

}
