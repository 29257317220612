import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import {
  ActiveOrgs,
  AssociateSmartTagIdWithOrgIds,
  PushUpgradeSmartTag,
  SmartTagRequest,
} from '../interface/push-upgrade-smart-tag';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { AuthService } from '../shared/auth.service';
import { CommonService } from '../shared/common.service';
import { DualListComponent } from '../dual-list/dual-list.component';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

@Component({
  selector: 'app-admin-smart-tag',
  templateUrl: './admin-smart-tag.component.html',
  styleUrls: ['./admin-smart-tag.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class AdminSmartTagComponent implements OnInit {
  isLoading: boolean = false;
  showTopToast = false;
  toastMsg: string;
  toastVariant: string;

  pushUpgradeSmartTag: PushUpgradeSmartTag = {
    name: null,
    orgs: [],
    smartTagId: null,
  };
  expandedElement: PushUpgradeSmartTag | null;
  checkboxselection = new SelectionModel<PushUpgradeSmartTag>(true, []);
  dataSource = new MatTableDataSource<PushUpgradeSmartTag>();
  columnsToDisplay: string[] = ['select', 'smartTagId', 'name', 'action'];
  length = 100;
  pageIndex = 0;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 25, 50, 75, 100];
  searchText: string = '';
  disableBulkDeleteBtn: boolean = true;

  showSmartTagModal: boolean = false;
  showSmartTageModalHeader: string;
  showSmartTagModalModalBtnLabel: string;

  showCSVTagModal: boolean = false;
  csvTags: string = '';
  csvErrorTags: string = '';

  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  tagsVisible = true;
  tagsSelectable = true;
  tagsRemovable = true;
  tagsAddOnBlur = true;

  sourceScheduledOrgs: Array<any>;
  confirmedScheduledOrgs: Array<any>;
  keepSorted = true;
  key: string;
  display: string[];
  filter = true;
  open = false;

  disabled = false;
  sourceLeft = true;
  format: any = DualListComponent.DEFAULT_FORMAT;

  @ViewChild('TableOneSort', { static: false }) tableOneSort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  openConfirmationModaltoDelete: boolean = false;

  constructor(
    public authservice: AuthService,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    if (this.authservice.isLoggedIn) {
      this.getSmartTagList();
      this.doReset();
    } else {
      this.authservice.redirectToAdminLogin();
    }
  }

  ngAfterViewInit() {
    this.paginator._intl.firstPageLabel = 'First Page';
    this.paginator._intl.lastPageLabel = 'Last Page';
    this.paginator._intl.previousPageLabel = 'Prev Page';
    this.paginator._intl.nextPageLabel = 'Next Page';
    this.paginator._intl.itemsPerPageLabel = 'Display Records per page';
    this.dataSource.paginator = this.paginator;
  }

  getSmartTagList() {
    this.checkboxselection.clear();
    this.isLoading = true;
    this.authservice
      .GetSmartTagList(this.searchText, this.pageIndex + 1, this.pageSize)
      .subscribe(
        (data) => {
          this.length = data.totalCount;
          this.pageIndex = data.pageNo - 1;
          this.pageSize = data.pageSize;

          this.dataSource = new MatTableDataSource<PushUpgradeSmartTag>(
            data.data
          );
          this.dataSource.sort = this.tableOneSort;
          // PU-748 Handle sorting with case insensitive behaviour.
          this.dataSource.sortingDataAccessor = (data, header) =>
            this.authservice.isEmpty(data[header])
              ? ''
              : Array.isArray(data[header])
              ? data[header].join()
              : data[header].toString().toLowerCase();
          this.isLoading = false;
        },
        (error) => {
          console.log(error);
        }
      );
  }

  private dualListSettings() {
    this.key = '_id';
    this.display = ['name', '_id'];
    this.keepSorted = true;
  }

  doReset() {
    this.sourceScheduledOrgs = new Array<any>();
    this.confirmedScheduledOrgs = new Array<any>();
    this.dualListSettings();
  }

  OpenSmartTagPopUp(isUpdate = false) {
    this.GetAllActiveOrgs(isUpdate);
    this.showSmartTagModal = true;
    this.csvErrorTags = '';

    if (isUpdate) {
      this.showSmartTageModalHeader = 'Update Smart Tag';
      this.showSmartTagModalModalBtnLabel = 'Update';
    } else {
      this.pushUpgradeSmartTag = { name: null, orgs: [], smartTagId: null };
      this.showSmartTageModalHeader = 'Create Smart Tag';
      this.showSmartTagModalModalBtnLabel = 'Submit';
    }
  }

  prepareSelectedOrgsForUpdate() {
    this.confirmedScheduledOrgs = [];
    this.pushUpgradeSmartTag.orgs.forEach((x) =>
      this.pushToSourceIfnotExist(x)
    );
  }

  pushToSourceIfnotExist(orgs: ActiveOrgs) {
    this.confirmedScheduledOrgs.push(orgs);
    if (!this.orgExists(orgs._id)) this.sourceScheduledOrgs.push(orgs);
  }

  orgExists(orgId) {
    return this.sourceScheduledOrgs.some(function (el) {
      return el._id === orgId;
    });
  }

  GetAllActiveOrgs(isUpdate = false) {
    this.isLoading = true;
    this.authservice.GetAllActiveOrgs().subscribe((data) => {
      this.sourceScheduledOrgs = JSON.parse(JSON.stringify(data));
      if (isUpdate) {
        this.prepareSelectedOrgsForUpdate();
      } else {
        this.confirmedScheduledOrgs = [];
      }
      this.isLoading = false;
    });
  }

  closeSmartTagModal() {
    this.showSmartTagModal = false;
  }

  openToast(msg: string, success: Boolean = false) {
    this.toastMsg = msg;
    if (success) this.toastVariant = 'success';
    else this.toastVariant = 'error';

    this.showTopToast = true;
  }

  onClose(reason: string) {
    console.log(`Closed by ${reason}`);
  }

  saveSmartTag() {
    if (this.commonService.isEmpty(this.pushUpgradeSmartTag.name)) {
      this.openToast('Please enter Smart Tag name', false);
      return;
    }

    this.isLoading = true;

    if (this.showSmartTagModalModalBtnLabel !== 'Update') {
      let smartTagRequest: SmartTagRequest = {
        id: null,
        name: this.pushUpgradeSmartTag.name,
      };
      this.authservice.CreateSmartTag(smartTagRequest).subscribe(
        (data: any) => {
          let success_msg: string = 'Smart tag created succesfully.';
          this.openToast(success_msg, true);
          this.isLoading = false;
          this.pushUpgradeSmartTag.smartTagId = data.id;
          this.AssociateSmartTagIdWithOrgIds();
        },
        (err) => {
          this.isLoading = false;
          var msg = err.split('\n');
          if (msg[0] != 'Error Code: 400') {
            console.log(err);
            this.openToast('System Error Occured!', false);
            this.closeSmartTagModal();
          } else {
            this.openToast(msg[1], false);
          }
        }
      );
    } else {
      let smartTagRequest: SmartTagRequest = {
        id: this.pushUpgradeSmartTag.smartTagId,
        name: this.pushUpgradeSmartTag.name,
      };
      this.authservice.UpdateSmartTag(smartTagRequest).subscribe(
        (data: any) => {
          let success_msg: string = 'Smart tag updated succesfully.';
          this.openToast(success_msg, true);
          this.isLoading = false;
          this.pushUpgradeSmartTag.smartTagId = data.id;
          this.AssociateSmartTagIdWithOrgIds(true);
        },
        (err) => {
          this.isLoading = false;
          var msg = err.split('\n');
          if (msg[0] != 'Error Code: 400') {
            console.log(err);
            this.openToast('System Error Occured!', false);
            this.closeSmartTagModal();
          } else {
            this.openToast(msg[1], false);
          }
        }
      );
    }
  }

  AssociateSmartTagIdWithOrgIds(isUpdate = false) {
    this.isLoading = true;
    let associatedSmartTagIdWithOrgIds: AssociateSmartTagIdWithOrgIds = {
      smartTagId: this.pushUpgradeSmartTag.smartTagId,
      orgIds: [],
    };
    this.confirmedScheduledOrgs.forEach((orgData) => {
      associatedSmartTagIdWithOrgIds.orgIds.push(orgData._id);
    });

    if (isUpdate || !this.commonService.isEmpty(this.confirmedScheduledOrgs)) {
      this.authservice
        .AssociateSmartTagIdWithOrgIds(associatedSmartTagIdWithOrgIds)
        .subscribe(
          (data) => {
            let success_msg: string = 'Smart tag linked with selected Orgs.';
            this.openToast(success_msg, true);
            this.isLoading = false;

            this.closeSmartTagModal();
            this.refresh();
          },
          (err) => {
            this.isLoading = false;
            console.log(err);
            this.openToast('System Error Occured!', false);
            this.closeSmartTagModal();
          }
        );
    } else {
      this.isLoading = false;
      this.closeSmartTagModal();
      this.refresh();
    }
  }

  //**Apply filter to the Org list displayed on the UI */
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    if (this.searchText != filterValue.trim().toLowerCase()) {
      this.searchText = filterValue.trim().toLowerCase();
      if (this.searchText.length == 0 || this.searchText.length >= 3) {
        // PU-1168 Need to reset the page index as 0 at the time of search.
        this.pageIndex = 0;
        this.getSmartTagList();
      }
    }
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.checkboxselection.clear();
      return;
    } else {
      this.dataSource.filteredData.forEach((row) =>
        this.isDeleteDisabled(row) ? null : this.checkboxselection.select(row)
      );
    }
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.checkboxselection.selected.length;
    var enabledCheckboxCount = 0;
    this.dataSource.filteredData.forEach((row) =>
      this.isDeleteDisabled(row) ? null : enabledCheckboxCount++
    );
    return numSelected === enabledCheckboxCount;
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: PushUpgradeSmartTag): string {
    if (!row) {
      this.checkboxselection.hasValue()
        ? (this.disableBulkDeleteBtn = false)
        : (this.disableBulkDeleteBtn = true);
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
  }

  handlePageEvent(event: PageEvent) {
    this.length = event.length;
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.getSmartTagList();
  }

  openSmartTagDeleteConfirmationModal() {
    this.openConfirmationModaltoDelete = true;
  }

  closeSmartTagDeleteConfirmationModal() {
    this.openConfirmationModaltoDelete = false;
  }

  deleteSelectedSmartTags() {
    this.isLoading = true;

    let deleteSmartTagIds = this.checkboxselection.selected.map(
      (a) => a.smartTagId
    );

    this.authservice.deleteSmartTag(deleteSmartTagIds).subscribe(
      (data) => {
        this.isLoading = false;
        this.openToast(
          this.checkboxselection.selected.length +
            ' Smart Tag(s) has been deleted',
          true
        );
        this.closeSmartTagDeleteConfirmationModal();
        this.refresh();
      },
      (err) => {
        this.isLoading = false;
        console.log(err);
        this.openToast('System Error Occured!', false);
        this.closeSmartTagModal();
      }
    );
  }

  deleteSingleSmartTag(element: PushUpgradeSmartTag) {
    this.checkboxselection.clear();
    this.checkboxselection.selected.push(element);
    this.openSmartTagDeleteConfirmationModal();
  }

  isDeleteDisabled(element: PushUpgradeSmartTag) {
    if (this.commonService.isEmpty(element.orgs)) {
      return false;
    } else {
      return true;
    }
  }

  getDeleteBtnToolTip(element: PushUpgradeSmartTag) {
    var msg = '';
    if (this.isDeleteDisabled(element)) {
      msg = 'Cannot delete if Smart Tag is linked to an Org.';
    } else {
      msg = 'Delete Smart Tag';
    }
    return msg;
  }

  /** Method triggered to fetch/refresh the Smart Tag records */
  refresh(): void {
    this.getSmartTagList();
    this.closeSmartTagDeleteConfirmationModal();
  }

  updateSingleSmartTag(element: PushUpgradeSmartTag) {
    this.checkboxselection.clear();
    this.checkboxselection.selected.push(element);
    this.pushUpgradeSmartTag.smartTagId = element.smartTagId;
    this.pushUpgradeSmartTag.name = element.name;
    this.pushUpgradeSmartTag.orgs = element.orgs;
    this.OpenSmartTagPopUp(true);
  }

  disableMasterCheckbox() {
    let disableMasterCheckbox: Boolean = true;
    this.dataSource.filteredData.forEach((x) =>
      x.orgs.length > 0 ? null : (disableMasterCheckbox = false)
    );
    return disableMasterCheckbox;
  }

  handleCSVTags() {
    if (this.csvTags.trim()) {
      const bulkStr =
        this.csvTags.indexOf(',') >= 0
          ? this.csvTags
              .trim()
              .split(',')
              .map((item) => item.trim())
              .filter((item) => item)
          : [this.csvTags];

      const sourceTags = this.sourceScheduledOrgs;
      const unmatchedStr = bulkStr.filter(
        (tag) => sourceTags.findIndex((src) => src._id === tag) === -1
      );

      const matchingNewTags = sourceTags.filter(
        (src) => bulkStr.findIndex((tag) => src._id === tag) >= 0
      );

      const addedTags = this.confirmedScheduledOrgs;
      this.confirmedScheduledOrgs = [...addedTags, ...matchingNewTags];
      this.csvTags = '';
      this.csvErrorTags = unmatchedStr.length
        ? `Invalid Org Ids: ${unmatchedStr.join(',')}`
        : '';
    }
  }
}
