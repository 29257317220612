<div>
    <h2>Org Registration Report</h2>
    <div class="production-chart-wrapper">
        <div class="total-jobs-chart-wrapper"><canvas id="job-status"></canvas></div>
        <table mat-table [dataSource]="[{status: 'Sandbox', count: 54}, {status: 'Production', count: 78}]"
            class="mat-elevation-z8">
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef> Status </th>
                <td mat-cell *matCellDef="let element"> {{element.status}} </td>
            </ng-container>

            <ng-container matColumnDef="count">
                <th mat-header-cell *matHeaderCellDef> Count </th>
                <td mat-cell *matCellDef="let element"> {{element.count}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="['status', 'count']"></tr>
            <tr mat-row *matRowDef="let row; columns: ['status', 'count'];"></tr>
        </table>
    </div>
</div>