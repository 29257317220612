
export interface DropdownItem { label: string, value: any }

export interface WatcherList {
    data: Array<WatcherListItem>,
    pageNo: number,
    pageSize: number,
    totalPages: number
}
export interface WatcherListItem {
    defaultRecipient: boolean
    emailId: string
    id: string
    orgId: string
    recipientType: string
}

export interface SmartScheduleSetting {
    timeInDaysBeforeFinalzeSchedule: number,
    timeInDaysToSchedule: number,
    timeInDaysToSendRemainderNotification: number,
    maxOrgAllowedToSchedule: number,
    isGAAutoUpgradeEnabledForSandbox : boolean,
    isGAAutoUpgradeEnabledForProduction : boolean,
    finaliseAutoUpgradeScheduleCreation  : boolean,
    timeDiffInHoursBetweenAutoBatchCreation : number,
    timeInDaysToScheduleAutoUpgradeForSandbox : number,
    timeInDaysToScheduleAutoUpgradeForProduction :number,
    autoUpgradeDateForSandbox : Date
    autoUpgradeDateForProduction : Date
}


export interface BackgroundServiceSetting {
    chekPatchReleaseTaskIntervalInMinutes: number,
    smartScheduleEmailReminderTaskIntervalInMinutes: number,
    refreshMetadataForLatestPatchesTaskIntervalInMinutes: number,
    orgLifeCycleManagementTaskIntervalInMinutes: number,
    packageVersionMetadataRefreshTaskIntervalInMinutes: number
}

export interface SessionSetting {
    timeToExpireAccessTokenInMinutes: number,
    timeToExpireRefreshTokenInHours: number
}

export interface PackageUpgradeSettingList {
    data: Array<PackageUpgradeSettings>,
    pageNo: number,
    pageSize: number,
    totalPages: number
}

export interface PackageUpgradeSettings {
    id: string,
    subscriberPackageVersionKey: string,
    requiredPassword: boolean,
    requiredCustomInstallKey: boolean,
    customInstallKey: string
    showCustomKey?: boolean
}



export interface PackageUpgradeActionList {
    data: Array<PackageUpgradeAction>,
    pageNo: number,
    pageSize: number,
    totalPages: number
}


export interface PackageUpgradeAction {
    id: string,
    actionType: string,
    typeEnum: number,
    index: number,
    settingsName: string,
    settingsValue: boolean | string,
    executeBeforeUpgrade: boolean,
    executeAfterUpgrade: boolean,
    continuePackageUpgradeOnError: boolean,
    isActive: boolean
}

export interface ListFilter {
    searchText: string,
    pageNo: number,
    pageSize: number
}

export interface UserDetails {
    id: string,
    appName: string,
    clientId: string,
    emailId: string,
    userType: string,
    isActive: boolean,
}

export const RecipientTypeList: Array<DropdownItem> = [
    { label: 'To', value: 'To' },
    { label: 'CC', value: 'CC' },
    { label: 'BCC', value: 'BCC' },
]

export const PackageUpgradeActionSettingList: Array<DropdownItem> = [
    { label: 'enableCMSC2CConnections', value: 'enableCMSC2CConnections' },
    { label: 'enableChatterFileLink', value: 'enableChatterFileLink' },
    { label: 'enableContent', value: 'enableContent' },
    { label: 'enableContentAutoAssign', value: 'enableContentAutoAssign' },
    { label: 'enableContentDistForPortalUsers', value: 'enableContentDistForPortalUsers' },
    { label: 'enableContentDistPwOptionsBit1', value: 'enableContentDistPwOptionsBit1' },
    { label: 'enableContentDistPwOptionsBit2', value: 'enableContentDistPwOptionsBit2' },
    { label: 'enableContentDistribution', value: 'enableContentDistribution' },
    { label: 'enableContentSupportMultiLanguage', value: 'enableContentSupportMultiLanguage' },
    { label: 'enableContentWorkspaceAccess', value: 'enableContentWorkspaceAccess' },
    { label: 'enableDeleteFileInContentPacks', value: 'enableDeleteFileInContentPacks' },
    { label: 'enableFileShareSetByRecord', value: 'enableFileShareSetByRecord' },
    { label: 'enableFilesUsrShareNetRestricted', value: 'enableFilesUsrShareNetRestricted' },
    { label: 'enableJPGPreviews', value: 'enableJPGPreviews' },
    { label: 'enableLibraryManagedFiles', value: 'enableLibraryManagedFiles' },
    { label: 'enableSiteGuestUserToUploadFiles', value: 'enableSiteGuestUserToUploadFiles' },
    { label: 'enableUploadFilesOnAttachments', value: 'enableUploadFilesOnAttachments' },
    { label: 'setValidContentTypeForAtchDocDownload', value: 'setValidContentTypeForAtchDocDownload' },
    { label: 'skipContentAssetTriggers', value: 'skipContentAssetTriggers' },
]