<div class="data-container mat-elevation-z8 package-upgrade-actions">

    <div class="filter-toggle">
        <label for="filter-toggle">Display Only Active Actions</label>
        <ngl-checkbox-toggle id="filter-toggle" (change)="getPackageUpgradeActionsList()">
            <input ngl type="checkbox" [(ngModel)]="isActiveList">
        </ngl-checkbox-toggle>
    </div>

    <table #TableOneSort="matSort" mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

        <ng-container matColumnDef="actionType">
            <th mat-header-cell *matHeaderCellDef> Action Type </th>
            <td mat-cell *matCellDef="let element"> {{element.actionType}} </td>
        </ng-container>
        <ng-container matColumnDef="settingsName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Settings Name </th>
            <td mat-cell *matCellDef="let element"> {{element.settingsName}} </td>
        </ng-container>
        <ng-container matColumnDef="settingsValue">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Settings Value </th>
            <td mat-cell *matCellDef="let element"> {{element.settingsValue ? 'ON' : 'OFF'}} </td>
        </ng-container>
        <ng-container matColumnDef="executeBeforeUpgrade">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Execute Before Upgrade </th>
            <td mat-cell *matCellDef="let element"> {{element.executeBeforeUpgrade ? 'Yes' : 'No'}} </td>
        </ng-container>
        <ng-container matColumnDef="executeAfterUpgrade">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Execute After Upgrade </th>
            <td mat-cell *matCellDef="let element"> {{element.executeAfterUpgrade ? 'Yes' : 'No'}} </td>
        </ng-container>
        <ng-container matColumnDef="continuePackageUpgradeOnError">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Continue Package Upgrade On Error </th>
            <td mat-cell *matCellDef="let element"> {{element.continuePackageUpgradeOnError ? 'Yes' : 'No'}} </td>
        </ng-container>


        <ng-container matColumnDef="isActive">
            <th mat-header-cell *matHeaderCellDef>
                <span *readOnlyBtn>
                    Action
                </span>
            </th>
            <td mat-cell *matCellDef="let element">
                <div class="flex">
                    <button *readOnlyBtn class="slds-button slds-icon_container slds-icon-utility-info"
                        [nglTooltip]="'Update'" nglTooltipInteractive="true" nglTooltipDelay="100" nglTooltipOpenAuto
                        (click)="editPackageUpgradeActions(element)">
                        <svg class="slds-button__icon" aria-hidden="true">
                            <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#edit">
                            </use>
                        </svg>
                    </button>
                    <ngl-checkbox-toggle (change)="toggleActionStatus($event, element)">
                        <input ngl type="checkbox" [(ngModel)]="element.isActive">
                    </ngl-checkbox-toggle>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="8" style="text-align: center;">
                <span>No results found</span>
            </td>
        </tr>
    </table>

    <mat-paginator [length]="length" [pageIndex]="pageIndex" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
        [showFirstLastButtons]="true" (page)="handlePageEvent($event)">
    </mat-paginator>

</div>

<div class="loading_spinner" *ngIf="isLoading">
    <ngl-spinner size="large" variant="brand" alternativeText="Loading"></ngl-spinner>
</div>

<ngl-modal [header]="editActions ? 'Update Package Upgrade Actions' : 'Create New Package Upgrade Actions'"
    [(open)]="showActionsModal" size="large">
    <div class="slds-p-around_medium">
        <div>
            <table class="table borderless">
                <tbody class="package-upgrade-action-form">
                    <tr>
                        <td><span class="flex title">Action Type</span></td>
                        <td><input ngl type="text" disabled [(ngModel)]="editorData.actionType"></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td><span class="flex title">Setting</span></td>
                        <td>
                            <ngl-combobox [(open)]="settionsOpen" [options]="settingList"
                                [(selection)]="editorData.settingsName">
                                <input nglCombobox>
                            </ngl-combobox>
                            <span *ngIf="isSubmitted && !editorData.settingsName" class="slds-text-color_error">Setting
                                is
                                required</span>
                        </td>
                        <td>
                            <ngl-checkbox-toggle label="Value">
                                <input ngl type="checkbox" [(ngModel)]="editorData.settingsValue">
                            </ngl-checkbox-toggle>
                        </td>
                    </tr>


                    <tr>
                        <td>
                            <ngl-checkbox-toggle label="Execute Before Upgrade">
                                <input ngl type="checkbox" [(ngModel)]="editorData.executeBeforeUpgrade">
                            </ngl-checkbox-toggle>
                        </td>
                        <td>
                            <ngl-checkbox-toggle label="Execute After Upgrade">
                                <input ngl type="checkbox" [(ngModel)]="editorData.executeAfterUpgrade">
                            </ngl-checkbox-toggle>
                        </td>
                        <td>
                            <ngl-checkbox-toggle label="Continue Package Upgrade on Error">
                                <input ngl type="checkbox" [(ngModel)]="editorData.continuePackageUpgradeOnError">
                            </ngl-checkbox-toggle>
                        </td>
                    </tr>
                </tbody>
            </table>
            <br>
        </div>
    </div>
    <ng-template nglModalFooter>
        <button class="slds-button slds-button_neutral"
            (click)="showActionsModal = false; editActions = false;">Cancel</button>
        <button class="slds-button slds-button_brand" (click)="onSubmitPackageUpgradeActions()">{{editActions ?
            'Update' :
            'Submit'}}</button>
    </ng-template>
</ngl-modal>