import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from '../../shared/auth.service';
import { DropdownItem, RecipientTypeList, WatcherList, WatcherListItem } from '../../interface/pu-admin';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';

@Component({
  selector: 'app-watcher',
  templateUrl: './watcher.component.html',
  styleUrls: ['./watcher.component.css']
})
export class WatcherComponent implements OnInit {


  displayedColumns: string[] = ['emailId', 'orgId', 'recipientType', 'defaultRecipient', 'action'];
  emailPattern = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
  dataSource = new MatTableDataSource<WatcherListItem>();
  isLoading: boolean = false;
  showEmailModal: boolean = false;
  editEmail: boolean = false;
  defaultWatcherData: WatcherListItem = {
    defaultRecipient: false,
    emailId: "",
    id: "",
    orgId: "",
    recipientType: "",
  };
  editorData: WatcherListItem = this.defaultWatcherData;
  openDeleteConfirmationModel = false;
  deleteData: WatcherListItem = null;
  recipientTypeOptions: Array<DropdownItem> = RecipientTypeList;
  orgIdDropdownInputCtrl: FormControl = new FormControl();
  orgList: Array<any> = [];
  filteredOrgIdList$: Observable<any[]>;
  orgIdOpen: boolean = false;
  recipientTypeOpen: boolean = false;
  @ViewChild('TableOneSort', { static: false }) tableOneSort: MatSort;

  length: number = 0;
  pageSize: number = 10;
  pageIndex: number = 0;
  pageSizeOptions: number[] = [10, 25, 50, 75, 100];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  emails: string[] = [];
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  emailsVisible = true;
  emailsSelectable = true;
  emailsRemovable = true;
  emailsAddOnBlur = true;

  isSubmitted: boolean = false;
  @Input() searchText :FormControl;

  constructor(private authservice: AuthService) { }

  ngOnInit(): void {
    this.getRegisteredOrgList();
    this.orgIdDropdownInputCtrl.reset();
    this.orgIdDropdownInputCtrl.valueChanges.pipe(
      startWith(''))
      .subscribe((val) => {
        this.filteredOrgIdList$ = !val ? of(this.orgList) : of(this.filterReleaseDropdownList(val))
      });
  }

  filterReleaseDropdownList(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.orgList.filter(org => org.label.toLowerCase().indexOf(filterValue) > -1);
  }


  public getUpgradeWatcherList(searchText: string = "") {
    searchText = this.searchText.value
    // this.checkboxselection.clear()
    this.isLoading = true;
    this.authservice.getAllWatcher({
      searchText,
      pageNo: this.pageIndex + 1,
      pageSize: this.pageSize
    }).subscribe((response: WatcherList) => {
      this.dataSource = new MatTableDataSource<WatcherListItem>(response.data);
      this.length = this.pageSize * response.totalPages;
      this.dataSource.sort = this.tableOneSort;
      this.dataSource.sortingDataAccessor = (data, header) => this.authservice.isEmpty(data[header]) ? "" : Array.isArray(data[header]) ? data[header].join() : data[header].toString().toLowerCase();
      this.isLoading = false;
    }, error => {
      console.log(error)
    });
  }

  public onCreate() {
    this.editEmail = false;
    this.emails = [];
    this.isSubmitted = false;
    this.showEmailModal = true;
    this.toggleOrgId(this.defaultWatcherData.defaultRecipient);   
    this.editorData = JSON.parse(JSON.stringify(this.defaultWatcherData));
  }

  editWatcher(item: WatcherListItem) {
    this.emails = [];
    this.isSubmitted = false;
    this.editEmail = this.showEmailModal = true;
    this.toggleOrgId(item.defaultRecipient);   
    this.editorData = JSON.parse(JSON.stringify(item));
  }

  getRegisteredOrgList() {
    this.authservice.getAllRegisteredOrgList().toPromise().then(list => {
      this.orgList = list.map(item => {
        return {
          label: `${item.name} -- ${item.orgId}`,
          value: item.orgId
        }
      });
      this.filteredOrgIdList$ = of(this.orgList);
    })
  }

  onSubmitWatcher() {
    this.isSubmitted = true;
    let apiCall = null;
    let hasError = (!this.editorData.defaultRecipient && !this.editorData.orgId) || !this.editorData.recipientType;
    if (this.editEmail) {
      hasError = hasError || !this.editorData.emailId || !this.emailPattern.test(this.editorData.emailId);
      apiCall = this.authservice.submitWatcher(this.editorData)
    } else {
      let watcherList: Array<WatcherListItem> = [];
      this.emails.forEach((emailId) => {
        watcherList.push({ ...this.editorData, emailId })
      })
      hasError = hasError || !watcherList.length;
      apiCall = this.authservice.submitMultipleWatcher(watcherList)
    }
    if (!hasError) {
      this.isLoading = true;
      apiCall.subscribe(() => {
        this.editEmail = false;
        this.showEmailModal = false;
        this.getUpgradeWatcherList();
        this.isSubmitted = false;
        this.isLoading = false;
      }, (err) => {
        this.isLoading = false;
        console.error({ err });
      })
    }
  }

  confirmDeleteWatcher(item: WatcherListItem) {
    this.openDeleteConfirmationModel = true;
    this.deleteData = item;
  }

  deleteWatcher() {
    this.isLoading = true;
    this.authservice.DeleteByWatcherId(this.deleteData.id).toPromise().finally(() => {
      this.isLoading = false;
      this.openDeleteConfirmationModel = false;
      this.getUpgradeWatcherList();
      this.deleteData = null;
    })
  }


  handlePageEvent(event: PageEvent) {
    this.pageIndex = event.pageIndex;
    if (event.pageSize != this.pageSize) {
      this.pageSize = event.pageSize;
      this.pageIndex = 0;
    }

    this.getUpgradeWatcherList();
  }


  addEmail(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    if (value && this.emails.indexOf(value) == -1 && this.emailPattern.test(value)) {
      this.emails.push(value);
    }

    // Clear the input value
    event.input.value = "";
  }

  removeEmail(email: string): void {
    const index = this.emails.indexOf(email);

    if (index >= 0) {
      this.emails.splice(index, 1);
    }
  }

  selectionValue(event) {
    if (event) {
      let val = this.orgList.find(org => org.value == event);
      return val;
    }
    return event;
  }

  toggleOrgId(e) {
    if (e) {
      this.editorData.orgId = "";
      this.orgIdDropdownInputCtrl.setValue('');
      this.orgIdDropdownInputCtrl.disable();
    } else {
      this.orgIdDropdownInputCtrl.setValue(this.editorData.orgId);
      this.orgIdDropdownInputCtrl.enable();
    }
  }
}
