import { Component, OnInit } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { FormControl } from '@angular/forms';
import { forkJoin, Observable, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DropdownItem } from '../interface/pu-admin';
import { AuthService } from '../shared/auth.service';
import { CommonService } from '../shared/common.service';
import { OrgSummary, JobSummary } from './../interface/upgrade-summary';

@Component({
  selector: 'app-upgrade-summary',
  templateUrl: './upgrade-summary.component.html',
  styleUrls: ['./upgrade-summary.component.css']
})
export class UpgradeSummaryComponent implements OnInit {

  isLoading: boolean = false
  tabs = {
    orgReport: 'org-registration-report',
    jobReport: 'upgraded-job-report'
  }
  activeTab = this.tabs.orgReport;
  selectedTab = this.tabs.orgReport;


  isFilterTypeOpen: boolean = false;
  filterType: string = "all";
  filterTypeList: Array<{ label: string, value: string }> = [
    { label: 'All', value: null },
    { label: 'Production', value: 'Production' },
    { label: 'Sandbox', value: 'Sandbox' },
  ];
  today: Date = new Date();
  lastYearDate: Date = new Date(new Date().setFullYear(new Date().getFullYear() - 1));
  filterFromDate: FormControl = new FormControl(this.lastYearDate, {
    validators: [
      (control: FormControl) => {
        if (!(control.value instanceof Date)) {
          return null;
        }
        return null;
      }]
  });

  filterToDate: FormControl = new FormControl(this.today, {
    validators: [
      (control: FormControl) => {
        if (!(control.value instanceof Date)) {
          return null;
        }
        const date: Date = control.value;
        return date.getTime() < new Date(this.filterFromDate.value).getTime() ? { inValid: true } : null;
      }]
  });

  orgReportFilter = {
    open: false,
    formData: {
      hasDateRange: false,
      dateRange: {
        fromDate: '',
        toDate: '',
      },
      reportFor: {
        open: false,
        value: ''
      },
    }
  }

  jobReportFilter = {
    open: false,
    formData: {
      hasDateRange: false,
      dateRange: {
        fromDate: '',
        toDate: '',
      },
      reportFor: {
        open: false,
        value: ''
      },
      orgId: {
        open: false,
        value: ''
      },
      releaseId: {
        open: false,
        value: ''
      },
      jobId: {
        open: false,
        value: ''
      },
    }
  }

  orgListControl = new FormControl('');
  orgList!: Array<any>;
  filteredOrgList!: Array<any>;
  filteredOrgList$!: Observable<Array<DropdownItem>>;

  releaseListControl = new FormControl('');
  releaseList!: Array<DropdownItem>;
  filteredReleaseList$!: Observable<Array<DropdownItem>>;

  orgSummary!: OrgSummary;
  jobSummary!: JobSummary;


  constructor(private authService: AuthService, private commonService: CommonService) { }

  ngOnInit(): void {
    this.isLoading = true;
    forkJoin({
      orgSummary: this.authService.getOrgRegistrationSummaryReport(),
      jobSummary: this.authService.getUpgradeJobSummaryReport()
    }).subscribe(({ orgSummary, jobSummary }) => {
      this.isLoading = false;
      this.orgSummary = orgSummary;
      this.jobSummary = jobSummary;
    }, (() => {
      this.isLoading = false;
    }))

    forkJoin({
      allRegisteredOrgList: this.authService.getAllRegisteredOrgList(),
      allReleaseInfo: this.authService.getAllReleaseInfo()
    }).subscribe(({ allRegisteredOrgList, allReleaseInfo }) => {
      if (allRegisteredOrgList) {
        this.orgList = this.filteredOrgList = allRegisteredOrgList;
        this.orgListControl.reset();
        this.filteredOrgList$ = of(this.filteredOrgList.map(item => ({ label: item.name, value: item.orgId })));
        this.orgListControl.valueChanges.subscribe(val => {
          if (val) {
            this.filteredOrgList$ = of(this.filteredOrgList.map(item => ({ label: item.name, value: item.orgId })).filter(release => release.label.toLowerCase().indexOf(val.toLowerCase()) > -1));
          } else {
            this.filteredOrgList$ = of(this.filteredOrgList.map(item => ({ label: item.name, value: item.orgId })));
          }
        });
      }
      if (allReleaseInfo) {
        this.releaseList = allReleaseInfo.map(item => ({ label: item.name, value: item.releaseVersionString }));
        this.releaseListControl.reset();
        this.filteredReleaseList$ = this.releaseListControl.valueChanges
          .pipe(
            startWith(''),
            map(val => !val ? [...this.releaseList] : this.releaseList.filter(release => release.label.toLowerCase().indexOf(val.toLowerCase()) > -1)
            )
          );
      }
    });
  }


  tabChange(tabName) {
    this.resetFilter();
    this.activeTab = tabName;
  }

  resetFilter() {
    this.filterFromDate.setValue(this.lastYearDate);
    this.filterToDate.setValue(this.today);
    this.filterType = "all";
  }

  openFilter() {
    switch (this.activeTab) {
      case this.tabs.orgReport:
        this.orgReportFilter = { open: true, formData: { ...this.orgReportFilter.formData, dateRange: { fromDate: this.filterFromDate.value, toDate: this.filterToDate.value } } };
        break;
      case this.tabs.jobReport:
        this.jobReportFilter = { open: true, formData: { ...this.jobReportFilter.formData, dateRange: { fromDate: this.filterFromDate.value, toDate: this.filterToDate.value } } };
        break;
      default:
        break;
    }
  }

  filterOrgList(orgType) {
    this.filteredOrgList = orgType ? this.orgList.filter(release => release.type.indexOf(orgType)) : this.orgList;
    if (this.filteredOrgList && this.filteredOrgList.findIndex(item => item.orgId === this.jobReportFilter.formData.orgId.value) == -1) {
      this.jobReportFilter.formData.orgId.value = "";
    }
    this.filteredOrgList$ = of(this.filteredOrgList.map(item => ({ label: item.name, value: item.orgId })));
  }

  clearFirstPartOfJobFilter() {
    if (this.jobReportFilter.formData.jobId.value) {
      this.jobReportFilter.formData.reportFor.value = "";
      this.jobReportFilter.formData.orgId.value = "";
      this.jobReportFilter.formData.releaseId.value = "";
    }
  }
  onOrgReportFilterApplied() {
    this.isLoading = true;

    let zone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.authService.getAllRegisteredOrgListReport(zone, this.orgReportFilter.formData.reportFor.value === 'Sandbox').subscribe((res: HttpResponse<Blob>) => {
      this.commonService.downloadFileFromAPIRes(res);
      this.orgReportFilter.open = false;
      this.isLoading = false;
    }, err => {
      this.orgReportFilter.open = false;
      this.isLoading = false;
      console.error(err);
    });
  }

  onJobReportFilterApplied() {
    this.isLoading = true;
    let zone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.authService.getAllUpgradeJobListReport(
      this.jobReportFilter.formData.orgId.value,
      this.jobReportFilter.formData.jobId.value, zone,
      this.jobReportFilter.formData.reportFor.value === 'Sandbox').subscribe((res: HttpResponse<Blob>) => {
        this.commonService.downloadFileFromAPIRes(res);
        this.jobReportFilter.open = false;
      }, err => {
        this.jobReportFilter.open = false;
        console.error(err);
      });
  }
}
