import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../app-config.service';
import { AuthService } from '../shared/auth.service';
import { CustomerReleaseDetail } from '../interface/customer-release-detail'
import { forkJoin } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { PackageInformation } from '../interface/package-information';
import { PackageStatus } from '../interface/package-status';

@Component({
  selector: 'app-push-upgrade-release-detail',
  templateUrl: './push-upgrade-release-detail.component.html',
  styleUrls: ['./push-upgrade-release-detail.component.css']
})



export class PushUpgradeReleaseDetailComponent implements OnInit {
  org_id: string;
  displayPage: boolean;
  release_details: CustomerReleaseDetail;
  isVanillaOrg: boolean;

  packageDisplayedColumns: string[] = ['packageName', 'installedVersion', 'installedReleaseName', 'toBeInstalledVersion'];
  packageDataSource = new MatTableDataSource<PackageInformation>();

  constructor(private route: ActivatedRoute, private http: HttpClient, private appConfigService: AppConfigService, private authservice: AuthService) { }

  ngOnInit(): void {

    // Check if Org ID is in local storage and if present redirect to registration ui page with
    if (this.authservice.isEmpty(this.authservice.getOrgId())) {
      this.authservice.doLogout();
      this.authservice.redirectToLogin();
    }
    else {
      let releaseDetails = this.authservice.getReleaseDetails(this.authservice.getOrgId());
      forkJoin([releaseDetails]).subscribe(response => {
        this.release_details = response[0];
        if (this.authservice.isEmpty(this.release_details.products))
          this.isVanillaOrg = true;
        this.displayPage = true;
      });
    }
  }

  // check if the iput string has is null or empty then return "-".
  checkForNullorEmpty(item: string) {
    if (this.authservice.isEmpty(item))
      return "-"
    else
      return item
  }

  // Display "New" beside those packages which are being tagged and newly created for the latest release.
  checkIsPacakageNew(packageName: string, status: PackageStatus) {
    if (status.IsDepenentPackage)
      packageName = packageName + '*'

    if (status.ParticipatingPackage)
      packageName = packageName + " (New)";

    return packageName
  }

  // Add Patch Name along with release Info.
  addPatchNameToVersionName(versionName: string, patchName: boolean) {
    if (this.authservice.isEmpty(patchName))
      return this.checkForNullorEmpty(versionName);
    else
      return versionName + " - " + patchName;
  }
}

