<app-admin-navigation-panel></app-admin-navigation-panel>

<!-- Scheduler Dashboard to view Schedule settings and Update/Cancel Schedule -->

<div class="main"><br><br>
    <ngl-tabset [(selected)]='selectedTab' variant='scoped'>
        <ng-template ngl-tab label="Sandbox" (activate)="tabChange('sandbox')">
            <div class="slds-page-header slds-page-header_record-home">
                <div class="slds-page-header__row">
                    <div class="slds-page-header__col-title">
                        <div class="slds-media">
                            <div class="slds-media__figure">
                                <span class="slds-icon_container slds-icon-standard-opportunity">
                                    <svg class="slds-icon slds-page-header__icon" aria-hidden="true">
                                        <use xlink:href="/assets/icons/standard-sprite/svg/symbols.svg#opportunity">
                                        </use>
                                    </svg>
                                </span>
                            </div>
                            <div class="slds-media__body">
                                <div class="slds-page-header__name">
                                    <div class="slds-page-header__name-title">
                                        <h1>
                                            <span class="slds-page-header__title slds-truncate"
                                                title="Scheduled Sandbox Org Information">Scheduled Sandbox Org
                                                Information</span>
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="slds-page-header__col-actions">
                        <div class="slds-page-header__controls">
                            <div class="slds-page-header__control">
                                <button *readOnlyBtn type="button" nglButton variant="brand"
                                    (click)="openScheduleUpgradeModal()" aria-live="assertive">
                                    <span class="slds-text-not-selected">Create Schedule</span>
                                </button>
                                <button *readOnlyBtn type="button" nglButton variant="destructive"
                                    (click)="openScheduleCancelConfirmationModal()" aria-live="assertive"
                                    [disabled]="disableBulkUpgradeBtn">
                                    <span class="slds-text-not-selected">Cancel Schedule</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template #heading>Production</ng-template>
        <ng-template id="production" ngl-tab #summaryTab="nglTab" [label]="heading"
            (activate)="tabChange('production')">
            <div class="slds-page-header slds-page-header_record-home">
                <div class="slds-page-header__row">
                    <div class="slds-page-header__col-title">
                        <div class="slds-media">
                            <div class="slds-media__figure">
                                <span class="slds-icon_container slds-icon-standard-opportunity">
                                    <svg class="slds-icon slds-page-header__icon" aria-hidden="true">
                                        <use xlink:href="/assets/icons/standard-sprite/svg/symbols.svg#opportunity">
                                        </use>
                                    </svg>
                                </span>
                            </div>
                            <div class="slds-media__body">
                                <div class="slds-page-header__name">
                                    <div class="slds-page-header__name-title">
                                        <h1>
                                            <span class="slds-page-header__title slds-truncate"
                                                title="Scheduled Production Org Information">Scheduled Production Org
                                                Information</span>
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="slds-page-header__col-actions">
                        <div class="slds-page-header__controls">
                            <div class="slds-page-header__control">
                                <button *readOnlyBtn type="button" nglButton variant="brand"
                                    (click)="openScheduleUpgradeModal()" aria-live="assertive">
                                    <span class="slds-text-not-selected">Create Schedule</span>
                                </button>
                                <button *readOnlyBtn type="button" nglButton variant="destructive"
                                    (click)="openScheduleCancelConfirmationModal()" aria-live="assertive"
                                    [disabled]="disableBulkUpgradeBtn">
                                    <span class="slds-text-not-selected">Cancel Schedule</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </ngl-tabset>

    <br>

    <div class="slds-page-header">
        <div class="data-body">
            <div class="slds-page-header">
                <mat-form-field>
                    <mat-label>Filter</mat-label>
                    <input matInput (keyup)="applyFilter($event)" placeholder="Search Text" #input>
                </mat-form-field>
                <div class="data-container mat-elevation-z8">
                    <table #TableOneSort="matSort" mat-table matSort [dataSource]="dataSource" multiTemplateDataRows
                        class="mat-elevation-z8">

                        <!-- Checkbox Column -->
                        <ng-container matColumnDef="select">
                            <th mat-header-cell *matHeaderCellDef>
                                <mat-checkbox *readOnlyBtn color="primary" (change)="$event ? masterToggle() : null"
                                    [checked]="checkboxselection.hasValue() && isAllSelected()"
                                    [disabled]="disableMasterCheckbox()"
                                    [indeterminate]="checkboxselection.hasValue() && !isAllSelected()"
                                    [aria-label]="checkboxLabel()">
                                </mat-checkbox>
                            </th>
                            <td mat-cell *matCellDef="let row">
                                <mat-checkbox *readOnlyBtn color="primary" (click)="$event.stopPropagation()"
                                    (change)="$event ? checkboxselection.toggle(row) : null"
                                    [checked]="checkboxselection.isSelected(row)"
                                    [disabled]="this.isDisabled(row.status)" [aria-label]="checkboxLabel(row)">
                                </mat-checkbox>
                            </td>
                        </ng-container>

                        <!-- Org ID Column -->
                        <ng-container matColumnDef="id">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Schedule Id </th>
                            <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                        </ng-container>

                        <!-- Org Name Column -->
                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Schedule Name </th>
                            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                        </ng-container>

                        <!-- Release Name Column -->
                        <ng-container matColumnDef="releaseDisplayName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Release</th>
                            <td mat-cell *matCellDef="let element"> {{this.getScheduleReleaseName(element)}} </td>
                        </ng-container>

                        <!-- Schedule Date/Time Column -->
                        <ng-container matColumnDef="scheduledDate">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Schedule <br> ({{timeZone}})</th>
                            <td mat-cell *matCellDef="let element"> {{element.scheduledDateStr}} </td>
                        </ng-container>

                        <!-- Schedule Tags Column -->
                        <ng-container matColumnDef="tags">
                            <th mat-header-cell *matHeaderCellDef> Schedule Tags </th>
                            <td class="mat-chip-tag" mat-cell *matCellDef="let element">
                                <ngl-pill *ngFor="let tag of  element.tags">
                                    {{tag}}
                                </ngl-pill>
                            </td>
                        </ng-container>

                        <!-- Schedule Status Column -->
                        <ng-container matColumnDef="status">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                            <td mat-cell *matCellDef="let element">
                                <div class="tags">{{element.status}}</div>
                            </td>
                        </ng-container>


                        <!-- Action Column -->
                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef>
                                <span *readOnlyBtn>Action</span>
                            </th>
                            <td mat-cell *matCellDef="let element">
                                <div class="flex" *readOnlyBtn>
                                    <button class="slds-button slds-icon_container slds-icon-utility-info"
                                        [nglTooltip]="this.getFinalizeBtnToolTip(element)" nglTooltipInteractive="true"
                                        nglTooltipDelay="100" nglTooltipOpenAuto
                                        [disabled]="this.finaliseIsDisabled(element)"
                                        (click)="finalizeSingleSchedule(element)">
                                        <svg class="slds-button__icon" aria-hidden="true">
                                            <use xlink:href="/assets/icons/action-sprite/svg/symbols.svg#freeze_user">
                                            </use>
                                        </svg>
                                    </button>
                                    <button class="slds-button slds-icon_container slds-icon-utility-info"
                                        [nglTooltip]="'Update'" nglTooltipInteractive="true" nglTooltipDelay="100"
                                        nglTooltipOpenAuto [disabled]="this.updateIsDisabled(element.status)"
                                        (click)="updateSingleSchedule(element)">
                                        <svg class="slds-button__icon" aria-hidden="true">
                                            <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#edit">
                                            </use>
                                        </svg>
                                    </button>
                                    <button class="slds-button slds-icon_container slds-icon-utility-info"
                                        [nglTooltip]="'Cancel'" nglTooltipInteractive="true" nglTooltipDelay="100"
                                        nglTooltipOpenAuto [disabled]="this.isDisabled(element.status)"
                                        (click)="removeSingleSchedule(element)">
                                        <svg class="slds-button__icon" aria-hidden="true">
                                            <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#close">
                                            </use>
                                        </svg>
                                    </button>
                                </div>
                            </td>
                        </ng-container>

                        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                        <ng-container matColumnDef="expandedDetail">
                            <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
                                <div class="example-element-detail"
                                    [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">

                                    <table class="table table-hover table-striped">
                                        <thead>
                                            <tr class="table-secondary">
                                                <th scope="col">Sl No</th>
                                                <th scope="col">Org Id</th>
                                                <th scope="col">Org Name</th>
                                                <th scope="col">Smart Tags</th>
                                                <th scope="col">Job Id</th>
                                                <th scope="col">Job Status</th>
                                                <th *ngIf="checkIsActionVisible(element.status)" scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            <div
                                                *ngIf="this.commonService.isEmpty(element.organizations) || this.commonService.isEmpty(element.organizations[0].orgId); then thenBlock else elseBlock">
                                            </div>
                                            <ng-template #elseBlock>
                                                <tr *ngFor="let row of element.organizations ;index as i">
                                                    <th class="sl-no" scope="row">{{i+1}} </th>
                                                    <td>{{this.commonService.isEmpty(row.orgId) ? null :
                                                        this.commonService.removeOrgIdCheksum(row.orgId)}}
                                                    </td>
                                                    <td>{{row.orgName}}</td>
                                                    <td class="org-tags-lst">
                                                        <ngl-pill *ngFor="let tag of  row.smartTags">
                                                            {{tag}}
                                                        </ngl-pill>
                                                    </td>
                                                    <td><span class="jobIDCol"
                                                            (click)="openJObInfoInNewTab(row.jobId)">{{row.jobId}}</span>
                                                    </td>
                                                    <td>{{row.jobStatus}}</td>
                                                    <td *ngIf="checkIsActionVisible(element.status)">
                                                        <span *readOnlyBtn
                                                            (click)="openRemoveOrgFromSchedulelConfirmationModal(element.id,row)"
                                                            title="Remove Org from Schedule">
                                                            <ngl-icon iconName='utility:delete' size="small"></ngl-icon>
                                                        </span>
                                                    </td>
                                                </tr>
                                            </ng-template>
                                            <ng-template #thenBlock>
                                                <tr>
                                                    <td colspan="6">No Orgs scheduled</td>
                                                </tr>
                                            </ng-template>


                                        </tbody>
                                    </table>
                                </div>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                        <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row"
                            [class.example-expanded-row]="expandedElement === element"
                            [ngClass]="{'rowCancel': element.status === 'Cancelled'}"
                            (click)="expandedElement = expandedElement === element ? null : element">
                        </tr>
                        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>

                        <!-- Row shown when there is no matching data. -->
                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" colspan="8" style="text-align: center;">No results found</td>
                        </tr>
                    </table>

                </div>
                <mat-paginator [length]="length" [pageIndex]="pageIndex" [pageSize]="pageSize"
                    [pageSizeOptions]="pageSizeOptions" [showFirstLastButtons]="true" (page)="handlePageEvent($event)">
                </mat-paginator>
            </div>
        </div>
    </div>

    <!-- Loading Icon while making backend call -->
    <div class="loading_spinner" *ngIf="isLoading">
        <ngl-spinner size="large" variant="brand" alternativeText="Loading"></ngl-spinner>
    </div>
</div>

<div class="slds-notify_container">
    <ngl-toast iconName="utility:info" class="toast-info" *ngIf="showTopToast" [(variant)]="toastVariant"
        (close)="onClose($event); showTopToast = false;" duration="3000">
        <h3 class="slds-text-heading_small">{{toastMsg}}</h3>
    </ngl-toast>
</div>

<!-- Modal Popup for confirmation to remove org from the Schedule -->
<ngl-modal header="Remove Org from Schedule" [(open)]="openConfirmationModal">
    <div class="slds-p-around_medium">
        <p>Are you sure to remove the selected Org {{this.customerPreferenceOrg.orgId}} from the schedule</p>
    </div>
    <ng-template nglModalFooter>
        <button class="slds-button slds-button_neutral"
            (click)="closeRemoveOrgFromSchedulelConfirmationModal()">Cancel</button>
        <button class="slds-button slds-button_brand" (click)="removeOrgFromSchedule()">Ok</button>
    </ng-template>
</ngl-modal>

<!-- Modal Popup for confirmation to cancel the Schedule -->
<ngl-modal header="Cancel Schedule(s)" [(open)]="openConfirmationModaltoCancel">
    <div class="slds-p-around_medium">
        <p>Are you sure to Cancel the selected schedule(s)</p>
    </div>
    <ng-template nglModalFooter>
        <button class="slds-button slds-button_neutral" (click)="closeScheduleCancelConfirmationModal()">Cancel</button>
        <button class="slds-button slds-button_brand" (click)="cancelSelectedSchedules()">Ok</button>
    </ng-template>
</ngl-modal>

<!-- Modal Popup for confirmation to finalise the Schedule -->
<ngl-modal header="Finalize Schedule(s)" [(open)]="openConfirmationModaltoFialise">
    <div class="slds-p-around_medium">
        <p>Are you sure to finalize the selected schedule(s)</p>
    </div>
    <ng-template nglModalFooter>
        <button class="slds-button slds-button_neutral"
            (click)="closeScheduleFinaliseConfirmationModal()">Cancel</button>
        <button class="slds-button slds-button_brand" (click)="finalizeSelectedSchedules()">Ok</button>
    </ng-template>
</ngl-modal>

<!-- <app-push-upgrade-schedule-modal></app-push-upgrade-schedule-modal> -->
<ngl-modal [(header)]="this.scheduleUpgradeModalHeader" [(open)]="showScheduleUpgradeModal" size="large">
    <div class="slds-p-around_medium">
        <div>
            <table class="table borderless">
                <tbody>
                    <tr>

                        <td>Schedule Name</td>
                        <td>
                            <ngl-input>
                                <input ngl [(ngModel)]="this.createRescheduleUpgradeScheduleRequest.scheduleName"
                                    placeholder="Name">
                            </ngl-input>
                        </td>


                        <td>Schedule Date</td>
                        <td>
                            <ngl-pill class="schedule-date-text">
                                {{this.authservice.dateFormatter(this.createRescheduleUpgradeScheduleRequest.scheduleDate.toString())}}
                            </ngl-pill>
                        </td>
                    </tr>
                    <tr>
                        <td>Target Release<span class="inptRequired"> * </span>
                            <span class="slds-icon_container slds-icon-utility-info">
                                <div [nglTooltip]="interactive" nglTooltipInteractive="true" nglTooltipDelay="100"
                                    nglTooltipOpenAuto>
                                    <ngl-icon iconName='action:info' size="xx-small"></ngl-icon>
                                    <ng-template #interactive>Release can be selected only at the time of schedule
                                        creation</ng-template>
                                </div>
                            </span>
                        </td>
                        <td>
                            <ng-container
                                *ngIf="this.authservice.isEmpty(this.createRescheduleUpgradeScheduleRequest.id); else readOnly">
                                <ngl-combobox [options]="filteredReleaseDropdown$ | async" [(open)]="open"
                                    [(selection)]="this.createRescheduleUpgradeScheduleRequest.releaseConfigId"
                                    variant="lookup" (selectionChange)="onReleaseSelection($event)">
                                    <input nglCombobox placeholder="Select an Option"
                                        [formControl]="releaseDropdownInputCtrl">
                                </ngl-combobox>
                            </ng-container>
                            <ng-template #readOnly>
                                <input ngl disabled
                                    [ngModel]="this.getReleaseName(this.createRescheduleUpgradeScheduleRequest.releaseConfigId)"
                                    placeholder="Target Release">
                            </ng-template>
                        </td>


                        <td>Description</td>
                        <td>
                            <ngl-textarea>
                                <textarea ngl [(ngModel)]="this.createRescheduleUpgradeScheduleRequest.description"
                                    placeholder="Description"></textarea>
                            </ngl-textarea>
                        </td>
                    </tr>
                    <tr>
                        <td>Date</td>
                        <td>
                            <ngl-datepicker-input [(ngModel)]="this.scheduleDate" #dt="ngModel"
                                [class.slds-has-error]="!dt.valid" [showToday]="true" [min]="minScheduleDate"
                                [cleave]="cleaveOptions" (valueChange)="scheduleDateChange($event)">
                                <input nglDatepickerInput type="text" readonly>
                            </ngl-datepicker-input>
                        </td>
                        <td>Time</td>
                        <td>
                            <ngl-combobox [(open)]="scheduleTimeOpen" [options]="scheduleTimeOptions"
                                [(selection)]="scheduleTimeSelection" (selectionChange)="scheduleTimeChange($event)">
                                <input nglCombobox>
                            </ngl-combobox>
                        </td>
                    </tr>
                    <tr>
                        <td>Tags</td>
                        <td colspan="3">
                            <form-field class="tag-chip-list">
                                <mat-chip-list #chipList aria-label="Tags">
                                    <mat-chip *ngFor="let tag of this.createRescheduleUpgradeScheduleRequest.tags"
                                        [selectable]="tagsSelectable" [removable]="tagsRemovable"
                                        (removed)="removeTag(tag)">
                                        {{tag}}
                                        <mat-icon matChipRemove>cancel</mat-icon>
                                    </mat-chip>
                                    <input placeholder="New Tag..." [matChipInputFor]="chipList"
                                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                        [matChipInputAddOnBlur]="tagsAddOnBlur" (matChipInputTokenEnd)="addTag($event)">
                                </mat-chip-list>
                            </form-field>
                        </td>
                    </tr>
                    <tr>
                        <td>Smart Tag Filter</td>
                        <td colspan="3">
                            <ngl-combobox multiple [options]="this.smartTagList" [(open)]="openSmartTag"
                                [(selection)]="smartTagSelection" (selectionChange)="onSmartTagSelection()">
                                <input nglCombobox placeholder="Select smart tags to filter the Orgs">
                            </ngl-combobox>
                            <div class="slds-m-top_xx-small" *ngIf="this.smartTagList">
                                <ng-container *ngFor="let smartTagId of smartTagSelection">
                                    <ngl-pill class="smartTag-pill" (remove)="remove(smartTagId)">{{
                                        this.getSmartTagNameById(smartTagId) }}</ngl-pill>
                                </ng-container>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <br>
            <h3>Select Orgs(s) for Scheduling</h3>

            <dual-list [sort]="keepSorted" [source]="this.sourceScheduledOrgs" [key]="key" [display]="display"
                [filter]="filter" [(destination)]="this.confirmedScheduledOrgs" height="265px" [format]="format"
                [disabled]="disabled" [maxSelect]="this.smartScheduleSettings.maxOrgAllowedToSchedule"></dual-list>
        </div>
    </div>
    <ng-template nglModalFooter>
        <button class="slds-button slds-button_neutral" (click)="closeScheduleUpgradeModal()">Cancel</button>
        <button class="slds-button slds-button_brand"
            (click)="saveUpgradeSchedule()">{{this.scheduleUpgradeModalBtnLabel}}</button>
    </ng-template>
</ngl-modal>

<ngl-modal [header]="promptHeader" [(open)]="opened" [prompt]="prompt" dismissOnClickOutside="false">
    <div class="slds-p-around_medium">
        <div [innerHtml]="this.adobeSignErrorMsg"></div>
    </div>
    <ng-template nglModalFooter>
        <button class="slds-button slds-button_neutral" (click)="cancelErrorPrompt()">Okay</button>
    </ng-template>
</ngl-modal>