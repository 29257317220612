import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { AppConfigService } from '../app-config.service';
import { HttpService } from './http.service';
import { Organization } from '../interface/organization';
import { ClientApplication } from '../interface/client-application'
import { PermissionSet } from '../interface/permission-set'
import { ReleaseConfig } from '../interface/release-config'
import { CreateJobRequest } from '../interface/create-job-request';
import { PackageAccessControl } from '../interface/package-access-control';
import { CreateUpgradeScheduleRequest } from '../interface/create-upgrade-schedule-request';
import { ScheduleOrg, UpdateUpgradeScheduleRequest } from '../interface/update-upgrade-schedule-request';
import { AssociateOrgIdWithSmartTagIds, AssociateSmartTagIdWithOrgIds, PushUpgradeSmartTag, SmartTagRequest } from '../interface/push-upgrade-smart-tag';
import { PackageUpgradeSettings, SmartScheduleSetting, ListFilter, WatcherListItem, PackageUpgradeSettingList, PackageUpgradeActionList, PackageUpgradeAction, WatcherList, BackgroundServiceSetting, SessionSetting } from '../interface/pu-admin';
import { JobSummary, OrgSummary } from '../interface/upgrade-summary';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  apiBaseUrl: string;
  headers = new HttpHeaders().set('Content-Type', 'application/json');
  currentUser: ClientApplication = { ClientId: null, UserType: null, AppName: null };

  constructor(
    private http: HttpService,
    public router: Router,
    private appConfigService: AppConfigService
  ) {
    this.apiBaseUrl = this.appConfigService.apiBaseUrl;
    this.currentUser.UserType = this.appConfigService.userType;
  }

  // Redirect to Salesforce login page.
  redirectToSalesforceSignIn(isSandbox: boolean) {
    return this.getSFDCLoginURL(isSandbox).subscribe((response: any) => {
      if (!this.isEmpty(response)) {
        var urlParams = response.substring(response.indexOf("?") + 1);
        var paramArray = urlParams.split("&");
        paramArray.forEach(element => {
          if (element.indexOf("state") >= 0) {
            var stateId = element.split("=")[1];
            sessionStorage.setItem("state", stateId);
          }
        });
      }
      setTimeout(function () { window.location.href = response; }, 2000);
    });
  }

  // Sign-in to PushUpgrade API to get access token and redirect to SFDC connected app login page.
  adminSignIn(userName: string, clientId: string): Observable<any> {
    this.currentUser.AppName = userName;
    this.currentUser.ClientId = clientId;
    return this.http.post<any>('/ClientApplication', this.currentUser)
  }

  //Get SalesForce Connected app URL.
  getSFDCLoginURL(isSandbox: boolean): Observable<any> {
    let headers: any = { 'Content-Type': 'text/plain; charset=utf-8' };
    if (isSandbox) headers = { ...headers, 'OrgType': 'sandbox' };
    return this.http.get<any>('/login', { headers });
  }

  refreshToken(body: { refreshToken: string, accessToken: string }) {
    return this.http.post<any>('/ClientApplication/RefreshToken', body);
  }

  //Get the early feedback url along with TAAS access token.
  getEarlyFeedbackURL(orgId: string): Observable<any> {
    let headers: any = { 'Content-Type': 'text/plain; charset=utf-8' };
    return this.http.get<any>('/Login/GetEarlyFeedbackURL/' + orgId, { headers })
  }

  //Endpoint to generate a new accesstoken from the refresh token
  generateSFOrgAccessTokenByRefreshTokenAndSave(orgId: string): Observable<any> {
    let headers: any = { 'Content-Type': 'text/plain; charset=utf-8' };
    return this.http.post<any>('/Login/GenerateAccessToken/' + orgId, { headers })
  }

  //Endpoint to generate Welcome email for an Organization.
  triggerOrgRegistrationEmail(orgId: string): Observable<any> {
    let headers: any = { 'Content-Type': 'text/plain; charset=utf-8' };
    return this.http.post<any>('/AdminDashboard/TriggerOrgRegistrationEmail/' + orgId, { headers })
  }

  //Using Auth Code authenticate the connected app to get access token.
  getSalesForceOrgAccessTokenAndSave(authCode: any, isSandbox: boolean) {
    let headers: any = { 'Content-Type': 'application/json' };
    if (isSandbox) headers = { ...headers, 'OrgType': 'sandbox' };
    this.http.post<any>('/login', JSON.stringify(authCode), { headers })
      .subscribe((result: any) => {
        if (result) {
          localStorage.setItem('access_token', result.token)
          localStorage.setItem('user_type', result.userType)
          localStorage.setItem('org_id', result.orgId);
          this.redirectToRegistrationPageWithOrgId(result.orgId)
        }
      });
  }

  //Redirect to registration form page by passing the Organization ID.
  redirectToRegistrationPageWithOrgId(orgId: string) {
    this.router.navigate(['ui/consent-form/' + orgId]);
  }

  // Redirect to Login page.
  redirectToLogin() {
    this.redirectToAdminLogin()
  }

  //Redirect to opt-In login page.
  redirectToOptinLogin() {
    if (this.isEmpty(localStorage.getItem('isSandbox'))) {
      this.router.navigate(['ui/login/production']);
    }
    else {
      this.router.navigate(['ui/login/sandbox']);
      localStorage.removeItem('isSandbox');
    }
  }

  //Redirect to login page.
  redirectToAdminLogin() {
    localStorage.setItem('redirectURLAfterReLogin', window.location.href);
    localStorage.removeItem('user_type');
    window.location.href = 'ui/admin/login';
  }

  //Get access token from local storage.
  getToken() {
    return localStorage.getItem('access_token');
  }
  //Get refresh token from local storage.
  getRefreshToken() {
    return localStorage.getItem('refresh_token');
  }
  //Get Organization ID from local storage.
  getOrgId() {
    return localStorage.getItem('org_id');
  }

  //Check if the access token is present and confirm login.
  get isLoggedIn(): boolean {
    let authToken = localStorage.getItem('access_token');
    return (authToken !== null) ? true : false;
  }

  //Check if the userType is CongaAdmin.
  get isAdminUser(): boolean {
    let userType = localStorage.getItem('user_type');
    if (this.isEmpty(userType))
      return false;
    else
      return (userType.toLowerCase() === 'congaadmin') ? true : false;
  }

  // Clear the local storage keys
  doLogout() {
    sessionStorage.removeItem("state");
    localStorage.removeItem('access_token');
    localStorage.removeItem('org_id');
    localStorage.removeItem('user_type');
    localStorage.removeItem('redirectURLAfterReLogin')
  }

  //Empty check condition.
  isEmpty(val: any) {
    return (val === undefined || val == null || val.length <= 0) ? true : false;
  }

  //Get Org Details.
  getSalesForceOrgCustomerPreference(orgId: string): Observable<Organization> {
    return this.http.get<Organization>(`/CustomerPreference/${orgId}`)
  }

  //Get all Permission Set for a given Org.
  getSalesForceOrgPermissionSets(orgId: string): Observable<PermissionSet[]> {
    return this.http.get<PermissionSet[]>(`/Org/GetOrgPermissionSets?orgId=${orgId}`)
  }

  //Save Org Details with Customer Preference Settings.
  saveSalesForceOrgCustomerPreference(userPreference: Organization): Observable<any> {
    return this.http.post<any>('/CustomerPreference/SaveCustomerPreference', JSON.stringify(userPreference))
  }

  //Save Access Control Information for a given Org ID.
  savePackageAccessControlInfo(PackageAccessControlLst: PackageAccessControl[], orgId: string): Observable<any> {
    return this.http.post<any>(`/CustomerPreference/SavePackageAccessControl/${orgId}`, JSON.stringify(PackageAccessControlLst))
  }

  //Get Access Control Information for a given Org ID.
  getAccessControlInfo(orgId: string, includeThirdPartyPackages: boolean = false): Observable<any> {
    return this.http.get<any>(`/CustomerPreference/GetPackageAccessControlInfo/${orgId}/${includeThirdPartyPackages}`)
  }

  //Get the current relase Info with supported products
  getCurrentReleaseInfo() {
    return this.http.get<any>('/Configuration/GetCurrentReleaseInfo')
  }

  //Get the current active relase Info
  getCurrentActiveReleaseInfo() {
    return this.http.get<ReleaseConfig>('/Configuration/GetCurrentActiveRelease')
  }

  //Get if the early feedback feature needs to be enabled for admin dashboard.
  getIsEarlyFeedbackFeatureEnabledForAdmin() {
    return this.http.get<boolean>('/Configuration/GetIsEarlyFeedbackFeatureEnabledForAdmin')
  }

  //Get available relase Info list.
  getAllReleaseInfo(includeInActiveReleases: boolean = false) {
    let params = new HttpParams().append("includeInActiveReleases", includeInActiveReleases.toString());
    return this.http.get<ReleaseConfig[]>('/Configuration/GetAllReleaseInfo', { params })
  }

  //Get the current relase Info with supported products for the given OrgID.
  getReleaseDetails(OrgID: string): Observable<any> {
    return this.http.get<ReleaseConfig>('/CustomerPreference/GetSubscriberReleaseDetail/' + OrgID)
  }

  //Get All Org Registration Summary Report
  getOrgRegistrationSummaryReport(): Observable<OrgSummary> {
    return this.http.get<any>('/AdminDashboard/OrgRegistrationSummaryReport')
  }

  //Get All Org Job Summary Report
  getUpgradeJobSummaryReport(): Observable<JobSummary> {
    return this.http.get<any>('/AdminDashboard/UpgradeJobSummaryReport')
  }

  //Get All Org Details based on Release and Produt Name from Push-Upgrade Job records.
  getAllSalesForceOrgPushUpgradeJobs(): Observable<any> {
    return this.http.get<any>('/AdminDashboard/GetAllJobRecords')
  }

  getSalesForceOrgPushUpgradeJobs(searchText: string, pageNo: number, pageSize: number, clientTimezone: string, isSandbox: boolean): Observable<any> {
    let params = new HttpParams().append("pageNo", pageNo.toString()).append("pageSize", pageSize.toString()).append("searchText", searchText).append("clientTimezone", clientTimezone).append("isSandbox", isSandbox.toString());
    return this.http.get<any>('/AdminDashboard/GetJobRecords', { params })
  }

  //Get All Org Details based on Release and Produt Name from Push-Upgrade Job records.
  getAllRegisteredOrgList(): Observable<any> {
    return this.http.get<any>('/AdminDashboard/GetAllRegisteredOrgList')
  }

  getRegisteredOrgList(searchText: string, pageNo: number, pageSize: number, clientTimezone: string, isSandbox: boolean, showActiveOrg: boolean): Observable<any> {
    let params = new HttpParams().append("pageNo", pageNo.toString()).append("pageSize", pageSize.toString()).append("searchText", searchText).append("clientTimezone", clientTimezone).append("isSandbox", isSandbox.toString()).append("filterOnlyActiveOrg", showActiveOrg.toString());
    return this.http.get<any>('/AdminDashboard/GetRegisteredOrgList', { params: params });
  }

  getUpgradeScheduleList(searchText: string, pageNo: number, pageSize: number, clientTimezone: string, isSandbox: boolean): Observable<any> {
    let params = new HttpParams().append("pageNo", pageNo.toString()).append("pageSize", pageSize.toString()).append("searchText", searchText).append("clientTimezone", clientTimezone).append("isSandbox", isSandbox.toString());
    return this.http.get<any>('/PushUpgradeSchedule/GetUpgradeScheduleList', { params: params });
  }

  getPushUpgradeScheduleList(searchText: string, pageNo: number, pageSize: number, clientTimezone: string, isSandbox: boolean): Observable<any> {
    let params = new HttpParams().append("pageNo", pageNo.toString()).append("pageSize", pageSize.toString()).append("searchText", searchText).append("clientTimezone", clientTimezone).append("isSandbox", isSandbox.toString());
    return this.http.get<any>('/PushUpgradeSchedule/GetPushUpgradeScheduleList', { params: params });
  }

  getPushUpgradeScheduleSettings(): Observable<any> {
    return this.http.get<any>('/AdminSettings/GetSmartScheduleSettingInfo');
  }

  getAllSmartTagsList(): Observable<any> {
    return this.http.get<any>('/SmartTag/GetAllSmartTags');
  }

  removeScheduleUpgrade(scheduleOrgsListToRemove: ScheduleOrg[]): Observable<any> {
    const body = JSON.stringify(scheduleOrgsListToRemove);
    return this.http.delete<any>('/PushUpgradeSchedule/Deleteschedule', { body: body })
  }

  cancelScheduleUpgrade(scheduleOrgsListToRemove: ScheduleOrg[]): Observable<any> {
    const body = JSON.stringify(scheduleOrgsListToRemove);
    return this.http.delete<any>('/PushUpgradeSchedule/CancelSchedule', { body: body })
  }

  finalizeScheduleUpgrade(scheduleOrgsListToRemove: ScheduleOrg[]): Observable<any> {
    return this.http.put<any>('/PushUpgradeSchedule/FinalizeSchedule', JSON.stringify(scheduleOrgsListToRemove))
  }

  //Get upgrade detials for a particular JobId.
  getJobDetails(orgId: string, jobId: string): Observable<any> {
    return this.http.get<any>(`/AdminDashboard/GetJobRecords/${orgId}/${jobId}`)
  }

  //Get upgrade detials for a particular customerPreferenceId and release name.
  getUpgradeDetailsDialog(orgId: string, customerPreferenceId: string, releaseName: string): Observable<any> {
    return this.http.get<any>(`/AdminDashboard/GetUpgradeJobRecordInfo/${orgId}/${customerPreferenceId}/${releaseName}`)
  }

  //Trigger Push Upgrade for a given Job Id.
  triggerUpgradeJob(jobIds: string[]): Observable<any> {
    const body = JSON.stringify(jobIds);
    return this.http.post<any>('/AdminDashboard', body)
  }

  //Trigger Push Upgrade for a given Job Id.
  CreateAndTriggerUpgradeJob(createJobRequest: CreateJobRequest): Observable<any> {
    const body = JSON.stringify(createJobRequest);
    return this.http.post<any>('/AdminDashboard/CreateAndInvokeUpgradeJob', body, {}, { showToast: false })
  }

  ReTriggerPostUpgradeValidation(pushJobId: string): Observable<any> {
    return this.http.post<any>(`/AdminDashboard/ReTriggerPostUpgradeValidation/${pushJobId}`, null, {}, { showToast: false })
  }

  GetScheduleDates(releaseName: string): Observable<any> {
    return this.http.get<any>(`/ReleaseConfig/GetSchedule/${releaseName}`)
  }

  GetAllSchedulableOrgs(releaseName: string, smartTagIds: string[], isSandbox: boolean): Observable<any> {
    var params = new HttpParams().append("releaseName", releaseName).append("isSandbox", isSandbox.toString());
    smartTagIds.forEach(id => {
      params = params.append("smartTagIds", id);
    })
    return this.http.get<any>('/PushUpgradeSchedule/GetAllSchedulableOrgList', { params })
  }

  CreateDraftUpgradeSchedule(createUpgradeScheduleRequest: CreateUpgradeScheduleRequest): Observable<any> {
    const body = JSON.stringify(createUpgradeScheduleRequest);
    return this.http.post<any>('/PushUpgradeSchedule/AllocateSchedule', body, {}, { showToast: false })
  }

  CreateUpgradeSchedule(createUpgradeScheduleRequest: CreateUpgradeScheduleRequest): Observable<any> {
    const body = JSON.stringify(createUpgradeScheduleRequest);
    return this.http.post<any>('/PushUpgradeSchedule/CreateSchedule', body, {}, { showToast: false })
  }

  UpdateUpgradeSchedule(updateScheduleRequest: UpdateUpgradeScheduleRequest): Observable<any> {
    const body = JSON.stringify(updateScheduleRequest);
    return this.http.put<any>('/PushUpgradeSchedule/UpdateSchedule', body)
  }

  CreateSmartTag(createSmartTag: SmartTagRequest): Observable<any> {
    const body = JSON.stringify(createSmartTag);
    return this.http.post<any>('/SmartTag/Post', body, {}, { showToast: false })
  }

  UpdateSmartTag(createSmartTag: SmartTagRequest): Observable<any> {
    const body = JSON.stringify(createSmartTag);
    return this.http.put<any>('/SmartTag/Put', body, {}, { showToast: false })
  }

  GetAllActiveOrgs(): Observable<any> {
    return this.http.get<any>('/SmartTag/GetAllActiveOrgs')
  }

  GetSmartTagList(searchText: string, pageNo: number, pageSize: number): Observable<any> {
    var params = new HttpParams().append("pageNo", pageNo.toString()).append("pageSize", pageSize.toString()).append("searchText", searchText);
    return this.http.get<any>('/SmartTag/GetSmartTagList', { params: params })
  }

  AssociateSmartTagIdWithOrgIds(associateSmartTagIdWithOrgIds: AssociateSmartTagIdWithOrgIds): Observable<any> {
    const body = JSON.stringify(associateSmartTagIdWithOrgIds);
    return this.http.put<any>('/SmartTag/AssociateSmartTagIdWithOrgIds', body)
  }

  AssociateOrgIdWithSmartTagIds(associateOrgIdWithSmartTagIds: AssociateOrgIdWithSmartTagIds): Observable<any> {
    const body = JSON.stringify(associateOrgIdWithSmartTagIds);
    return this.http.put<any>('/SmartTag/AssociateOrgIdWithSmartTagIds', body)
  }

  deleteSmartTag(smartTagIds: string[]): Observable<any> {
    const body = JSON.stringify(smartTagIds);
    let options = { body: body };
    return this.http.delete<any>('/SmartTag/Delete', options)
  }

  TriggerJobRubStatusReport(pushJobId: string): Observable<any> {
    return this.http.post<any>(`/AdminDashboard/ResendJobRunReport/${pushJobId}`, null, {}, { showToast: false })
  }

  GetReleaseMetadata(releaseName: string): Observable<any> {
    return this.http.get<any>(`/ReleaseConfig/${releaseName}/getPackages`)
  }

  ChangeReleaseStatus(releaseName: string, isActive: boolean): Observable<any> {
    return this.http.post<any>(`/ReleaseConfig/SetStatus/${releaseName}/${isActive.toString()}`, null)
  }

  RefreshPatchReleaseMetadata(releaseName: string, patchNo: string): Observable<any> {
    return this.http.post<any>(`/ReleaseConfig/RefreshMetadata/${releaseName}/patchRelease/${patchNo}`, null)
  }

  SetAdobeConsentStatus(orgId: string, isEnable: boolean) {
    return this.http.post<any>(`/CustomerPreference/SetAdobeConsentStatus/${orgId}/${isEnable.toString()}`, null)
  }


  getAllWatcher(filter: ListFilter) {
    let url = `/Watcher/GetAll?pageNo=${filter.pageNo}&pageSize=${filter.pageSize}`;

    if (filter.searchText) {
      url = url.concat(`&searchText=${filter.searchText}`);
    }
    return this.http.get<WatcherList>(url);
  }

  DeleteByWatcherId(watcherId: string) {
    return this.http.delete<any>('/Watcher/DeleteById/' + watcherId, { responseType: 'text' }, { successToast: "Watcher is deleted successfully" })
  }

  submitMultipleWatcher(watcherList: Array<WatcherListItem>) {
    return this.http.post<any>('/Watcher/PostMultiple', watcherList, {}, { successToast: "Watcher(s) is added successfully" })
  }

  submitWatcher(watcher: WatcherListItem) {
    if (watcher.id) {
      return this.http.put<any>('/Watcher', watcher, {}, { successToast: "Watcher is updated successfully" })
    } else {
      return this.http.post<any>('/Watcher', watcher, {}, { successToast: "Watcher(s) is added successfully" })
    }
  }

  getAllUsers(filter: ListFilter) {
    let url = `/Users/GetAll?pageNo=${filter.pageNo}&pageSize=${filter.pageSize}`;
    if (filter.searchText) {
      url = url.concat(`&searchtext=${filter.searchText}`);
    }
    return this.http.get<any>(url);
  }
  submitUser(user: any, updatePassword: boolean = false) {
    if (this.isEmpty(user.id)) {
      return this.http.post<any>('/Users', user, {}, { successToast: "User is added successfully" })
    } else {
      return this.http.put<any>('/Users?updatePassword=' + updatePassword, user, {}, { successToast: "User is updated successfully" })
    }
  }
  updateUserStatus(user: any) {
    return this.http.put<any>('/Users?updatePassword=false', user, {}, { successToast: user.isActive ? "User activated successfully" : "User deactivated successfully" })
  }


  GetSiteMaintenanceStatus() {
    return this.http.get<boolean>('/AdminSettings/GetIsSiteUnderMaintenance');
  }
  updateSiteMaintenanceStatus(payload: { IsSiteUnderMaintenanceFlag: boolean }) {
    return this.http.post(`/AdminSettings/UpdateIsSiteUnderMaintenanceFlag?IsSiteUnderMaintenanceFlag=${payload.IsSiteUnderMaintenanceFlag}`, {});
  }
  GetSendEmailForOrgLifeCycle() {
    return this.http.get<{ allowSendEmail: boolean }>('/AdminSettings/GetSendEmailForOrgLifeCycle');
  }
  GetSmartScheduleSettingInfo() {
    return this.http.get<SmartScheduleSetting>('/AdminSettings/GetSmartScheduleSettingInfo');
  }
  GetMinSupportedReleaseVersionForPatch() {
    return this.http.get<string>('/AdminSettings/GetMinSupportedReleaseVersionForPatch');
  }

  getGetBackgroundServiceSettings() {
    return this.http.get<BackgroundServiceSetting>('/AdminSettings/GetBackgroudServiceSettings');
  }

  updateBackgroudServiceSettings(payload: BackgroundServiceSetting) {
    return this.http.post('/AdminSettings/UpdateBackgroudServiceSettings', payload);
  }


  SetSendEmailForOrgLifeCycle(allowSendEmail) {
    return this.http.post('/AdminSettings/SetSendEmailForOrgLifeCycle/' + allowSendEmail, {});
  }
  UpdateSmartScheduleSettingInfo(payload: SmartScheduleSetting) {
    return this.http.post('/AdminSettings/UpdateSmartScheduleSettingInfo', payload);
  }
  SetMinSupportedReleaseVersionForPatch(minSupportedPatchReleaseVersion: string) {
    return this.http.post('/AdminSettings/SetMinSupportedReleaseVersionForPatch/' + minSupportedPatchReleaseVersion, {});
  }
  getSessionSettings() {
    return this.http.get<SessionSetting>("/AdminSettings/GetSessionSettings");
  }
  updateSessionSettings(payload: SessionSetting) {
    return this.http.post<boolean>("/AdminSettings/UpdateSessionSettings", payload)
  }


  getAllRegisteredOrgListReport(clientTimezone: string, isSandbox: boolean = null) {

    let url = `/Export/GetAllRegisteredOrgListReport?clientTimezone=${clientTimezone}`;
    if (isSandbox !== null) {
      url = `${url}&isSandbox=${isSandbox}`
    }
    return this.http.get<any>(url, { responseType: 'blob', observe: "response" },);
  }

  getAllUpgradeJobListReport(orgId: string = "", jobId: string = "", clientTimezone: string, onlySandbox: boolean = null) {
    let url = `/Export/GetAllUpgradeJobListReport?ClientTimezone=${clientTimezone}`;
    if (orgId) { url = `${url}&OrgId=${orgId}` }
    if (jobId) { url = `${url}&JobId=${jobId}` }
    if (onlySandbox !== null) {
      url = `${url}&OnlySandbox=${onlySandbox}`;
    }
    return this.http.get<any>(url, { responseType: 'blob', observe: "response" });
  }


  getAllPackageUpgradeSettings(filter: ListFilter) {
    let url = `/PackageUpgradeSettings?pageNo=${filter.pageNo}&pageSize=${filter.pageSize}`;
    if (filter.searchText) {
      url = url.concat(`&searchtext=${filter.searchText}`);
    }
    return this.http.get<PackageUpgradeSettingList>(url)
  }
  submitPackageUpgradeSettings(payload: PackageUpgradeSettings) {
    if (payload.id) {
      return this.http.put("/PackageUpgradeSettings/", payload, {}, { successToast: "Package Metadata Setting is updated successfully" })
    } else {
      return this.http.post("/PackageUpgradeSettings", payload, {}, { successToast: "Package Metadata Setting is added successfully" })
    }
  }
  getPackageUpgradeSettingDetails(subscriberPackageVersionKey: string) {
    return this.http.get<PackageUpgradeSettings>("/PackageUpgradeSettings/" + subscriberPackageVersionKey)
  }
  deletePackageUpgradeSettings(subscriberPackageVersionKey: string) {
    return this.http.delete("/PackageUpgradeSettings/" + subscriberPackageVersionKey, {}, { successToast: "Package Metadata Setting is  deleted successfully" })
  }

  optOutOfCongaUpgrade(orgName: string, orgId: string) {
    return this.http.post(`/Org/OptOutofCongaUpgrade?orgId=${orgId}`, {}, {}, { showToast: true, successToast: `The org ${orgName} - ${orgId} has been opted out successfully.` });
  }


  getAllPackageUpgradeActions(filter: { isActive: boolean } & ListFilter) {
    let url = `/PackageUpgradeActions/GetActions/${filter.isActive}?pageNo=${filter.pageNo}&pageSize=${filter.pageSize}`;
    if (filter.searchText) {
      url = url.concat(`&searchText=${filter.searchText}`);
    }

    return this.http.get<PackageUpgradeActionList>(url)
  }
  submitPackageUpgradeActions(payload: PackageUpgradeAction) {
    if (payload.id) {
      return this.http.put("/PackageUpgradeActions/Update", payload, {}, { successToast: "Pre and Post Upgrade Action is updated successfully" })
    } else {
      return this.http.post("/PackageUpgradeActions", payload, {}, { successToast: "Pre and Post Upgrade Action is added successfully" })
    }
  }
  getPackageUpgradeActionDetails(id: string) {
    return this.http.get<PackageUpgradeAction>("/PackageUpgradeActions/" + id)
  }
  togglePackageUpgradeActionStatus(id: string, isActive: boolean) {
    return this.http.post("/PackageUpgradeActions/ChangeStatus/" + id + "/" + isActive, {}, {}, { successToast: `Pre and Post Upgrade Action is ${isActive ? 'enabled' : 'disabled'} successfully` })
  }


  //**Format date to display on UI */
  dateFormatter(inputDate: string, includeTimezone: boolean = true) {
    var date = new Date(inputDate);
    if (date.getFullYear() < 2019)
      return "N/A";

    var day = date.getDate();
    var month = date.toDateString().substr(4, 3);
    var year = date.getFullYear();
    var time = ("00" + date.getHours()).slice(-2) + ":"
      + ("00" + date.getMinutes()).slice(-2)
      + ":" + ("00" + date.getSeconds()).slice(-2);
    return day + '-' + month + '-' + year + ' ' + time + (includeTimezone ? ' (' + this.getTimeZone(date) + ')' : '');
  }

  getTimeZone(inputDate: Date = null): string {
    var date = (inputDate != null) ? new Date(inputDate) : new Date();
    var timeZone = /\((.*)\)/.exec(date.toString())[1];
    return timeZone;
  }
}