import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-push-upgrade-welcome',
  templateUrl: './push-upgrade-welcome.component.html',
  styleUrls: ['./push-upgrade-welcome.component.css'],
})
export class PushUpgradeWelcomeComponent implements OnInit {
  constructor() {
    window.localStorage.clear();
    window.location.href = window.location.origin + '/ui/upgrade/welcome';
  }

  ngOnInit(): void {}

  redirectToProductionOrg() {
    window.localStorage.clear();
    window.location.href = 'ui/login/production';
  }

  redirectToSandboxOrg() {
    window.localStorage.clear();
    window.location.href = 'ui/login/sandbox';
  }
}
