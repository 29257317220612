import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../shared/auth.service';
import {TextMessage} from '../shared/text-message';

@Component({
  selector: 'app-forbidden',
  templateUrl: './forbidden.component.html',
  styleUrls: ['./forbidden.component.css']
})
export class ForbiddenComponent implements OnInit {



  error: string = TextMessage.forbiddenErrorCode;
  errorDiscription: string = TextMessage.forbiddenErrorMsg;
  img: string;
  constructor(private authservice: AuthService,private route: ActivatedRoute) {
    this.img = window.location.origin + "/assets/images/themes/oneSalesforce/banner-group-unlisted-default-medium.png";
  }

  ngOnInit(): void {

    // If it is present then alert and clear  access token
    if (!this.authservice.isEmpty(this.route.snapshot.queryParamMap.get('error'))) {
      // Get error msg from Salesforce CallBack URL
      this.error = this.route.snapshot.queryParamMap.get('error');
      var error_description = this.route.snapshot.queryParamMap.get('error_description');
      this.errorDiscription = "Description: " + error_description + ". " + "Kindly contact your Salesforce Administrator."
    }

  }
}
