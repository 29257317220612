<div class="slds-brand-band slds-brand-band_large">
    <nav class="navbar navbar-light bg-light">
        <span class="navbar-brand mb-0 h1">
            <h1 class="h1-heading"> <img class="img" width="60" alt="Conga Logo"
                    src="../../assets/images/logo-conga.svg" />Conga - Push Upgrade Admin Portal</h1>
        </span>
    </nav>
    <nav class="navbar navbar-expand-sm bg-dark navbar-dark"></nav>
    <ngl-alert *ngIf="this.commonService.maintenanceWarning$ | async" variant="warning" iconName="warning"
        [dismissible]="false">
        Site under maintenance.
    </ngl-alert>

    <!-- Login Div for Admin Dashboard -->
    <div class="container">
        <div id="login-row" class="row justify-content-center align-items-center">
            <div id="login-column" class="col-md-6">
                <div class="box">
                    <div class="shape1"></div>
                    <div class="shape2"></div>
                    <div class="shape3"></div>
                    <div class="shape4"></div>
                    <div class="shape5"></div>
                    <div class="shape6"></div>
                    <div class="shape7"></div>
                    <div class="float">
                        <form class="form" action="">
                            <div class="form-group">
                                <label for="username" class="text-white">Username:</label><br>
                                <input type="text" autocomplete="username" name="username" id="username"
                                    class="form-control" (keyup.enter)="onAdminLogin()" [(ngModel)]="username">
                            </div>
                            <div class="form-group">
                                <label for="pwd" class="text-white">Password:</label><br>
                                <input type="password" autocomplete="current-password" name="pwd" id="pwd"
                                    class="form-control" (keyup.enter)="onAdminLogin()" [(ngModel)]="clientId">
                            </div>
                            <div class="form-group">
                                <button type="button" class="btn btn-info btn-md"
                                    (click)="onAdminLogin()">Log-In</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="slds-notify_container">
        <ngl-toast iconName="utility:info" class="toast-info" *ngIf="showTopToast" [(variant)]="toastVariant"
            (close)="onClose($event); showTopToast = false;" duration="2000">
            <h3 class="slds-text-heading_small">{{toastMsg}}</h3>
        </ngl-toast>
    </div>