import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, Version, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { forkJoin } from 'rxjs';
import { UpgradeScheduleList } from '../interface/upgrade-schedule-list';
import { ReleaseConfig } from '../interface/release-config';
import { AuthService } from '../shared/auth.service';
import { NglComboboxOptionItem } from 'ng-lightning';
import { CommonService } from '../shared/common.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { Tag } from '../interface/tag';
import { CreateUpgradeScheduleRequest } from '../interface/create-upgrade-schedule-request';
import { CustomerPreferenceOrg, ScheduleOrg, UpdateUpgradeScheduleRequest } from '../interface/update-upgrade-schedule-request'

@Component({
  selector: 'app-admin-job-scheduler-list',
  templateUrl: './admin-job-scheduler-list.component.html',
  styleUrls: ['./admin-job-scheduler-list.component.css']
})

export class AdminJobSchedulerListComponent implements OnInit {



  displayedColumns: string[] = ['select', 'orgId', 'orgName', 'releaseDisplayName', 'scheduledDate', 'tags', 'status', 'action'];
  scheduleOrgsDisplayedColumns: string[] = ['orgId', 'orgName', 'releaseDisplayName', 'scheduledDate'];

  dataSource = new MatTableDataSource<UpgradeScheduleList>();
  length = 100;
  pageIndex = 0;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 25, 50, 75, 100];
  searchText: string = '';
  isSandboxTab: boolean = true;

  isLoading: boolean = false;
  showTopToast = false;
  toastMsg: string;
  toastVariant: string;
  disableBulkUpgradeBtn: boolean = true;

  openConfirmationModal: boolean = false;
  currentActiveReleaseInfo: ReleaseConfig = { id: null, displayLabel: null, name: null,
    lmaName: null, isCurrentRelease: true, gaDate: null, minDate: null, maxDate: null, patchNo: null,
    releaseVersion: new Version("0.0.0"), isCummulative: false, isActive: true, canBeDeactivated: false };
  openReleaseMenu: boolean = false;
  releaseList: ReleaseConfig[];
  public releaseDropdownList: NglComboboxOptionItem[];
  selection: number;
  open = false;
  openUpgradeModal = false;
  isEarlyFeedbackEnabled: boolean = false;
  scheduleOrgsDataSource = new MatTableDataSource<UpgradeScheduleList>();
  checkboxselection = new SelectionModel<UpgradeScheduleList>(true, []);
  timeZone: string;
  tzTimeZone: string;
  selectedTab: any = 'sandbox';
  statusIsScheduled: string = 'Scheduled';

  showScheduleUpgradeModal: boolean = false;
  tags: Tag[] = [];
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  tagsVisible = true;
  tagsSelectable = true;
  tagsRemovable = true;
  tagsAddOnBlur = true;

  cleaveOptions = {
    date: true,
    delimiter: '/',
    datePattern: ['d', 'MMM', 'Y']
  };
  scheduleName: string;
  scheduleDate: Date;
  minScheduleDate: Date;
  scheduleTimeSelection: string = null;
  scheduleDateHourSelection: string = null;
  scheduleDateMinutesSelection: string = null;
  scheduleTimeOptions: NglComboboxOptionItem[];
  scheduleDateHours: string[];
  scheduleDateMinutes: string[];
  scheduleTimeOpen: true;
  scheduleHoursOpen: true;
  scheduleMinsOpen: true;
  createUpgradeScheduleRequest: ScheduleOrg[] = [];
  createRescheduleUpgradeScheduleRequest: CreateUpgradeScheduleRequest = { id: null, releaseConfigId: null, tags: [], orgIds: [], scheduleDate: null, scheduleName: null, productLine: [], description: null, isSandbox: null };
  updateRescheduleRequest: UpdateUpgradeScheduleRequest = { newPushUpgradeScheduleRequest: this.createRescheduleUpgradeScheduleRequest, existingRescheduleOrgs: [] }
  @ViewChild('TableOneSort', { static: false }) tableOneSort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(public authservice: AuthService, public commonService: CommonService) { }

  ngOnInit(): void {
    var date = new Date();
    this.timeZone = /\((.*)\)/.exec(date.toString())[1];
    this.tzTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    this.scheduleDate = new Date();
    this.minScheduleDate = new Date();

    var maxDate = new Date(this.scheduleDate.getFullYear(), this.scheduleDate.getMonth(), this.scheduleDate.getDate());
    maxDate.setDate(maxDate.getDate() + 60);

    // Check if access token is present else take the user to admin login page.
    if (this.authservice.isLoggedIn) {
      this.getUpgradeScheduleOrgList();
    }
    else {
      this.authservice.redirectToAdminLogin();
    }
  }

  ngAfterViewInit() {
    this.paginator._intl.firstPageLabel = "First Page";
    this.paginator._intl.lastPageLabel = "Last Page";
    this.paginator._intl.previousPageLabel = "Prev Page";
    this.paginator._intl.nextPageLabel = "Next Page";
    this.paginator._intl.itemsPerPageLabel = "Display Records per page";

    this.dataSource.paginator = this.paginator;
  }

  openToast(msg: string, success: Boolean = false) {
    this.toastMsg = msg;
    if (success)
      this.toastVariant = 'success';
    else
      this.toastVariant = 'error';

    this.showTopToast = true;
  }

  onClose(reason: string) {
    console.log(`Closed by ${reason}`);
  }

  /** Method triggered to fetch/refresh the Push-Upgrade Jobs records */
  refresh(): void {
    this.getUpgradeScheduleOrgList();
  }

  getUpgradeScheduleOrgList(): void {
    this.checkboxselection.clear()
    this.isLoading = true;
    let currentActiveRelease = this.authservice.getCurrentActiveReleaseInfo()
    let upgradeScheduleOrgs = this.authservice.getUpgradeScheduleList(this.searchText, this.pageIndex + 1, this.pageSize, this.tzTimeZone, this.isSandboxTab);
    forkJoin([upgradeScheduleOrgs, currentActiveRelease]).subscribe(response => {
      var data = response[0].data;
      this.length = response[0].totalCount;
      this.pageIndex = response[0].pageNo - 1;
      this.pageSize = response[0].pageSize;
      this.currentActiveReleaseInfo = response[1];

      this.dataSource = new MatTableDataSource<UpgradeScheduleList>(data);
      this.dataSource.sort = this.tableOneSort;
      // PU-748 Handle sorting with case insensitive behaviour.
      this.dataSource.sortingDataAccessor = (data, header) => this.authservice.isEmpty(data[header]) ? "" : Array.isArray(data[header]) ? data[header].join() : data[header].toString().toLowerCase();

      this.isLoading = false;
    },
      error => {
        console.log(error)
      });
  }

  handlePageEvent(event: PageEvent) {
    this.length = event.length;
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;

    this.getUpgradeScheduleOrgList();
  }

  //**Apply filter to the Org list displayed on the UI */
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    if (this.searchText != filterValue.trim().toLowerCase()) {
      this.searchText = filterValue.trim().toLowerCase();
      if (this.searchText.length == 0 || this.searchText.length >= 3) {
        // PU-1168 Need to reset the page index as 0 at the time of search.
        this.pageIndex = 0;
        this.getUpgradeScheduleOrgList();
      }
    }
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.checkboxselection.selected.length;
    var enabledCheckboxCount = 0;
    this.dataSource.filteredData.forEach(row => (this.isDisabled(row.status)) ? null : enabledCheckboxCount++);
    return numSelected === enabledCheckboxCount;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.checkboxselection.clear();
      this.disableBulkUpgradeBtn = true;
      return;
    }
    else {
      this.dataSource.filteredData.forEach(row => (this.isDisabled(row.status)) ? null : this.checkboxselection.select(row));
      this.disableBulkUpgradeBtn = false;
    }
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: UpgradeScheduleList): string {
    if (!row) {
      this.checkboxselection.hasValue() ? this.disableBulkUpgradeBtn = false : this.disableBulkUpgradeBtn = true;
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
  }

  disableMasterCheckbox() {
    let disableMasterCheckbox: Boolean = true;
    this.dataSource.filteredData.forEach(x => x.status === this.statusIsScheduled ? disableMasterCheckbox = false : null)
    return disableMasterCheckbox
  }

  openScheduleCancelConfirmationModal() {
    this.openConfirmationModal = true;
  }

  closeScheduleCancelConfirmationModal() {
    this.openConfirmationModal = false;
  }

  removeSelectedSchedules() {
    this.isLoading = true;
    this.createUpgradeScheduleRequest = [];
    var lstOfSchedule = this.checkboxselection.selected;
    var groupedSchedule = this.groupBy(lstOfSchedule, x => x.name);

    for (let value of groupedSchedule.values()) {
      var scheduleOrgsListToRemove: ScheduleOrg = { pushUpgradeScheduleId: null, customerPreferenceOrgs: [] };
      for (var i = 0; i < value.length; i++) {
        scheduleOrgsListToRemove.pushUpgradeScheduleId = value[i].id;
        var customerPreferenceOrg: CustomerPreferenceOrg = { orgId: null, customerPrefernceId: null };
        customerPreferenceOrg.orgId = value[i].orgId;
        customerPreferenceOrg.customerPrefernceId = value[i].customerPreferenceId;
        scheduleOrgsListToRemove.customerPreferenceOrgs.push(customerPreferenceOrg)
      }
      this.createUpgradeScheduleRequest.push(scheduleOrgsListToRemove);
    }
    this.authservice.removeScheduleUpgrade(this.createUpgradeScheduleRequest).subscribe((data: ReleaseConfig) => {
      this.createUpgradeScheduleRequest = [];
      this.isLoading = false;
      this.openToast(this.checkboxselection.selected.length + ' Schedule(s) has been cancelled', true);
      this.closeScheduleCancelConfirmationModal();
      this.refresh();

    }, error => {
      console.log(error);
      this.openToast("System Error Occured!", false);
      this.closeScheduleCancelConfirmationModal();
      this.isLoading = false;
      this.refresh();
    });
  }

  removeSingleSchedule(element: UpgradeScheduleList) {
    this.checkboxselection.clear();
    this.checkboxselection.selected.push(element);
    this.openScheduleCancelConfirmationModal();
  }

  groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }




  //**Format date to display on UI */
  dateFormatter(inputDate: string) {
    var date = this.authservice.dateFormatter(inputDate);
    if (this.authservice.isEmpty(this.timeZone))
      this.timeZone = /\((.*)\)/.exec(date.toString())[1];
    date = date.split('(')[0];
    return date;
  }

  tabChange(tabName: string) {
    this.pageIndex = 0;
    var isSandboxTabActive = true;
    switch (tabName) {
      case "production": {
        isSandboxTabActive = false;
        break;
      }
      case "sandbox": {
        isSandboxTabActive = true;
        break;
      }
      default: {
        isSandboxTabActive = true;
        break;
      }
    }
    if (isSandboxTabActive !== this.isSandboxTab) {
      this.isSandboxTab = isSandboxTabActive;
      this.getUpgradeScheduleOrgList();
    }
  }

  //#region Schedule Upgrade Methods
  openScheduleUpgradeModal() {
    this.isLoading = true;
    // Check if access token is present else take the user to admin login page.
    if (this.authservice.isLoggedIn) {

      // check if any Org is already scheduled
      this.getScheduleDates();
      this.showScheduleUpgradeModal = true;

      // prepare Orgs list for selected Orgs
      this.scheduleOrgsDataSource.data = this.checkboxselection.selected;

    }
    else {
      this.authservice.redirectToAdminLogin();
    }
    this.isLoading = false;
  }

  prepareScheduleTimeOptions(inputDate: Date) {

    this.scheduleTimeOptions = [];
    let hrs = 0;
    var todayDate = new Date();
    if (todayDate.getDate() == inputDate.getDate() &&
      todayDate.getMonth() == inputDate.getMonth()) {
      hrs = todayDate.getHours() + 2;
    }

    for (; hrs < 24; hrs++) {
      var paddedHrs = (hrs < 10 ? "0" : "") + hrs.toString();

      for (let mins = 0; mins < 46; mins = mins + 15) {
        var paddedMins = (mins < 10 ? "0" : "") + mins.toString();

        let item: NglComboboxOptionItem = {
          value: paddedHrs + ":" + paddedMins,
          label: paddedHrs + ":" + paddedMins
        };

        this.scheduleTimeOptions.push(item);
      }
    }

    this.scheduleTimeSelection = this.prepareScheduleTimeOptions.length > 0 ? this.scheduleTimeOptions[0].value.toString() : null;
    this.scheduleTimeChange(this.scheduleTimeOptions[0].value.toString());
  }

  getScheduleDates() {
    this.authservice.GetScheduleDates(this.currentActiveReleaseInfo.name).subscribe((data: ReleaseConfig) => {
      // Get Active release Date and compare with today and acoording to set min and max date
      var todayDate = new Date();
      var parsedMinDate = new Date(Date.parse(data.minDate.toString()));

      if (todayDate < parsedMinDate) {
        this.scheduleDate = this.getDate(parsedMinDate);
        this.minScheduleDate = this.getDate(parsedMinDate);
      } else {
        if (todayDate.getHours() > 22) {
          todayDate.setDate(todayDate.getDate() + 1);
        }
        this.scheduleDate = this.getDate(todayDate);
        this.minScheduleDate = this.getDate(todayDate);
      }
      this.prepareScheduleTimeOptions(this.scheduleDate);
      this.setupScheduleName(this.scheduleDate);
    }, error => {
      console.log(error);
    });
  }

  getDate(fromDate: Date, addDays: number = 0): Date {
    var date = new Date(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate());
    if (addDays > 0) {
      date.setDate(date.getDate() + addDays);
    }

    return date;
  }

  scheduleDateChange(selectedDate: Date) {
    this.prepareScheduleTimeOptions(selectedDate);
    this.setupScheduleName(selectedDate);
    this.prepareScheduleTimeOptions(selectedDate);
  }

  scheduleTimeChange(selectedItem: string) {
    var hrs = selectedItem.substring(0, selectedItem.indexOf(":"));
    var mins = selectedItem.replace(hrs + ":", "");
    this.scheduleDateHourSelection = hrs;
    this.scheduleDateMinutesSelection = mins;

    this.setupScheduleName(this.scheduleDate);
  }

  setupScheduleName(inputDate: Date) {
    this.scheduleName = this.commonService.getCurrentReleaseLabel(this.currentActiveReleaseInfo) + ":"
      + inputDate.getDate() + "-" + inputDate.toDateString().substr(4, 3) + "-" + inputDate.getFullYear()
      + " " + this.scheduleDateHourSelection + ":" + this.scheduleDateMinutesSelection;

    var scheduleHours: number = +this.scheduleDateHourSelection;
    var scheduleMins: number = +this.scheduleDateMinutesSelection;
    this.scheduleDate = new Date(inputDate.getFullYear(), inputDate.getMonth(), inputDate.getDate(), scheduleHours, scheduleMins, 0)
  }

  addTag(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our fruit
    if (value) {
      this.tags.push({ name: value });
    }

    // Clear the input value
    event.input.value = "";
  }

  removeTag(tag: Tag): void {
    const index = this.tags.indexOf(tag);

    if (index >= 0) {
      this.tags.splice(index, 1);
    }
  }

  saveUpgradeSchedule() {
    this.isLoading = true;

    this.createRescheduleUpgradeScheduleRequest.scheduleName = this.scheduleName;
    this.createRescheduleUpgradeScheduleRequest.releaseConfigId = this.currentActiveReleaseInfo.id;
    this.createRescheduleUpgradeScheduleRequest.isSandbox = this.isSandboxTab;

    this.createRescheduleUpgradeScheduleRequest.orgIds = [];
    this.scheduleOrgsDataSource.data.forEach(orgData => {
      this.createRescheduleUpgradeScheduleRequest.orgIds.push(orgData.orgId);
    });

    this.createRescheduleUpgradeScheduleRequest.tags = [];
    this.tags.forEach(tag => {
      this.createRescheduleUpgradeScheduleRequest.tags.push(tag.name);
    });

    var scheduleHours: number = +this.scheduleDateHourSelection;
    var scheduleMins: number = +this.scheduleDateMinutesSelection;

    this.createRescheduleUpgradeScheduleRequest.scheduleDate = new Date(this.scheduleDate.getFullYear(), this.scheduleDate.getMonth(), this.scheduleDate.getDate(), scheduleHours, scheduleMins, 0);
    var groupedSchedule = this.groupBy(this.checkboxselection.selected, x => x.name);

    for (let value of groupedSchedule.values()) {
      var scheduleOrgsListToRemove: ScheduleOrg = { pushUpgradeScheduleId: null, customerPreferenceOrgs: [] };
      for (var i = 0; i < value.length; i++) {
        scheduleOrgsListToRemove.pushUpgradeScheduleId = value[i].id;
        var customerPreferenceOrg: CustomerPreferenceOrg = { orgId: null, customerPrefernceId: null };
        customerPreferenceOrg.orgId = value[i].orgId;
        customerPreferenceOrg.customerPrefernceId = value[i].customerPreferenceId;
        scheduleOrgsListToRemove.customerPreferenceOrgs.push(customerPreferenceOrg)
      }
      this.updateRescheduleRequest.existingRescheduleOrgs.push(scheduleOrgsListToRemove);
    }
    this.updateRescheduleRequest.newPushUpgradeScheduleRequest = this.createRescheduleUpgradeScheduleRequest;

    this.authservice.UpdateUpgradeSchedule(this.updateRescheduleRequest).subscribe((data: any) => {
      var success_msg = 'Push Upgrade Re-Scheduled for ' + this.checkboxselection.selected.length + ' Org(s)';
      this.openToast(success_msg, true);
      this.isLoading = false;
      //this.checkboxselection.selected.forEach(x => x.releaseTriggeredDate = new Date().toISOString());
      this.checkboxselection.clear();
      //this.cancelReleaseSelectionMenu();
      this.closeScheduleUpgradeModal();
      this.refresh();
    },
      (err) => {
        console.log(err);
        this.openToast("System Error Occured!", false);
        this.closeScheduleUpgradeModal();
        this.isLoading = false;
        this.refresh();
      }
    );
  }

  updateSingleSchedule(element: UpgradeScheduleList) {
    this.checkboxselection.clear();
    this.checkboxselection.selected.push(element);
    this.openScheduleUpgradeModal();
  }

  closeScheduleUpgradeModal() {
    this.tags = [];
    this.scheduleName = null;
    this.scheduleTimeSelection = null;
    this.showScheduleUpgradeModal = false;
  }

  isDisabled(status: string) {
    if (status === this.statusIsScheduled)
      return false;
    else
      return true;
  }
  //#endregion
}


