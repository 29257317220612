<div class="data-container mat-elevation-z8">
    <table #TableOneSort="matSort" mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox *readOnlyBtn color="primary" (change)="$event ? masterToggle() : null"
                    [checked]="checkboxselection.hasValue() && isAllSelected()" [disabled]="disableMasterCheckbox()"
                    [indeterminate]="checkboxselection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
                <mat-checkbox *readOnlyBtn color="primary" (click)="$event.stopPropagation()"
                    (change)="$event ? checkboxselection.toggle(row) : null"
                    [checked]="checkboxselection.isSelected(row)" [disabled]="this.isDisabled(row.status)"
                    [aria-label]="checkboxLabel(row)">
                </mat-checkbox>
            </td>
        </ng-container>

        <!-- Email ID Column -->
        <ng-container matColumnDef="emailId">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Email Id </th>
            <td mat-cell *matCellDef="let element"> {{element.emailId}} </td>
        </ng-container>

        <!-- Org ID Column -->
        <ng-container matColumnDef="orgId">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Org Id </th>
            <td mat-cell *matCellDef="let element"> {{element.orgId}} </td>
        </ng-container>

        <!-- Recipient Type Column -->
        <ng-container matColumnDef="recipientType">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Recipient Type </th>
            <td mat-cell *matCellDef="let element"> {{element.recipientType}} </td>
        </ng-container>

        <!-- Default Recipient Column -->
        <ng-container matColumnDef="defaultRecipient">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Default Recipient </th>
            <td mat-cell *matCellDef="let element"> {{element.defaultRecipient ? 'Yes'
                : 'No'}} </td>
        </ng-container>

        <!-- Action Column -->
        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef>
                <span *readOnlyBtn>
                    Action
                </span>
            </th>
            <td mat-cell *matCellDef="let element">
                <button *readOnlyBtn class="slds-button slds-icon_container slds-icon-utility-info"
                    [nglTooltip]="'Update'" nglTooltipInteractive="true" nglTooltipDelay="100" nglTooltipOpenAuto
                    (click)="editWatcher(element)">
                    <svg class="slds-button__icon" aria-hidden="true">
                        <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#edit">
                        </use>
                    </svg>
                </button>
                <button *readOnlyBtn class="slds-button slds-icon_container slds-icon-utility-info"
                    [nglTooltip]="'Delete'" nglTooltipInteractive="true" nglTooltipDelay="100" nglTooltipOpenAuto
                    (click)="confirmDeleteWatcher(element)">
                    <svg class="slds-button__icon" aria-hidden="true">
                        <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#delete">
                        </use>
                    </svg>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="6" style="text-align: center;">
                <span>No results found</span>
            </td>
        </tr>
    </table>

    <mat-paginator [length]="length" [pageIndex]="pageIndex" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
        [showFirstLastButtons]="true" (page)="handlePageEvent($event)">
    </mat-paginator>

</div>

<!-- Loading Icon while making backend call -->
<div class="loading_spinner" *ngIf="isLoading">
    <ngl-spinner size="large" variant="brand" alternativeText="Loading"></ngl-spinner>
</div>

<ngl-modal [header]="editEmail ? 'Update Watcher' : 'Create New Watchers'" [(open)]="showEmailModal" size="large">
    <div class="slds-p-around_medium">
        <div class="watcher-form">
            <form-field class="email-input" *ngIf="!editEmail">
                <mat-label>Email Id</mat-label>
                <mat-chip-list #chipList aria-label="Emails">
                    <mat-chip *ngFor="let email of emails" [selectable]="emailsSelectable" [removable]="emailsRemovable"
                        (removed)="removeEmail(email)">
                        {{email}}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                    <input [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        [matChipInputAddOnBlur]="emailsAddOnBlur" (matChipInputTokenEnd)="addEmail($event)">
                </mat-chip-list>
                <hr class="seperator">
                <span *ngIf="isSubmitted && !emails.length" class="slds-text-color_error">Email(s) are
                    required</span>
            </form-field>
            <div *ngIf="editEmail">
                <span *ngIf="editEmail">Email Id</span>
                <input *ngIf="editEmail" ngl [(ngModel)]="editorData.emailId">
                <span *ngIf="editEmail && isSubmitted && !editorData.emailId" class="slds-text-color_error">Email is
                    required</span>
                <span *ngIf="editEmail && isSubmitted && editorData.emailId && !emailPattern.test(editorData.emailId)"
                    class="slds-text-color_error">Invalid Email Id</span>
            </div>
        </div>

        <div class="flex rows">
            <ngl-checkbox-toggle label="Default Recipient" class="flex-1">
                <input ngl type="checkbox" [(ngModel)]="editorData.defaultRecipient"
                    (change)="toggleOrgId($event.target.checked)">
            </ngl-checkbox-toggle>
            <div class="flex-1">
                <ngl-combobox class="flex-1" label="Org Id" [options]="filteredOrgIdList$ | async" [(open)]="orgIdOpen"
                    [(selection)]="editorData.orgId" variant="lookup"
                    (selectionValueFn)="selectionValue(editorData.orgId)">
                    <input nglCombobox [formControl]="orgIdDropdownInputCtrl">
                </ngl-combobox>
                <span *ngIf="isSubmitted && !editorData.defaultRecipient && !editorData.orgId"
                    class="slds-text-color_error">Organizatoin is
                    required</span>
            </div>
            <div class="flex-1">
                <ngl-combobox label="Recipient Type" [(open)]="recipientTypeOpen" [options]="recipientTypeOptions"
                    [(selection)]="editorData.recipientType">
                    <input nglCombobox>
                </ngl-combobox>
                <span *ngIf="isSubmitted && !editorData.recipientType" class="slds-text-color_error">Recipient type
                    is
                    required</span>
            </div>
        </div>

        <br>
    </div>
    <ng-template nglModalFooter>
        <button class="slds-button slds-button_neutral"
            (click)="showEmailModal = false; editEmail = false;">Cancel</button>
        <button class="slds-button slds-button_brand" (click)="onSubmitWatcher()">{{editEmail ? 'Update' :
            'Submit'}}</button>
    </ng-template>
</ngl-modal>

<ngl-modal header="Delete Watcher" [(open)]="openDeleteConfirmationModel">
    <div class="slds-p-around_medium">
        <p>Are you sure to Delete the watcher?</p>
    </div>
    <ng-template nglModalFooter>
        <button class="slds-button slds-button_neutral" (click)="openDeleteConfirmationModel = false">Cancel</button>
        <button class="slds-button slds-button_brand" (click)="deleteWatcher()">Ok</button>
    </ng-template>
</ngl-modal>