import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from '../../shared/auth.service';
import { DropdownItem, PackageUpgradeAction, PackageUpgradeActionSettingList } from '../../interface/pu-admin';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { FormControl } from '@angular/forms';


@Component({
  selector: 'app-package-upgrade-actions',
  templateUrl: './package-upgrade-actions.component.html',
  styleUrls: ['./package-upgrade-actions.component.css']
})
export class PackageUpgradeActionsComponent implements OnInit {


  displayedColumns: string[] = ['actionType', 'settingsName', 'settingsValue', 'executeBeforeUpgrade', 'executeAfterUpgrade', 'continuePackageUpgradeOnError', 'isActive'];
  emailPattern = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
  dataSource = new MatTableDataSource<PackageUpgradeAction>();
  isLoading: boolean = false;
  showActionsModal: boolean = false;
  editActions: boolean = false;
  defaultPackageUpgradeActionsData: PackageUpgradeAction = {
    id: "",
    actionType: "ModifyContentSettings",
    typeEnum: 1,
    index: 0,
    settingsName: "",
    settingsValue: "",
    executeBeforeUpgrade: true,
    executeAfterUpgrade: true,
    continuePackageUpgradeOnError: true,
    isActive: true
  };
  editorData: PackageUpgradeAction = this.defaultPackageUpgradeActionsData;
  openDeleteConfirmationModel = false;
  deleteData: PackageUpgradeAction = null;
  @Input() searchText :FormControl;

  @ViewChild('TableOneSort', { static: false }) tableOneSort: MatSort;

  length: number = 100;
  pageSize: number = 10;
  pageIndex: number = 0;
  pageSizeOptions: number[] = [10, 25, 50, 75, 100];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  settionsOpen: boolean = false;
  settingList: Array<DropdownItem> = PackageUpgradeActionSettingList;
  isSubmitted: boolean = false;

  isActiveList: boolean = true;


  constructor(private authservice: AuthService) { }

  ngOnInit(): void { }

  public getPackageUpgradeActionsList(searchText: string = "") {
    // this.checkboxselection.clear()
    searchText = this.searchText.value
    this.isLoading = true;
    this.authservice.getAllPackageUpgradeActions({
      isActive: this.isActiveList,
      pageNo: this.pageIndex + 1,
      pageSize: this.pageSize,
      searchText
    }).subscribe(response => {
      this.dataSource = new MatTableDataSource<PackageUpgradeAction>(response.data.map(item => {
        return { ...item, settingsValue: item.settingsValue == "true"}
      }));
      this.length = this.pageSize * response.totalPages;
      this.dataSource.sort = this.tableOneSort;
      this.dataSource.sortingDataAccessor = (data, header) => this.authservice.isEmpty(data[header]) ? "" : Array.isArray(data[header]) ? data[header].join() : data[header].toString().toLowerCase();
      this.isLoading = false;
    }, () => {
      this.isLoading = false;
    });
  }

  public onCreate() {
    this.editActions = this.isSubmitted = false;
    this.editorData = JSON.parse(JSON.stringify(this.defaultPackageUpgradeActionsData));
    this.showActionsModal = true;
  }

  editPackageUpgradeActions(item: PackageUpgradeAction) {
    this.editActions = this.showActionsModal = true;
    this.isSubmitted = false;
    this.editorData = JSON.parse(JSON.stringify(item));
    console.log(this.editorData )
  }

  onSubmitPackageUpgradeActions() {
    
    this.isSubmitted = true;
    let hasError = !this.editorData.settingsName;

    if (!hasError) {
      this.isLoading = true;
      this.authservice.submitPackageUpgradeActions({
        ...this.editorData,
        settingsValue: this.editorData.settingsValue.toString()
      }).subscribe(() => {
        this.editActions = false;
        this.showActionsModal = false;
        this.getPackageUpgradeActionsList();
      }, (err) => {
        this.isLoading = false;
        console.error({ err });
      })
    }
  }

  confirmDeletePackageUpgradeActions(item: PackageUpgradeAction) {
    this.openDeleteConfirmationModel = true;
    this.deleteData = item;
  }

  deletePackageUpgradeActions() {
    this.authservice.togglePackageUpgradeActionStatus(this.deleteData.id, this.deleteData.isActive).toPromise().finally(() => {
      this.openDeleteConfirmationModel = false;
      this.getPackageUpgradeActionsList();
      this.deleteData = null;
    })
  }

  toggleActionStatus(event: any, item: PackageUpgradeAction) {
    this.authservice.togglePackageUpgradeActionStatus(item.id, event.target.checked).subscribe(() => {
      this.getPackageUpgradeActionsList()
    });
  }

  handlePageEvent(event: PageEvent) {
    this.length = event.length;
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;

    this.getPackageUpgradeActionsList();
  }

}
