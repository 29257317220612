<app-admin-navigation-panel></app-admin-navigation-panel>

<div class="main"><br><br>

    <ngl-tabset [(selected)]='selectedTab' variant='scoped'>
        <ng-template ngl-tab label="Watchers" (activate)="tabChange('watcher-list')">

            <div class="slds-page-header slds-page-header_record-home">
                <div class="slds-page-header__row">
                    <div class="slds-page-header__col-title">
                        <div class="slds-media">
                            <div class="slds-media__body">
                                <div class="slds-page-header__name">
                                    <div class="slds-page-header__name-title">
                                        <h1>
                                            <span class="slds-page-header__title slds-truncate"
                                                title="Scheduled Sandbox Org Information"></span>
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="slds-page-header__col-actions">
                        <div class="slds-page-header__controls">
                            <div class="slds-page-header__control">
                                <button *readOnlyBtn type="button" (click)="watcherList.onCreate()" nglButton
                                    class="slds-button slds-button_neutral">
                                    <span class="slds-text-not-selected">Create New Watchers</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </ng-template>
        <ng-template ngl-tab label="Package Metadata Settings" (activate)="tabChange('package-upgrade-settings')">

            <div class="slds-page-header slds-page-header_record-home">
                <div class="slds-page-header__row">
                    <div class="slds-page-header__col-title">
                        <div class="slds-media">
                            <div class="slds-media__body">
                                <div class="slds-page-header__name">
                                    <div class="slds-page-header__name-title">
                                        <h1>
                                            <span class="slds-page-header__title slds-truncate"
                                                title="Scheduled Sandbox Org Information"></span>
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="slds-page-header__col-actions">
                        <div class="slds-page-header__controls">
                            <div class="slds-page-header__control">
                                <button *readOnlyBtn type="button" (click)="packageUpgradeSettings.onCreate()" nglButton
                                    class="slds-button slds-button_neutral">
                                    <span class="slds-text-not-selected">Create New Setting</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </ng-template>
        <ng-template ngl-tab label="Pre and Post Upgrade Actions" (activate)="tabChange('package-upgrade-actions')">

            <div class="slds-page-header slds-page-header_record-home">
                <div class="slds-page-header__row">
                    <div class="slds-page-header__col-title">
                        <div class="slds-media">
                            <div class="slds-media__body">
                                <div class="slds-page-header__name">
                                    <div class="slds-page-header__name-title">
                                        <h1>
                                            <span class="slds-page-header__title slds-truncate"
                                            title="Scheduled Sandbox Org Information"></span>
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="slds-page-header__col-actions">
                        <div class="slds-page-header__controls">
                            <div class="slds-page-header__control">
                                <button *readOnlyBtn type="button" (click)="packageUpgradeActions.onCreate()" nglButton
                                    class="slds-button slds-button_neutral">
                                    <span class="slds-text-not-selected">Create New Action</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </ng-template>
        <ng-template ngl-tab label="Users" (activate)="tabChange('user')">

            <div class="slds-page-header slds-page-header_record-home">
                <div class="slds-page-header__row">
                    <div class="slds-page-header__col-title">
                        <div class="slds-media">
                            <div class="slds-media__body">
                                <div class="slds-page-header__name">
                                    <div class="slds-page-header__name-title">
                                        <h1>
                                            <span class="slds-page-header__title slds-truncate"
                                            title="Scheduled Sandbox Org Information"></span>
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="slds-page-header__col-actions">
                        <div class="slds-page-header__controls">
                            <div class="slds-page-header__control">
                                <button *readOnlyBtn type="button" (click)="user.onCreate()" nglButton
                                    class="slds-button slds-button_neutral">
                                    <span class="slds-text-not-selected">Add New User</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </ng-template>
        <ng-template ngl-tab label="System Settings" (activate)="tabChange('common-settings')">
        </ng-template>
        <!-- <ng-template ngl-tab label="Test2" (activate)="tabChange('test2')">
        </ng-template> -->
    </ngl-tabset>

    <br />
    <div class="slds-page-header">
        <div class="data-body">
            <div class="slds-page-header filter-wrapper">
                <mat-form-field *ngIf="activeTab=='watcher-list' || activeTab=='package-upgrade-settings' || activeTab=='package-upgrade-actions' || activeTab == 'user'">
                    <mat-label>Filter</mat-label>
                    <input matInput [formControl]="searchField" placeholder="Search Text">
                </mat-form-field>
                <app-watcher [searchText] = "searchField" [hidden]="activeTab!='watcher-list'" #watcherList></app-watcher>
                <app-common-settings  [hidden]="activeTab!='common-settings'" #commonSettings></app-common-settings>
                <app-package-upgrade-settings [searchText] = "searchField" [hidden]="activeTab!='package-upgrade-settings'" #packageUpgradeSettings></app-package-upgrade-settings>
                <app-package-upgrade-actions [searchText] = "searchField" [hidden]="activeTab!='package-upgrade-actions'" #packageUpgradeActions></app-package-upgrade-actions>
                <app-user [searchText] = "searchField" [hidden]="activeTab!='user'" #user></app-user>

            </div>
        </div>
    </div>

</div>