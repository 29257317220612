<app-admin-navigation-panel></app-admin-navigation-panel>

<!-- Scheduler Dashboard to view Schedule settings and Update/Cancel Schedule -->

<div class="main"><br><br>
    <ngl-tabset [(selected)]='selectedTab' variant='scoped'>
        <ng-template ngl-tab label="Sandbox" (activate)="tabChange('sandbox')">
            <div class="slds-page-header slds-page-header_record-home">
                <div class="slds-page-header__row">
                    <div class="slds-page-header__col-title">
                        <div class="slds-media">
                            <div class="slds-media__figure">
                                <span class="slds-icon_container slds-icon-standard-opportunity">
                                    <svg class="slds-icon slds-page-header__icon" aria-hidden="true">
                                        <use xlink:href="/assets/icons/standard-sprite/svg/symbols.svg#opportunity">
                                        </use>
                                    </svg>
                                </span>
                            </div>
                            <div class="slds-media__body">
                                <div class="slds-page-header__name">
                                    <div class="slds-page-header__name-title">
                                        <h1>
                                            <span class="slds-page-header__title slds-truncate"
                                                title="Scheduled Sandbox Org Information">Scheduled Sandbox Org
                                                Information</span>
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="slds-page-header__col-actions">
                        <div class="slds-page-header__controls">
                            <div class="slds-page-header__control">
                                <button *readOnlyBtn type="button" nglButton variant="brand"
                                    (click)="openScheduleUpgradeModal()" aria-live="assertive"
                                    [disabled]="disableBulkUpgradeBtn">
                                    <span class="slds-text-not-selected">Reschedule Upgrade</span>
                                </button>
                                <button *readOnlyBtn type="button" nglButton variant="destructive"
                                    (click)="openScheduleCancelConfirmationModal()" aria-live="assertive"
                                    [disabled]="disableBulkUpgradeBtn">
                                    <span class="slds-text-not-selected">Cancel Schedule</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template #heading>Production</ng-template>
        <ng-template id="production" ngl-tab #summaryTab="nglTab" [label]="heading"
            (activate)="tabChange('production')">
            <div class="slds-page-header slds-page-header_record-home">
                <div class="slds-page-header__row">
                    <div class="slds-page-header__col-title">
                        <div class="slds-media">
                            <div class="slds-media__figure">
                                <span class="slds-icon_container slds-icon-standard-opportunity">
                                    <svg class="slds-icon slds-page-header__icon" aria-hidden="true">
                                        <use xlink:href="/assets/icons/standard-sprite/svg/symbols.svg#opportunity">
                                        </use>
                                    </svg>
                                </span>
                            </div>
                            <div class="slds-media__body">
                                <div class="slds-page-header__name">
                                    <div class="slds-page-header__name-title">
                                        <h1>
                                            <span class="slds-page-header__title slds-truncate"
                                                title="Scheduled Production Org Information">Scheduled Production Org
                                                Information</span>
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="slds-page-header__col-actions">
                        <div class="slds-page-header__controls">
                            <div class="slds-page-header__control">
                                <button *readOnlyBtn type="button" nglButton variant="brand"
                                    (click)="openScheduleUpgradeModal()" aria-live="assertive"
                                    [disabled]="disableBulkUpgradeBtn">
                                    <span class="slds-text-not-selected">Reschedule Upgrade</span>
                                </button>
                                <button *readOnlyBtn type="button" nglButton variant="destructive"
                                    (click)="openScheduleCancelConfirmationModal()" aria-live="assertive"
                                    [disabled]="disableBulkUpgradeBtn">
                                    <span class="slds-text-not-selected">Cancel Schedule</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </ngl-tabset>

    <br>

    <div class="slds-page-header">
        <div class="data-body">
            <div class="slds-page-header">
                <mat-form-field>
                    <mat-label>Filter</mat-label>
                    <input matInput (keyup)="applyFilter($event)" placeholder="Search Text" #input>
                </mat-form-field>
                <div class="data-container mat-elevation-z8">
                    <table #TableOneSort="matSort" mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

                        <!-- Checkbox Column -->
                        <ng-container matColumnDef="select">
                            <th mat-header-cell *matHeaderCellDef>
                                <mat-checkbox *readOnlyBtn color="primary" (change)="$event ? masterToggle() : null"
                                    [checked]="checkboxselection.hasValue() && isAllSelected()"
                                    [disabled]="disableMasterCheckbox()"
                                    [indeterminate]="checkboxselection.hasValue() && !isAllSelected()"
                                    [aria-label]="checkboxLabel()">
                                </mat-checkbox>
                            </th>
                            <td mat-cell *matCellDef="let row">
                                <mat-checkbox *readOnlyBtn color="primary" (click)="$event.stopPropagation()"
                                    (change)="$event ? checkboxselection.toggle(row) : null"
                                    [checked]="checkboxselection.isSelected(row)"
                                    [disabled]="this.isDisabled(row.status)" [aria-label]="checkboxLabel(row)">
                                </mat-checkbox>
                            </td>
                        </ng-container>

                        <!-- Org ID Column -->
                        <ng-container matColumnDef="orgId">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Organization Id </th>
                            <td mat-cell *matCellDef="let element">
                                {{this.commonService.removeOrgIdCheksum(element.orgId)}} </td>
                        </ng-container>

                        <!-- Org Name Column -->
                        <ng-container matColumnDef="orgName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Organization Name </th>
                            <td mat-cell *matCellDef="let element"> {{element.orgName}} </td>
                        </ng-container>

                        <!-- Release Name Column -->
                        <ng-container matColumnDef="releaseDisplayName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Release</th>
                            <td mat-cell *matCellDef="let element"> {{element.releaseDisplayName}} </td>
                        </ng-container>

                        <!-- Schedule Date/Time Column -->
                        <ng-container matColumnDef="scheduledDate">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Schedule <br> ({{timeZone}})</th>
                            <td mat-cell *matCellDef="let element"> {{element.scheduledDateStr}} </td>
                        </ng-container>

                        <!-- Schedule Tags Column -->
                        <ng-container matColumnDef="tags">
                            <th mat-header-cell *matHeaderCellDef> Schedule Tags </th>
                            <td mat-cell *matCellDef="let element">
                                <ngl-pill *ngFor="let tag of  element.tags">
                                    {{tag}}
                                </ngl-pill>
                            </td>
                        </ng-container>

                        <!-- Status Column -->
                        <ng-container matColumnDef="status">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                            <td mat-cell *matCellDef="let element"> {{element.status}} </td>
                        </ng-container>

                        <!-- Action Column -->
                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef>
                                <span *readOnlyBtn>
                                    Action
                                </span>
                            </th>
                            <td mat-cell *matCellDef="let element">
                                <div *readOnlyBtn>
                                    <button class="slds-button slds-icon_container slds-icon-utility-info"
                                        [nglTooltip]="'Reschedule'" nglTooltipInteractive="true" nglTooltipDelay="100"
                                        nglTooltipOpenAuto [disabled]="this.isDisabled(element.status)"
                                        (click)="updateSingleSchedule(element)">
                                        <svg class="slds-button__icon" aria-hidden="true">
                                            <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#event">
                                            </use>
                                        </svg>
                                    </button>
                                    <button class="slds-button slds-icon_container slds-icon-utility-info"
                                        [nglTooltip]="'Cancel'" nglTooltipInteractive="true" nglTooltipDelay="100"
                                        nglTooltipOpenAuto [disabled]="this.isDisabled(element.status)"
                                        (click)="removeSingleSchedule(element)">
                                        <svg class="slds-button__icon" aria-hidden="true">
                                            <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#close">
                                            </use>
                                        </svg>
                                    </button>
                                </div>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                        <tr [nglTooltip]="this.isDisabled(row.status) ? dsbl : sdl" nglTooltipInteractive="true"
                            nglTooltipDelay="100" nglTooltipOpenAuto class="org-element-row"
                            [class.row-is-disabled]="this.isDisabled(row.status)" mat-row
                            *matRowDef="let row; columns: displayedColumns;">
                            <ng-template #dsbl>The schedule is {{row.status}}. To know more about Org Upgrade Status,
                                Kindly refer the Job Details Tab</ng-template>
                            <ng-template #sdl>Scheduled to run on {{row.scheduledDateStr}} {{timeZone}}</ng-template>
                        </tr>

                        <!-- Row shown when there is no matching data. -->
                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" colspan="8" style="text-align: center;">No results found</td>
                        </tr>
                    </table>

                </div>
                <mat-paginator [length]="length" [pageIndex]="pageIndex" [pageSize]="pageSize"
                    [pageSizeOptions]="pageSizeOptions" [showFirstLastButtons]="true" (page)="handlePageEvent($event)">
                </mat-paginator>
            </div>
        </div>
    </div>

    <!-- Loading Icon while making backend call -->
    <div class="loading_spinner" *ngIf="isLoading">
        <ngl-spinner size="large" variant="brand" alternativeText="Loading"></ngl-spinner>
    </div>
</div>

<div class="slds-notify_container">
    <ngl-toast iconName="utility:info" class="toast-info" *ngIf="showTopToast" [(variant)]="toastVariant"
        (close)="onClose($event); showTopToast = false;" duration="3000">
        <h3 class="slds-text-heading_small">{{toastMsg}}</h3>
    </ngl-toast>
</div>

<!-- Modal Popup for confirmation to cancel the Schedule -->
<ngl-modal header="Cancel Schedule" [(open)]="openConfirmationModal">
    <div class="slds-p-around_medium">
        <p>Are you sure to Cancel the selected schedule(s)</p>
    </div>
    <ng-template nglModalFooter>
        <button class="slds-button slds-button_neutral" (click)="closeScheduleCancelConfirmationModal()">Cancel</button>
        <button class="slds-button slds-button_brand" (click)="removeSelectedSchedules()">Ok</button>
    </ng-template>
</ngl-modal>

<!-- <app-push-upgrade-schedule-modal></app-push-upgrade-schedule-modal> -->
<ngl-modal header="Reschedule Upgrade(s)" [(open)]="showScheduleUpgradeModal" size="large">
    <div class="slds-p-around_medium">
        <div>
            <table class="table borderless">
                <tbody>
                    <tr>
                        <td>Target Release</td>
                        <td>{{this.commonService.getCurrentReleaseLabel(currentActiveReleaseInfo)}}</td>
                        <td>Reschedule</td>
                        <td>
                            <ngl-pill class="schedule-date-text">
                                {{this.authservice.dateFormatter(scheduleDate.toString())}}</ngl-pill>
                        </td>
                    </tr>
                    <tr>
                        <td>Date</td>
                        <td>
                            <ngl-datepicker-input [(ngModel)]="scheduleDate" #dt="ngModel"
                                [class.slds-has-error]="!dt.valid" [showToday]="true" [min]="minScheduleDate"
                                [cleave]="cleaveOptions" (valueChange)="scheduleDateChange($event)">
                                <input nglDatepickerInput type="text" readonly>
                            </ngl-datepicker-input>
                        </td>
                        <td>Time</td>
                        <td>
                            <ngl-combobox [(open)]="scheduleTimeOpen" [options]="scheduleTimeOptions"
                                [(selection)]="scheduleTimeSelection" (selectionChange)="scheduleTimeChange($event)">
                                <input nglCombobox>
                            </ngl-combobox>
                        </td>
                    </tr>
                    <tr>
                        <td>Tags</td>
                        <td colspan="3">
                            <form-field class="tag-chip-list">
                                <mat-chip-list #chipList aria-label="Tags">
                                    <mat-chip *ngFor="let tag of tags" [selectable]="tagsSelectable"
                                        [removable]="tagsRemovable" (removed)="removeTag(tag)">
                                        {{tag.name}}
                                        <mat-icon matChipRemove>cancel</mat-icon>
                                    </mat-chip>
                                    <input placeholder="New Tag..." [matChipInputFor]="chipList"
                                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                        [matChipInputAddOnBlur]="tagsAddOnBlur" (matChipInputTokenEnd)="addTag($event)">
                                </mat-chip-list>
                            </form-field>
                        </td>
                    </tr>
                </tbody>
            </table>
            <br>
            <h3>Current Schedule(s)</h3>
            <div class="detail-data-container mat-elevation-z8">
                <table mat-table [dataSource]="scheduleOrgsDataSource" class="mat-elevation-z8">
                    <!--- Note that these columns can be defined in any order.
                      The actual rendered columns are set as a property on the row definition" -->

                    <!-- Organization Id Column -->
                    <ng-container matColumnDef="orgId">
                        <th mat-header-cell *matHeaderCellDef> Organization Id </th>
                        <td mat-cell *matCellDef="let element"> {{this.commonService.removeOrgIdCheksum(element.orgId)}}
                        </td>
                    </ng-container>

                    <!-- Organization Name Column -->
                    <ng-container matColumnDef="orgName">
                        <th mat-header-cell *matHeaderCellDef> Organization Name </th>
                        <td mat-cell *matCellDef="let element"> {{element.orgName}} </td>
                    </ng-container>

                    <!-- Release Name Column -->
                    <ng-container matColumnDef="releaseDisplayName">
                        <th mat-header-cell *matHeaderCellDef> Release</th>
                        <td mat-cell *matCellDef="let element"> {{element.releaseDisplayName}} </td>
                    </ng-container>

                    <!-- Schedule Date/Time Column -->
                    <ng-container matColumnDef="scheduledDate">
                        <th mat-header-cell *matHeaderCellDef> Schedule <br> ({{timeZone}})</th>
                        <td mat-cell *matCellDef="let element"> {{element.scheduledDateStr}} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="scheduleOrgsDisplayedColumns; sticky: true"></tr>
                    <tr class="package-element-row" mat-row
                        *matRowDef="let row; columns: scheduleOrgsDisplayedColumns;"></tr>
                </table>
            </div>
        </div>
    </div>
    <ng-template nglModalFooter>
        <button class="slds-button slds-button_neutral" (click)="closeScheduleUpgradeModal()">Cancel</button>
        <button class="slds-button slds-button_brand" (click)="saveUpgradeSchedule()">Submit</button>
    </ng-template>
</ngl-modal>