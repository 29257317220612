import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NglComboboxOptionItem } from 'ng-lightning';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ReleaseConfig } from '../interface/release-config';
import { AuthService } from '../shared/auth.service';
import { CommonService } from '../shared/common.service';
import { ReleaseProduct, ReleaseProductPackage } from '../interface/release-metadata'
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-admin-release-details',
  templateUrl: './admin-release-details.component.html',
  styleUrls: ['./admin-release-details.component.css']
})
export class AdminReleaseDetailsComponent implements OnInit {
  isLoading: boolean = false;

  showTopToast: boolean = false;
  toastMsg: string;
  toastVariant: string;

  showActiveReleases: boolean = true;
  releaseDropdownInputCtrl: FormControl = new FormControl();
  releaseOpen = false;
  releaseSelection: string;
  filteredReleaseDropdown$: Observable<any[]>;
  releaseList: ReleaseConfig[];
  releaseDropdownList: NglComboboxOptionItem[];
  selectedRelease: ReleaseConfig;

  releaseInfo: {};
  stringifyReleaseInfo: string;
  releaseProducts: ReleaseProduct[];
  packageDataSource = new MatTableDataSource<ReleaseProductPackage>();
  expandedProducts = null;

  displayedColumns: string[] = ['packageName', 'version'];

  openDeactivateReleaseConfirmationModal: boolean = false;

  constructor(public authservice: AuthService, private commonService: CommonService) { }

  ngOnInit(): void {
    // Check if access token is present else take the user to admin login page.
    if (this.authservice.isLoggedIn) {
      //this.getAllRegisteredOrgList();
      this.populateReleaseDropDown();
    }
    else {
      this.authservice.redirectToAdminLogin();
    }
  }

  filterReleaseDropdownList(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.releaseDropdownList.filter(release => release.label.toLowerCase().indexOf(filterValue) > -1);
  }

  toggleShowActiveReleasesFilter(event: any) {
    this.showActiveReleases = event.target.checked;
    this.populateReleaseDropDown();
  }
  populateReleaseDropDown() {
    this.isLoading = true;
    this.releaseSelection = null;

    this.releaseInfo = {};
    this.stringifyReleaseInfo = null;
    this.releaseProducts = [];
    this.selectedRelease = null;
    this.expandedProducts = null;

    this.authservice.getAllReleaseInfo(!this.showActiveReleases).subscribe((response: ReleaseConfig[]) => {
      this.releaseList = response;

      var releaseList = response.map((item, i) => {
        return {
          value: item.name,
          label: item.isCummulative ? item.name : item.displayLabel,
          patchNo: item.patchNo,
          isCummulative: item.isCummulative,
          isActive: item.isActive,
          canBeDeactivated: item.canBeDeactivated
        };
      });

      this.releaseDropdownList = releaseList;
      this.releaseDropdownInputCtrl.reset();
      this.filteredReleaseDropdown$ = this.releaseDropdownInputCtrl.valueChanges
        .pipe(
          startWith(''),
          map(val => !val ? [...this.releaseDropdownList] : this.filterReleaseDropdownList(val))
        );

      this.isLoading = false;
    });
  }

  onChangeRelease(selectedReleaseName: string) {
    this.isLoading = true;
    this.releaseInfo = {};
    this.stringifyReleaseInfo = null;
    this.releaseProducts = [];
    this.selectedRelease = null;
    this.expandedProducts = null;

    if (selectedReleaseName != null) {
      this.selectedRelease = this.releaseList.find(i => i.name == selectedReleaseName);
      this.authservice.GetReleaseMetadata(selectedReleaseName).subscribe((response) => {
        this.releaseInfo = response

        const removeEmpty = (obj) => {
          Object.keys(obj).forEach(key => {
            if (obj[key] && typeof obj[key] === "object") {
              removeEmpty(obj[key]);
            } else if (obj[key] === null) {
              delete obj[key];
            }
          });
          return obj;
        };

        this.stringifyReleaseInfo = JSON.stringify(removeEmpty(response), null, 4);
        Object.keys(response).forEach(e => {
          this.releaseProducts.push({
            name: e.replace("_", " "),
            packages: response[e]
          });
        });

        this.isLoading = false;
      });
    }
    else {
      this.isLoading = false;
    }
  }

  getPackagesSource(): MatTableDataSource<ReleaseProductPackage> {
    return new MatTableDataSource<ReleaseProductPackage>()
  }

  changeReleaseStatus() {
    if (this.selectedRelease != null) {
      this.isLoading = true;
      this.authservice.ChangeReleaseStatus(this.selectedRelease.name, !this.selectedRelease.isActive).subscribe(response => {
        this.openToast("Release " + (this.selectedRelease.isActive ? "Deactivated" : "Activated") + " successfully.", true);
        this.openDeactivateReleaseConfirmationModal = false;
        this.populateReleaseDropDown();
      });
    }
  }

  refreshReleaseMetadata() {
    if (this.selectedRelease != null && this.selectedRelease.patchNo != null) {
      this.isLoading = true;
      this.authservice.RefreshPatchReleaseMetadata(this.selectedRelease.name, this.selectedRelease.patchNo)
        .subscribe(response => {
          if (response.success) {
            this.openToast("Release metadata refreshed successfully", true);
            if (response.data == true) {
              this.populateReleaseDropDown();
            } else {
              this.onChangeRelease(this.selectedRelease.name);
            }
          }
          else {
            this.openToast(response.message, false);
          }
        });
    }
  }

  expandAllProducts() {
    var products = [];
    this.releaseProducts.forEach(releaseProduct => {
      products.push(releaseProduct.name);
    });

    this.expandedProducts = products;
  }

  collaspeAllProducts() {
    this.expandedProducts = null;
  }

  showDeactivateReleaseConfirmationModal() {
    this.openDeactivateReleaseConfirmationModal = true;
  }

  cancelDeactivateReleaseConfirmationModal() {
    this.openDeactivateReleaseConfirmationModal = false;
  }

  openToast(msg: string, success: Boolean = false) {
    this.toastMsg = msg;
    this.isLoading = false;
    if (success)
      this.toastVariant = 'success';
    else
      this.toastVariant = 'error';

    this.showTopToast = true;
  }

  onClose(reason: string) {
    console.log('Closed by ${reason}');
  }
}
