import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css']
})
export class PageNotFoundComponent implements OnInit {

  img: string
  constructor() { }

  ngOnInit(): void {
    this.img = window.location.origin + "/assets/images/themes/oneSalesforce/banner-group-private-default.png"
  }

}
