import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER } from '@angular/core';
import { MatSliderModule } from '@angular/material/slider';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './shared/authconfig.interceptor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';

import { NglModule, NGL_ICON_CONFIG, NglIconConfig } from 'ng-lightning';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PushUpgradeRegistrationComponent } from './push-upgrade-registration/push-upgrade-registration.component';
import { AppConfigService } from './app-config.service';
import { LoginComponent } from './login/login.component';
import { PushUpgradeAdminComponent } from './push-upgrade-admin/push-upgrade-admin.component';
import { ShowMoreDetailComponent } from './show-more-detail/show-more-detail.component';
import { ForbiddenComponent } from './forbidden/forbidden.component';
import { PushUpgradeReleaseDetailComponent } from './push-upgrade-release-detail/push-upgrade-release-detail.component';
import { OptInNavigationPanelComponent } from './opt-in-navigation-panel/opt-in-navigation-panel.component';
import { AdminNavigationPanelComponent } from './admin-navigation-panel/admin-navigation-panel.component';
import { AdminRegisteredOrgListComponent } from './admin-registered-org-list/admin-registered-org-list.component';
import { AdminReleaseJobListComponent } from './admin-release-job-list/admin-release-job-list.component';
import { PushUpgradeWelcomeComponent } from './push-upgrade-welcome/push-upgrade-welcome.component';
import { AdminJobSchedulerListComponent } from './admin-job-scheduler-list/admin-job-scheduler-list.component';
import { AdminReleaseDetailsComponent } from './admin-release-details/admin-release-details.component';
import { AdminSmartScheduleComponent } from './admin-smart-schedule/admin-smart-schedule.component';
import { AdminSettingsComponent } from './admin-settings/admin-settings.component';
import { DualListComponent } from './dual-list/dual-list.component';
import { AdminSmartTagComponent } from './admin-smart-tag/admin-smart-tag.component';
import { ReadOnlyBtnDirective } from './shared/directives/read-only-btn.directive';
import { ReadOnlyFieldDirective } from './shared/directives/read-only-field.directive';
import { SystemAdminAccessDirective } from './shared/directives/system-admin-access.directive';
import { WatcherComponent } from './admin-settings/watcher/watcher.component';
import { CommonSettingsComponent } from './admin-settings/common-settings/common-settings.component';
import { PackageUpgradeSettingsComponent } from './admin-settings/package-upgrade-settings/package-upgrade-settings.component';
import { PackageUpgradeActionsComponent } from './admin-settings/package-upgrade-actions/package-upgrade-actions.component';
import { UserComponent } from './admin-settings/user/user.component';
import { UpgradeSummaryComponent } from './upgrade-summary/upgrade-summary.component';
import { AdminSettingsGuard } from './guards/admin-settings.guard';
import { OrgRegistrationReportComponent } from './upgrade-summary/charts/org-registration-report/org-registration-report.component';
import { UpgradedJobReportComponent } from './upgrade-summary/charts/upgraded-job-report/upgraded-job-report.component';
import { JobsStatusReportComponent } from './upgrade-summary/charts/jobs-status-report/jobs-status-report.component';



@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    PushUpgradeRegistrationComponent,
    LoginComponent,
    PushUpgradeAdminComponent,
    ShowMoreDetailComponent,
    ForbiddenComponent,
    PushUpgradeReleaseDetailComponent,
    OptInNavigationPanelComponent,
    AdminNavigationPanelComponent,
    AdminRegisteredOrgListComponent,
    AdminReleaseJobListComponent,
    PushUpgradeWelcomeComponent,
    AdminJobSchedulerListComponent,
    AdminReleaseDetailsComponent,
    AdminSmartScheduleComponent,
    AdminSettingsComponent,
    DualListComponent,
    AdminSmartTagComponent,
    ReadOnlyBtnDirective,
    ReadOnlyFieldDirective,
    SystemAdminAccessDirective,
    WatcherComponent,
    CommonSettingsComponent,
    PackageUpgradeSettingsComponent,
    PackageUpgradeActionsComponent,
    UserComponent,
    UpgradeSummaryComponent,
    OrgRegistrationReportComponent,
    UpgradedJobReportComponent,
    JobsStatusReportComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    MatSliderModule,
    MatSnackBarModule,
    MatToolbarModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatMenuModule,
    MatIconModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    ReactiveFormsModule,
    NglModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    AdminSettingsGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [AppConfigService],
      useFactory: (appConfigService: AppConfigService) => {
        return () => {
          //Make sure to return a promise!
          return appConfigService.load();
        };
      }
    },
    { provide: NGL_ICON_CONFIG, useValue: <NglIconConfig>{ svgPath: '/assets/icons' } },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
