import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../shared/auth.service';
import { CommonService } from '../shared/common.service';
import { TextMessage } from '../shared/text-message';

@Component({
  selector: 'app-push-upgrade-admin',
  templateUrl: './push-upgrade-admin.component.html',
  styleUrls: ['./push-upgrade-admin.component.css']
})

export class PushUpgradeAdminComponent implements OnInit {
  username: string;
  clientId: string;
  showTopToast = false;
  toastMsg: string;
  toastVariant: string;

  constructor(private router: Router, private authservice: AuthService, public commonService: CommonService) { }

  ngOnInit() {
    if (this.authservice.isLoggedIn)
      this.router.navigate(['ui/admin/subscriber-list']);
  }

  openToast(msg: string, success: Boolean = false) {
    this.toastMsg = msg;
    if (success)
      this.toastVariant = 'success';
    else
      this.toastVariant = 'error';

    this.showTopToast = true;
  }

  onClose(reason: string) {
    console.log(`Closed by ${reason}`);
  }

  onAdminLogin(): void {

    if (this.authservice.isEmpty(this.username)) {
      this.openToast(TextMessage.enterUserName);
      return;
    }

    if (this.authservice.isEmpty(this.clientId)) {
      this.openToast(TextMessage.enterPassword);
      return;
    }


    this.authservice.adminSignIn(this.username, this.clientId).subscribe(data => {
      if (!this.authservice.isEmpty(data.token)) {
        localStorage.setItem('refresh_token', data.refreshToken)
        localStorage.setItem('access_token', data.token)
        localStorage.setItem('user_type', data.userDetails.userType)
        this.authservice.currentUser.UserType = data.userDetails.userType;

        this.openToast(TextMessage.loggedIn, true);
        if (this.authservice.isEmpty(localStorage.getItem('redirectURLAfterReLogin')))
          this.router.navigate(['ui/admin/subscriber-list']);
        else
          window.location.href = localStorage.getItem('redirectURLAfterReLogin');
      }
      else {
        this.openToast(TextMessage.invalidCredentials);
      }
    }, error => { this.openToast(TextMessage.errorMsg); }
    );
  }
}
