<app-opt-in-navigation-panel></app-opt-in-navigation-panel>

<div class="row justify-content-around slds-text-body_regular" *ngIf="displayPage">
    <div class="slds-brand-band slds-brand-band_medium"></div>

    <div class="col-md-6 rounded release-detail border-10">
        <h2 style="text-align: left;">Conga Release Details</h2>
        <hr class="solid">
        <h3 class="upgrade-alert" *ngIf="release_details.isOrgUpgraded">{{release_details.comment}}</h3>

        <h3>Latest Certified Release - {{release_details.shortReleaseName}} (Release Notes are <a href="{{release_details.mainDocumentUrl}}">here</a>)</h3>

        <div *ngFor="let product of release_details.products; let i = index">
            <ul ngl-accordion [(activeName)]="expandedProducts" multiple>
                <ng-template nglAccordionSection name="{{product.productName}}" label="{{product.productName}}">

                    <div class="detail-data-container mat-elevation-z8">
                        <table mat-table [dataSource]="product.packages" class="mat-elevation-z8">

                            <!-- Pacakge Name Column -->
                            <ng-container matColumnDef="packageName">
                                <th mat-header-cell *matHeaderCellDef> Package Name </th>
                                <td mat-cell *matCellDef="let element">
                                    {{checkIsPacakageNew(element.packageName,element.status)}} </td>
                            </ng-container>

                            <!-- Package Source Version Column -->
                            <ng-container matColumnDef="installedVersion">
                                <th mat-header-cell *matHeaderCellDef> Installed Version </th>
                                <td mat-cell *matCellDef="let element"> {{checkForNullorEmpty(element.installedVersion)}} </td>
                            </ng-container>

                            <!-- Package Source Release Name Column -->
                            <ng-container matColumnDef="installedReleaseName">
                                <th mat-header-cell *matHeaderCellDef> Installed Release - {{ '{' }}Patch{{ '}' }} </th>
                                <td mat-cell *matCellDef="let element">
                                    {{addPatchNameToVersionName(element.installedReleaseName,element.installedPatchName)}} </td>
                            </ng-container>

                            <!-- Package Target Release Version Column -->
                            <ng-container matColumnDef="toBeInstalledVersion">
                                <th mat-header-cell *matHeaderCellDef> Latest Certified Version </th>
                                <td mat-cell *matCellDef="let element">
                                    <ng-container *ngIf="this.authservice.isEmpty(element.targetDocumentationUrl); else withLink">
                                        {{element.targetVersion}}
                                    </ng-container>
                                    <ng-template #withLink>
                                        <a href="{{this.commonService.getURLFromTag(element.targetDocumentationUrl)}}" target="_blank"><span
                        title="{{this.commonService.getURLFromTag(element.targetDocumentationUrl)}}">{{element.targetVersion}}</span></a>
                                    </ng-template>
                                </td>
                            </ng-container>

                            <!-- Action Column -->
                            <!-- <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef> Action </th>
                <td mat-cell *matCellDef="let element"> - </td>
              </ng-container> -->

                            <tr mat-header-row *matHeaderRowDef="packageDisplayedColumns; sticky: true"></tr>
                            <tr class="package-element-row" mat-row *matRowDef="let row; columns: packageDisplayedColumns;"></tr>
                        </table>
                    </div>
                </ng-template>
            </ul>
        </div>

        <div *ngIf="isVanillaOrg">
            <p>{{release_details.comment}}<br></p>
        </div>

        <div *ngIf="!isVanillaOrg">
            <hr class="solid">

            <div class="disclaimer">
                <h2>Notes</h2>
                <div>
                    Packages with (*) are dependent packages which needs to be installed to ensure the core features to work as expected
                    <br><br> The packages marked as (New) are new packages in this release.
                    <br><br> All the mandatory related/dependent pacakges will be installed for Example - Along with Conga Contract Managment package for CLM - Conga Base Library will be installed
                    <br><br> This package may send or release data from third-party websites. Make sure you trust these websites -
                    <br> CSP Trusted Site<br>
                </div>
                <ul>
                    <li>https://content.analytics.apttus.com</li>
                    <li>https://data.analytics.apttus.com</li>
                    <li>Website - data.analytics.apttus.com(SSL Encrypted)</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div *ngIf="!displayPage">
    <div class="loading_spinner">
        <ngl-spinner size="large" variant="brand" alternativeText="Loading"></ngl-spinner>
    </div>
</div>
