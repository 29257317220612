import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from '../../shared/auth.service';
import { DropdownItem, PackageUpgradeSettings, UserDetails } from '../../interface/pu-admin';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {


  displayedColumns: string[] = ['appName', 'emailId', 'userType', 'isActive', 'action'];
  emailPattern = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
  dataSource = new MatTableDataSource<UserDetails>();
  isLoading: boolean = false;
  showUserModal: boolean = false;
  editUser: boolean = false;
  deactivateUser: boolean = false;
  defaultUserDetails: UserDetails & { isChangePassword?: boolean, showPassword?: boolean } = {
    id: "",
    appName: "",
    emailId: "",
    clientId: "",
    userType: "",
    isChangePassword: false,
    showPassword: false,
    isActive: true
  };
  userTypeList: Array<DropdownItem> = [
    { label: 'CongaAdmin', value: 'CongaAdmin' },
    { label: 'ReadOnlyUser', value: 'ReadOnlyUser' },
    { label: 'TAASClient', value: 'TAASClient' },
    { label: 'SystemAdmin', value: 'SystemAdmin' },
    { label: 'CSMITClient', value: 'CSMITClient' }
  ]
  editorData: UserDetails & { isChangePassword?: boolean, showPassword?: boolean } = this.defaultUserDetails;
  openDeleteConfirmationModel = false;
  deleteData: UserDetails = null;
  userTypeOpen: boolean = false;

  @ViewChild('TableOneSort', { static: false }) tableOneSort: MatSort;

  length: number = 0;
  pageSize: number = 10;
  pageIndex: number = 0;
  pageSizeOptions: number[] = [10, 25, 50, 75, 100];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  isSubmitted: boolean = false;
  @Input() searchText: FormControl;

  constructor(private authservice: AuthService) { }

  ngOnInit(): void { }


  public getUserList(searchText: string = "") {
    searchText = this.searchText.value
    this.isLoading = true;
    this.authservice.getAllUsers({
      pageNo: this.pageIndex + 1,
      pageSize: this.pageSize,
      searchText
    }).subscribe(res => {
      const { data, totalCount } = res;
      this.dataSource = new MatTableDataSource<UserDetails>(data.map(item => {
        return {
          ...item
        }
      }));
      this.length = totalCount;
      this.dataSource.sort = this.tableOneSort;
      this.dataSource.sortingDataAccessor = (data, header) => this.authservice.isEmpty(data[header]) ? "" : Array.isArray(data[header]) ? data[header].join() : data[header].toString().toLowerCase();
      this.isLoading = false;
    });
  }

  public onCreate() {
    this.showUserModal = true;
    this.editUser = false;
    this.editorData = JSON.parse(JSON.stringify(this.defaultUserDetails));
  }

  handlePageEvent(event: PageEvent) {
    this.pageIndex = event.pageIndex;
    if (event.pageSize != this.pageSize) {
      this.pageSize = event.pageSize;
      this.pageIndex = 0;
    }
    this.getUserList();
  }

  editUserDetails(item: any) {
    this.isSubmitted = false;
    this.editUser = this.showUserModal = true;
    this.editorData = JSON.parse(JSON.stringify(item));
  }

  confirmDeleteUser(item: any) {
    this.openDeleteConfirmationModel = true;
    this.deleteData = item;
    this.deactivateUser = this.deleteData.isActive;
  }

  getUserName() {
    if (this.authservice.isEmpty(this.deleteData))
      return "";
    else
      return this.deleteData.appName;
  }

  userStatusChange() {
    this.deleteData.isActive = !this.deleteData.isActive;
    this.authservice.updateUserStatus(this.deleteData).toPromise().finally(() => {
      this.openDeleteConfirmationModel = false;
      this.getUserList();
      this.deleteData = null;
    })
  }

  onSubmitUser() {
    this.isSubmitted = true;
    let hasError = !this.editorData.appName || ( !this.authservice.isEmpty(this.editorData.isChangePassword) && this.editorData.isChangePassword && !this.editorData.clientId ) || !this.editorData.emailId
      || !this.emailPattern.test(this.editorData.emailId) || !this.editorData.userType;
    if (!hasError) {
      this.isLoading = true;
      var updatePassword = this.editorData.isChangePassword;
      this.authservice.submitUser(this.editorData, updatePassword).subscribe(() => {

        this.editUser = false;
        this.showUserModal = false;
        this.getUserList();
        this.isSubmitted = false;
        this.isLoading = false;
      }, (err) => {
        this.isLoading = false;
        console.error({ err });
      })
    }
  }



}
