import { Component, OnInit } from '@angular/core';
import { AuthService } from './shared/auth.service';
import { CommonService } from './shared/common.service';
import { HttpService } from './shared/http.service';

import {
  Chart,
  DoughnutController,
  ArcElement,
  Title,
  Tooltip,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  SubTitle
} from 'chart.js';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'push-upgrade-ui';
  isUnderMaintenance: boolean = false;

  constructor(public http: HttpService, private commonService: CommonService, private authService: AuthService) {


    Chart.register(
      DoughnutController,
      BarController,
      ArcElement,
      BarElement,
      LineElement,
      PointElement,
      BubbleController,
      LineController,
      PieController,
      PolarAreaController,
      RadarController,
      ScatterController,
      CategoryScale,
      LinearScale,
      LogarithmicScale,
      RadialLinearScale,
      TimeScale,
      TimeSeriesScale,
      Decimation,
      Filler,
      Legend,
      Title,
      Tooltip,
      SubTitle
    );

  }

  ngOnInit() {
    this.authService.GetSiteMaintenanceStatus().subscribe(isUnderMaintenance => {
      this.commonService.maintenanceWarning$.next(isUnderMaintenance);
    })
  }
}
