<app-admin-navigation-panel></app-admin-navigation-panel>

<div class="main">
    <br>
    <br>
    <div class="slds-page-header slds-page-header_record-home">
        <div class="slds-page-header__row">
            <div class="slds-page-header__col-title">
                <div class="slds-media">
                    <div class="slds-media__figure">
                        <span class="slds-icon_container slds-icon-standard-opportunity">
                            <svg class="slds-icon slds-page-header__icon" aria-hidden="true">
                                <use xlink:href="/assets/icons/standard-sprite/svg/symbols.svg#opportunity"></use>
                            </svg>
                        </span>
                    </div>
                    <div class="slds-media__body">
                        <div class="slds-page-header__name">
                            <div class="slds-page-header__name-title">
                                <h1>
                                    <span class="slds-page-header__title slds-truncate" title="Smart Tag">Smart
                                        Tag</span>
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="slds-page-header__col-actions">
                <div class="slds-page-header__controls">
                    <div class="slds-page-header__control">

                        <button *readOnlyBtn type="button" nglButton variant="brand" aria-live="assertive">
                            <span class="slds-text-not-selected" (click)="OpenSmartTagPopUp()">Create Smart Tag</span>
                        </button>
                        <button *readOnlyBtn type="button" nglButton variant="destructive" aria-live="assertive"
                            [disabled]="disableBulkDeleteBtn" (click)="openSmartTagDeleteConfirmationModal()">
                            <span class="slds-text-not-selected">Delete Smart Tag(s)</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <br>

    <div class="slds-page-header">
        <div class="data-body">
            <div class="slds-page-header">
                <mat-form-field>
                    <mat-label>Filter</mat-label>
                    <input matInput (keyup)="applyFilter($event)" placeholder="Search Text" #input>
                </mat-form-field>
                <div class="data-container mat-elevation-z8">
                    <table #TableOneSort="matSort" mat-table matSort [dataSource]="dataSource" multiTemplateDataRows
                        class="mat-elevation-z8">

                        <!-- Checkbox Column -->
                        <ng-container matColumnDef="select">
                            <th mat-header-cell *matHeaderCellDef>
                                <mat-checkbox *readOnlyBtn color="primary" (change)="$event ? masterToggle() : null"
                                    [checked]="checkboxselection.hasValue() && isAllSelected()"
                                    [disabled]="disableMasterCheckbox()"
                                    [indeterminate]="checkboxselection.hasValue() && !isAllSelected()"
                                    [aria-label]="checkboxLabel()">
                                </mat-checkbox>
                            </th>
                            <td mat-cell *matCellDef="let row">
                                <mat-checkbox *readOnlyBtn color="primary" (click)="$event.stopPropagation()"
                                    (change)="$event ? checkboxselection.toggle(row) : null"
                                    [checked]="checkboxselection.isSelected(row)" [aria-label]="checkboxLabel(row)"
                                    [disabled]="this.isDeleteDisabled(row)">
                                </mat-checkbox>
                            </td>
                        </ng-container>

                        <!-- Org ID Column -->
                        <ng-container matColumnDef="smartTagId">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Smart Tag Id </th>
                            <td mat-cell *matCellDef="let element"> {{element.smartTagId}} </td>
                        </ng-container>

                        <!-- Org Name Column -->
                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Smart Tag Name </th>
                            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                        </ng-container>

                        <!-- Action Column -->
                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef>
                                <span *readOnlyBtn>
                                    Action
                                </span>
                            </th>
                            <td mat-cell *matCellDef="let element">
                                <div *readOnlyBtn>
                                    <button class="slds-button slds-icon_container slds-icon-utility-info"
                                        [nglTooltip]="'Update'" nglTooltipInteractive="true" nglTooltipDelay="100"
                                        nglTooltipOpenAuto (click)="updateSingleSmartTag(element)">
                                        <svg class="slds-button__icon" aria-hidden="true">
                                            <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#edit">
                                            </use>
                                        </svg>
                                    </button>
                                    <button class="slds-button slds-icon_container slds-icon-utility-info"
                                        [nglTooltip]="this.getDeleteBtnToolTip(element)" nglTooltipInteractive="true"
                                        nglTooltipDelay="100" nglTooltipOpenAuto
                                        [disabled]="this.isDeleteDisabled(element)"
                                        (click)="deleteSingleSmartTag(element)">
                                        <svg class="slds-button__icon" aria-hidden="true">
                                            <use xlink:href="/assets/icons/utility-sprite/svg/symbols.svg#delete">
                                            </use>
                                        </svg>
                                    </button>
                                </div>
                            </td>
                        </ng-container>

                        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                        <ng-container matColumnDef="expandedDetail">
                            <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
                                <div class="example-element-detail"
                                    [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">

                                    <table class="table table-hover table-striped">
                                        <thead>
                                            <tr class="table-secondary">
                                                <th scope="col">Sl No</th>
                                                <th scope="col">Org Id</th>
                                                <th scope="col">Org Name</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            <div
                                                *ngIf="this.commonService.isEmpty(element.orgs) || this.commonService.isEmpty(element.orgs[0]._id); then thenBlock else elseBlock">
                                            </div>
                                            <ng-template #elseBlock>
                                                <tr *ngFor="let row of element.orgs ;index as i">
                                                    <th scope="row">{{i+1}} </th>
                                                    <td>{{this.commonService.isEmpty(row._id) ? null :
                                                        this.commonService.removeOrgIdCheksum(row._id)}}
                                                    </td>
                                                    <td>{{row.name}}</td>
                                                </tr>
                                            </ng-template>
                                            <ng-template #thenBlock>
                                                <tr>
                                                    <td colspan="6">No Orgs linked</td>
                                                </tr>
                                            </ng-template>


                                        </tbody>
                                    </table>
                                </div>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                        <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row"
                            [class.example-expanded-row]="expandedElement === element"
                            [ngClass]="{'rowCancel': element.status === 'Cancelled'}"
                            (click)="expandedElement = expandedElement === element ? null : element">
                        </tr>
                        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>

                        <!-- Row shown when there is no matching data. -->
                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" colspan="4" style="text-align: center;">No results found</td>
                        </tr>
                    </table>

                </div>
                <mat-paginator [length]="length" [pageIndex]="pageIndex" [pageSize]="pageSize"
                    [pageSizeOptions]="pageSizeOptions" [showFirstLastButtons]="true" (page)="handlePageEvent($event)">
                </mat-paginator>
            </div>
        </div>
    </div>

</div>


<div class="slds-notify_container">
    <ngl-toast iconName="utility:info" class="toast-info" *ngIf="showTopToast" [(variant)]="toastVariant"
        (close)="onClose($event); showTopToast = false;" duration="3000">
        <h3 class="slds-text-heading_small">{{toastMsg}}</h3>
    </ngl-toast>
</div>

<div class="loading_spinner" *ngIf="isLoading">
    <ngl-spinner size="large" variant="brand" alternativeText="Loading"></ngl-spinner>
</div>

<ngl-modal [(header)]="this.showSmartTageModalHeader" [(open)]="showSmartTagModal" size="large">
    <div class="slds-p-around_medium">
        <div>
            <table class="table borderless-table">
                <tbody>
                    <tr>
                        <td>
                            <ngl-input label="Smart Tag Name">
                                <input ngl [(ngModel)]="this.pushUpgradeSmartTag.name" placeholder="Name"
                                    required="true">
                            </ngl-input>
                        </td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>
                            <ngl-textarea label="Add Org Ids">
                                <textarea ngl [(ngModel)]="this.csvTags"
                                    placeholder="Org Ids(18 digits) in CSV Format"></textarea>
                            </ngl-textarea>
                            <span *ngIf="this.csvErrorTags" class="slds-text-color_error"
                                [nglTooltip]="this.csvErrorTags" nglTooltipInteractive="true" nglTooltipDelay="100"
                                nglTooltipOpenAuto>
                                {{(this.csvErrorTags && this.csvErrorTags.length> 200) ? this.csvErrorTags.slice(0,
                                180).concat("...") : this.csvErrorTags}}
                            </span>
                            <br *ngIf="this.csvErrorTags" />
                            <button class="slds-button slds-button_brand" [disabled]="!this.csvTags"
                                (click)="handleCSVTags()">Add</button>
                        </td>
                    </tr>
                </tbody>
            </table>

            <h3>Select Orgs(s) for Smart Tag</h3>

            <dual-list [sort]="keepSorted" [source]="this.sourceScheduledOrgs" [key]="key" [display]="display"
                [filter]="filter" [(destination)]="this.confirmedScheduledOrgs" height="265px" [format]="format"
                [disabled]="disabled"></dual-list>

        </div>
    </div>
    <ng-template nglModalFooter>
        <button class="slds-button slds-button_neutral" (click)="closeSmartTagModal()">Cancel</button>
        <button class="slds-button slds-button_brand"
            (click)="saveSmartTag()">{{this.showSmartTagModalModalBtnLabel}}</button>
    </ng-template>
</ngl-modal>


<!-- Modal Popup for confirmation to delete the Smart Tag -->
<ngl-modal header="Delete Smart Tag(s)" [(open)]="openConfirmationModaltoDelete">
    <div class="slds-p-around_medium">
        <p>Are you sure to Delete the selected Smart Tag(s)</p>
    </div>
    <ng-template nglModalFooter>
        <button class="slds-button slds-button_neutral" (click)="closeSmartTagDeleteConfirmationModal()">Cancel</button>
        <button class="slds-button slds-button_brand" (click)="deleteSelectedSmartTags()">Ok</button>
    </ng-template>
</ngl-modal>