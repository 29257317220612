import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { environment } from '../environments/environment';
import { Observable } from 'rxjs';
import { CommonService } from './shared/common.service';
import { AuthService } from './shared/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

  private appConfig;
  private http: HttpClient;

  //Using HttpBackend to avoid being intercepted by AuthInterceptor
  constructor(private httpClient: HttpBackend, private commonService: CommonService) {
    this.http = new HttpClient(httpClient);
  }

  getLocalConfigForPath(path: string) {
    return this.http.get(path)
      .toPromise()
      .then(data => {
        this.appConfig = data;
      }).catch((response: any) => {
        console.log(`Could not load file '${path}': ${JSON.stringify(response)}`);
      });
  }

  get apiBaseUrl() {

    if (!this.appConfig) {
      throw Error('Config file not loaded!');
    }

    return this.appConfig.apiBaseUrl;
  }

  get user() {
    if (!this.appConfig) {
      throw Error('Config file not loaded!');
    }
    return this.appConfig.user;
  }

  get userType() {
    return localStorage.getItem('user_type');
  }

  get autoRefreshTimeInSec() {
    if (!this.appConfig) {
      throw Error('Config file not loaded!');
    }
    return (this.commonService.isEmpty(this.appConfig.autoRefreshTimeInSec)) ? 30 : this.appConfig.autoRefreshTimeInSec;
  }


  public load() {
    var origin = window.location.origin;
    let overrideConfigPath = origin + '/configoverride/appsettings.json';
    let configPath = origin + `/assets/config/config.${environment.name}.json`;
    return this.http.get(overrideConfigPath)
      .toPromise()
      .then(data => {
        this.appConfig = data;
      }).catch(async (response: any) => {
        console.log(`Could not load file '${overrideConfigPath}': ${JSON.stringify(response)}`);
        try {
          await this.getLocalConfigForPath(configPath);
        } catch (error) {
          return Observable.throw(error.json().error || 'Server error');
        }
      });
  }
}
