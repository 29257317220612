<div class="slds-context-bar">
    <div class="slds-context-bar__primary">
        <div
            class="slds-context-bar__item slds-context-bar__dropdown-trigger slds-dropdown-trigger slds-dropdown-trigger_click slds-no-hover">
            <div class="slds-context-bar__icon-action">
                <img class="img" width="30" alt="Conga Logo" src="../../assets/images/logo-conga.svg" />
            </div>
            <span class="slds-context-bar__label-action slds-context-bar__app-name">
                <span class="slds-truncate" title="Push Upgrade">Admin Dashboard</span>
            </span>
        </div>
    </div>
    <nav class="slds-context-bar__secondary" role="navigation">
        <ul class="slds-grid">
            <li class="slds-context-bar__item" routerLinkActive="slds-is-active"
                [routerLinkActiveOptions]="{exact: true}">
                <a [routerLink]="['/ui/admin/subscriber-list']" class="slds-context-bar__label-action"
                    title="Subscriber Org Details">
                    <span class="slds-truncate" title="Subscriber Org Details">Subscriber Org Details</span>
                </a>
            </li>
            <li class="slds-context-bar__item" routerLinkActive="slds-is-active"
                [routerLinkActiveOptions]="{exact: false}">
                <a [routerLink]="['/ui/admin/job-list']" class="slds-context-bar__label-action"
                    title="Push Upgrade Job Details">
                    <span class="slds-truncate" title="Push Upgrade Job Details">Push Upgrade Job Details</span>
                </a>
            </li>
            <li class="slds-context-bar__item" routerLinkActive="slds-is-active"
                [routerLinkActiveOptions]="{exact: true}">
                <a [routerLink]="['/ui/admin/schedule-list']" class="slds-context-bar__label-action"
                    title="Push Upgrade Schedule Details">
                    <span class="slds-truncate" title="Push Upgrade Job Details">Push Upgrade Schedule Details</span>
                </a>
            </li>
            <li class="slds-context-bar__item" routerLinkActive="slds-is-active"
                [routerLinkActiveOptions]="{exact: true}">
                <a [routerLink]="['/ui/admin/smart-schedule']" class="slds-context-bar__label-action"
                    title="Smart Schedule">
                    <span class="slds-truncate" title="Push Upgrade Smart Schedule">Smart Schedule</span>
                </a>
            </li>
            <li class="slds-context-bar__item" routerLinkActive="slds-is-active"
                [routerLinkActiveOptions]="{exact: true}">
                <a [routerLink]="['/ui/admin/smart-tag']" class="slds-context-bar__label-action" title="Smart Tag">
                    <span class="slds-truncate" title="Smart Tag">Smart Tag</span>
                </a>
            </li>
            <li class="slds-context-bar__item" routerLinkActive="slds-is-active"
                [routerLinkActiveOptions]="{exact: true}">
                <a [routerLink]="['/ui/admin/release-detail']" class="slds-context-bar__label-action"
                    title="Release Details">
                    <span class="slds-truncate" title="Release Details">Release Details</span>
                </a>
            </li>
            <li *systemAdminAccess class="slds-context-bar__item" routerLinkActive="slds-is-active"
                [routerLinkActiveOptions]="{exact: true}">
                <a [routerLink]="['/ui/admin/settings']" class="slds-context-bar__label-action" title="Administration">
                    <span class="slds-truncate" title="Administration">Administration</span>
                </a>
            </li>
            <!-- <li class="slds-context-bar__item" routerLinkActive="slds-is-active"
                [routerLinkActiveOptions]="{exact: true}">
                <a [routerLink]="['/ui/admin/upgrade-summary']" class="slds-context-bar__label-action" title="Home">
                    <span class="slds-truncate" title="Upgrade Summary">Upgrade Summary</span>
                </a>
            </li> -->
        </ul>
    </nav>
    <a class="logout-icon" (click)="logOutUser();">
        <span title="Log Out">
            <ngl-icon iconName='utility:logout' size="small"></ngl-icon>
        </span>
    </a>
</div>

<ngl-alert style="position: absolute;" *ngIf="this.commonService.maintenanceWarning$ | async" variant="warning"
    iconName="warning" [dismissible]="false">
    Site under maintenance.
</ngl-alert>