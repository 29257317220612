export interface PackageAccessControl {
  id: string;
  orgId: string;
  customerPreferenceId: string;
  packageId: string;
  packageName: string;
  productName: string;
  accessibility: string;
  isInstalledPackage: boolean;
  userProfileList: userProfileList[];
  customProfileIds: string[];
  inputHasError: boolean;
  isCustomProfileEnabled :boolean;
  packageVersion : string;
}

export enum AccessControlEnum {
  InstallForAdminOnly = "InstallForAdminOnly",
  InstallForAllUsers = "InstallForAllUsers",
  InstallForCustomProfiles = "InstallForCustomProfiles"
}

export interface userProfileList {
  id: string
  name: string
  isCustom: boolean
  isSelected: boolean
}
