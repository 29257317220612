import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppConfigService } from '../app-config.service';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../shared/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  authCode: string;
  error: string;
  error_description: string;
  loginType: string;

  variant: string = 'brand';
  size = 'large';
  enableCheck: boolean = true;
  loginToSandBox: boolean = false;


  constructor(private route: ActivatedRoute, private http: HttpClient, private appConfigService: AppConfigService, private authservice: AuthService) {
    this.loginType = this.route.snapshot.params.type;

    // Check if the login Type is not empty and set the sandbox login flag.
    if (!this.authservice.isEmpty(this.loginType) && (this.loginType.toLowerCase() === 'sandbox')) {
      this.loginToSandBox = true;
    }

    // Get auth code from Salesforce CallBack URL
    this.authCode = this.route.snapshot.queryParamMap.get('code');
    // If it is present then get access token and save/update it in DB
    if (!this.authservice.isEmpty(this.authCode)) {
      this.enableCheck = false;
      var state = this.route.snapshot.queryParamMap.get("state");
      if(state == sessionStorage.getItem("state")){
        this.authservice.getSalesForceOrgAccessTokenAndSave(this.authCode, this.loginToSandBox);
        sessionStorage.removeItem("state");
      }else{
        this.authservice.doLogout();
        this.enableCheck = false;
        window.location.href = 'ui/welcome';
      }
    }

    // Get error msg from Salesforce CallBack URL
    this.error = this.route.snapshot.queryParamMap.get('error');
    this.error_description = this.route.snapshot.queryParamMap.get('error_description');
    // If it is present then alert and clear  access token
    if (!this.authservice.isEmpty(this.error)) {
      this.authservice.doLogout();
      this.enableCheck = false;
      window.location.href = 'ui/forbidden?error='+this.error+'&error_description='+this.error_description;
    }

    if (this.enableCheck) {
      // Check if access token is present else take the user to sfdc login page.
      if (!this.authservice.isLoggedIn) {
        if (this.authservice.isAdminUser)
          this.authservice.redirectToAdminLogin();
        else
          this.authservice.redirectToSalesforceSignIn(this.loginToSandBox);
      }

      // Check if Org ID is in local storage and if present redirect to registration ui page with
      if (!this.authservice.isEmpty(this.authservice.getOrgId()))
        this.authservice.redirectToRegistrationPageWithOrgId(this.authservice.getOrgId())
    }
  }

  ngOnInit(): void { }

}
