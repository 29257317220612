import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { OrgSummary } from 'src/app/interface/upgrade-summary';

@Component({
  selector: 'app-org-registration-report',
  templateUrl: './org-registration-report.component.html',
  styleUrls: ['./org-registration-report.component.css']
})
export class OrgRegistrationReportComponent implements OnInit {

  _orgSummary: OrgSummary;
  get orgSummary(): OrgSummary {
    return this._orgSummary;
  }
  @Input() set orgSummary(value: OrgSummary) {
    this._orgSummary = value;
    if (this._orgSummary) {
      this.updateSummary();
    }
  }

  productionSandboxData!: Array<any>;
  productionStatusData!: Array<any>;
  sandboxStatusData!: Array<any>;

  constructor(private changeDetection: ChangeDetectorRef) { }

  ngOnInit(): void { }

  updateSummary() {
    this.productionSandboxData = [];
    this.productionSandboxData.push({ label: "Total", count: this._orgSummary.totalOrgCount });
    this.productionSandboxData.push({ label: "Sandbox", count: this._orgSummary.sandboxOrgCount });
    this.productionSandboxData.push({ label: "Production", count: this._orgSummary.productionOrgCount });

    this.sandboxStatusData = [];
    this.sandboxStatusData.push({ label: "Active", count: this._orgSummary.sandboxStatusCount.active });
    this.sandboxStatusData.push({ label: "Inactive", count: this._orgSummary.sandboxStatusCount.inactive });
    this.sandboxStatusData.push({ label: "Disabled", count: this._orgSummary.sandboxStatusCount.diasbled });
    this.sandboxStatusData.push({ label: "Stale", count: this._orgSummary.sandboxStatusCount.stale });

    this.productionStatusData = [];
    this.productionStatusData.push({ label: "Active", count: this._orgSummary.productionStatusCount.active });
    this.productionStatusData.push({ label: "Inactive", count: this._orgSummary.productionStatusCount.inactive });
    this.productionStatusData.push({ label: "Disabled", count: this._orgSummary.productionStatusCount.diasbled });
    this.productionStatusData.push({ label: "Stale", count: this._orgSummary.productionStatusCount.stale });
    this.changeDetection.detectChanges();
  }
}
