import { Component, OnInit } from '@angular/core';
import { AuthService } from '../shared/auth.service';

@Component({
  selector: 'app-opt-in-navigation-panel',
  templateUrl: './opt-in-navigation-panel.component.html',
  styleUrls: ['./opt-in-navigation-panel.component.css']
})
export class OptInNavigationPanelComponent implements OnInit {

  constructor(private authservice: AuthService) { }

  ngOnInit(): void {
  }

  //Logout and remove active salesforce sessions for the provided Instance URL
  logoutUserFromSalesForce(): void {
    this.authservice.doLogout();
    var domain = this.getDomainName(localStorage.getItem('instanceURL'));
    var thisTimeout = setTimeout(function () {
      window.location.href = "https://" + domain + ".lightning.force.com/secur/logout.jsp";
    }, 100);
    var newWindow = window.open('ui/welcome');
    if (!newWindow) {
      clearTimeout(thisTimeout);
      alert('Please allow pop-ups on this site!');
    }
  }

  // Gets the Domain Name from Salesforce Instace Url
  getDomainName(instanceURL: string) {
    var match = instanceURL.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);
    if (match != null && match.length > 2 && typeof match[2] === 'string' && match[2].length > 0) {
      return match[2].split(".")[0];
    }
    else {
      return null;
    }
  }

}
